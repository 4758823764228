import { QueryBeanPropertyConfig } from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config'
import { getterCurControllerId, getterEntityModelVersion, getterProjectId } from '@/store'
import Entity from './entitymodel/entity'
import EntityModelManger from '@/libs/entitymodel/index'

/**
 * 将实体模型转换为querybean项
 * @param {Entity} entity 实体模型
 * @param {string} querybeanUuid 所属querybeanID
 */
export async function entityToQueryBeanConfig (entity: Entity, querybeanUuid?: string): Promise<QueryBeanPropertyConfig[]> {
  const manager = new EntityModelManger()
  await manager.loadAllOrgsAndEntities(getterProjectId()!, getterEntityModelVersion()!.uuid!)
  const fields = manager.getEntityAllFieldes(entity.uuid)
  return fields.map((field): Partial<QueryBeanPropertyConfig> => ({
    combinationType: 0,
    conditionType: 1,
    controllerConfigId: getterCurControllerId(),
    dataType: field.type,
    fromEnumUuid: field.enumUuid,
    propertyName: field.name,
    queryBeanConfigUuid: querybeanUuid,
    title: field.title
  })) as QueryBeanPropertyConfig[]
}
