/**
 * Enumeration配置模块
 */
import DefaultApiService from '@/libs/api-request'
import { EnumerationConfig } from './enum-config'

/**
 * Enumeration配置服务请求api接口
 */
class EnumerationConfigApi extends DefaultApiService<EnumerationConfig> {
  /**
   * 禁用control
     * @param enumerationId
     * @returns
     */
  disableEnumeration (enumerationId: number) {
    return this.request(`disable/${enumerationId}`, null, 'PUT')
  }

  /**
   * 启用control
   * @param enumerationId
   * @returns
   */
  enableEnumeration (enumerationId: number) {
    return this.request(`enable/${enumerationId}`, null, 'PUT')
  }

  /**
   * 通过uuid查找枚举
   * @param uuid
   */
  getItemByUuid (uuid: string) {
    return this.request(`uuid/${uuid}`, undefined, 'GET')
  }
}

export default new EnumerationConfigApi('/api/enumerationConfig')
