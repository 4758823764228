
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import Treeselect, { LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import { getterProjectId, getterEntityModelVersion } from '@/store'
import webapiEntityModelApi from '@/api/webapi-entity-model/webapi-entity-model-api'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'

export interface TreeNode {
  /// 节点id
  id: string;
  /// 子标题
  label: string;
  /// 是否可以选择
  isDisabled: boolean;
  /// 子节点
  children?: TreeNode[] | null;
  /// 节点全名
  fullName: string;
}

/**
 * 组织树选择器
 */
@Component({
  name: 'orgTreeSelector', components: { Treeselect }
})
export default class OrgTreeSelector extends Vue {
  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly value!: string

  @Prop({
    type: String,
    default: '请选择组织',
    required: false
  })
  // 默认显示的label
  readonly label!: string

  // 当前工程id
  projectId = -1
  // 当前版本uuid
  versionUuid = ''
  // 组织模型数据
  modelInfoData: OrgInfo[] = []

  /** 当前选择的节点全名的各个部分列表 */
  curValues: string[]=[]

  /// 取得树选择节点
  get options () {
    const res: TreeNode[] = this.modelInfoData.map(ch => this.orgToTreeData(ch))
      .filter((item) => item !== undefined) as TreeNode[]
    return res
  }

  /**
   * 组织选择
   */
  onSelect (org: TreeNode) {
    // this.$emit('input', org.id)
    this.$emit('on-select', org.fullName)
    // 把值分成多段
    const value = org.fullName
    if (!value || value === '') {
      this.curValues = []
    } else {
      this.curValues = value.split('.')
    }
  }

  /**
   * 将组织信息转为树节点
   */
  private orgToTreeData (org: OrgInfo, parentName?: string): TreeNode|undefined {
    const re: TreeNode = {
      id: org.uuid,
      label: org.name,
      isDisabled: false,
      children: [],
      fullName: !parentName ? org.name : parentName + '.' + org.name
    }

    const children = org.children.map((ch) => this.orgToTreeData(ch, re.fullName))
    let data: TreeNode[]|undefined = children.filter((item) => item !== undefined) as TreeNode[]
    if (data.length === 0) data = undefined

    re.children = data

    return re
  }

  /**
   * 获取组织模型信息
   */
  loadOrgInfoData () {
    webapiEntityModelApi.getModelOrgInfoData(this.projectId, this.versionUuid).then(res => {
      this.modelInfoData = [res.data!.data!]
    }).catch(err => {
      this.$Notice.error({
        title: '加载组织模型失败',
        desc: err
      })
    })
  }

  // 组件加载事件
  created () {
    this.projectId = getterProjectId()!
    this.versionUuid = getterEntityModelVersion()?.uuid!
    // 把值分成多段
    if (!this.value || this.value === '') {
      this.curValues = []
    } else {
      this.curValues = this.value.split('.')
    }
    this.loadOrgInfoData()
  }
}
