import { ModuleItem, updateCurUserMenusFromServer, updateUserProjectMenusFromServer } from '@/libs/sys-modules'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import storage from '@/libs/local-storage'

export interface RouteMoudleInfo{
  // 模块code
  code: string;
  // 扩展数据
  ext: string;
  // 路由对应的参数
  paramters: Record<string, any>;
  // 路由的地址
  fullPath: string;
}

/**
 * 用户菜单模块
 */
export interface MoudleStore{
    /// 当前用户的模块项列表
    moudles: Array<ModuleItem>|undefined;

    /// 当前菜单模块的code
    curMenuItemCode: string|undefined;

    // 当前路由模块的信息
    curRouteMoudleInfo?: RouteMoudleInfo;
}

/**
 * 模块状态模块
 */
class MoudleStoreModule implements Module<MoudleStore, unknown> {
    // 限制作用域
    namespaced=true

    // 状态
    state: MoudleStore={
      moudles: undefined,
      curMenuItemCode: storage.loadCurMenuItemCode(),
      curRouteMoudleInfo: storage.loadCurRouteMoudelInfo()
    }

    // 修改器
    mutations: MutationTree<MoudleStore>={
      /**
         * 设置当前模块列表
         * @param state
         * @param moudles
         */
      setMoudles (state, moudles: Array<ModuleItem>) {
        state.moudles = moudles
      },
      /**
       * 设置当前模块
       * @param state
       * @param curItem
       */
      setCurMenuItemCode (state, code: string|undefined) {
        state.curMenuItemCode = code
        storage.saveCurMenuItemCode(code)
      },
      /**
       * 设置当前route的模块code
       * @param state
       * @param code
       */
      setCurRouteMoudleInfo (state, routeMoudel?: RouteMoudleInfo) {
        state.curRouteMoudleInfo = routeMoudel
      }
    }

    // 操作列表
    actions: ActionTree<MoudleStore, unknown>={
      /**
       * 列新当前用户的模块列表
       * @param param0
       * @param moudles
       */
      updateUserMoudles ({ state, commit }, force?: boolean) {
        return new Promise((resolve, reject) => {
          /// 如果已经有模块数据，则直接返回模块数据
          if (state.moudles && !force) {
            resolve(state.moudles)
            return
          }
          /// 更新
          updateCurUserMenusFromServer().then((response) => {
            commit('setMoudles', response)
            resolve(state.moudles)
          }).catch((err) => reject(err))
        })
      },

      /**
       * 更新工程模块
       * @param projectId 工程id
       */
      updateProjectMoudles ({ state, commit }, { projectId, force }) {
        /// 如果已经有模块数据，则直接返回模块数据
        return new Promise((resolve, reject) => {
          if (state.moudles && !force) {
            resolve(state.moudles)
            return
          }
          updateUserProjectMenusFromServer(projectId).then((response) => {
            commit('setMoudles', response)
            resolve(state.moudles)
          }).catch((err) => reject(err))
        })
      },
      /**
       * 更新当前菜单项
       * @param param0
       * @param menuItem
       */
      updateCurMenuItemCode ({ commit }, fullCode: string) {
        commit('setCurMenuItemCode', fullCode)
      },

      /**
       * 更新当前路由对应的模块信息
       * @param param0
       * @param routeMoudleInfo
       */
      updateCurRouteMoudleInfo ({ commit }, routeMoudleInfo: RouteMoudleInfo|undefined) {
        commit('setCurRouteMoudleInfo', routeMoudleInfo)
        storage.saveCurRouteMoudelInfo(routeMoudleInfo)
      }
    }

    // 访问器
    getters: GetterTree<MoudleStore, unknown>={
      /**
         * 取得当前用户的模块列表
         * @param state
         * @param getters
         */
      userMoudlesGetter (state: MoudleStore, getters: unknown) {
        return state.moudles
      },
      /**
       * 取得当前用户的菜单项
       * @param state
       * @param getters
       */
      curMenuCodeItemGetter (state: MoudleStore, getters: unknown) {
        return state.curMenuItemCode
      },
      /**
       * 取得当前路由模块信息
       * @param state
       * @param getters
       */
      curRouteMoudleInfoGetter (state: MoudleStore, getters: unknown) {
        return state.curRouteMoudleInfo
      }
    }
}

export default new MoudleStoreModule()
