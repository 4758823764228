import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import storage from '@/libs/local-storage'

export interface CurEnumerationInfo{
  name: string;
  title: string;
}

interface EnumManagerStore {
    curEnumerationId?: number;
    curEnumerationInfo?: CurEnumerationInfo;
}

class EnumManagerStoreModule implements Module<EnumManagerStore, any> {
    namespaced = true // 限制作用域

    state: EnumManagerStore = {
      curEnumerationId: storage.loadCurEnumerationId(),
      curEnumerationInfo: storage.loadCurEnumerationInfo()
    }

    mutations: MutationTree<EnumManagerStore> = {
      setCurEnumerationId (state: EnumManagerStore, curEnumerationId: number) {
        state.curEnumerationId = curEnumerationId
        storage.saveCurEnumerationId(curEnumerationId)
      },
      setCurEnumerationInfo (state: EnumManagerStore, enumerationInfo: CurEnumerationInfo) {
        state.curEnumerationInfo = enumerationInfo
        storage.saveCurEnumerationInfo(enumerationInfo)
      }
    }

    actions: ActionTree<EnumManagerStore, any> = {
      /**
       * 更新当前enumeration的id
       * @param param0
       * @param enumerationId
       */
      updateCurEnumerationIdAndInfo ({ commit }, { enumerationId, info }) {
        commit('setCurEnumerationId', enumerationId)
        commit('setCurEnumerationInfo', info)
      },

      /**
       * 更新当前的enumeration信息
       * @param param0
       * @param enumerationInfo
       */
      updateCurEnumerationInfo ({ commit }, enumerationInfo: CurEnumerationInfo) {
        commit('setCurEnumerationInfo', enumerationInfo)
      }
    }

    getters: GetterTree<EnumManagerStore, any> = {
      /**
       * 取得enumerationler id 的getter方法
       * @param state
       * @param getters
       * @returns
       */
      curEnumerationIdGetter (state: EnumManagerStore, getters: unknown) {
        return state.curEnumerationId
      },
      /**
       * 取得当前enumerationinfo信息
       * @param state
       * @param getters
       * @returns
       */
      curEnumerationInfoGetter (state: EnumManagerStore, getters: unknown) {
        return state.curEnumerationInfo
      }
    }
}

// 导出对象实例
export default new EnumManagerStoreModule()
