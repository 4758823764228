
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import ControllerConfigApi from '@/api/project/apimanager/controller-config/controller-config-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import Modals from '@/views/project/api-manager/controller-view/modal/modals'
import EntityModelVersionSelector from '@/components/selector/entity-model-version-selector.vue'
import {
  updateCurControllerIdAndInfo,
  getterCurControllerInfo,
  getterCurControllerId,
  getterProjectId,
  dispatchSetEntityModelVersion,
  getterProjectMemberRight
} from '@/store'
import { CurControllInfo } from '@/store/module/api-manager'
import { Menu } from 'view-design'
import { QueryBeanConfig } from '@/api/project/apimanager/query-bean-config/query-bean-config'
import { getShortUuid } from '@/libs/uuid'
import queryBeanConfigApi from '@/api/project/apimanager/query-bean-config/query-bean-config-api'
import { entityToQueryBeanConfig } from '@/libs/querybean-proerty-helper'
import queryBeanConfigPropertyApi from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config-api'
import Entity from '@/libs/entitymodel/entity'

const defaultQuery = {
  page: 1,
  pageSize: 10000,
  sorts: ['-createTime'],
  queryControllerKey: ''
}

@Component({
  name: 'ControllerList',
  components: { EntityModelVersionSelector }
})
export default class ControllerList extends Vue {
  [x: string]: any
  isShowSpin = false

  entityModelVersionId = -1

  // 当前选择中的菜单
  activeName?: string = ''

  /**
   * 取得项目id
   */
  get projectId () {
    return getterProjectId()
  }

  /**
   * 用户是否可以编辑文档
   */
  get canEdit () {
    const right = getterProjectMemberRight()
    if (!right) return false
    return right.updateApiInterface
  }

  /**
   * 当前control的信息
   */
  get curControlInfo () {
    return getterCurControllerInfo()
  }

  /**
   * 当前controller的id
   */
  get curControllerId () {
    return getterCurControllerId()
  }

  /**
   * 当前控件id变化
   */
  onCurControllerIdChange (controlId?: number) {
    if (controlId) {
      this.activeName = controlId + ''
    } else if (this.controllerList && this.controllerList.length > 0) {
      this.activeName = this.controllerList[0].id + ''
    } else {
      this.activeName = ''
    }
  }

  controllerList: Array<ControllerConfig> = []

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)

  pageData: PageListData<ControllerConfig> = {
    items: [],
    total: 0,
    pageSize: 10000
  }

  loadDataFromServer () {
    this.isShowSpin = true
    this.queryData.projectId = getterProjectId()
    // 清空关键字
    if (
      this.queryData.queryControllerKey &&
      this.queryData.queryControllerKey === ''
    ) {
      this.queryData.queryControllerKey = undefined
    }
    this.queryData.curVersionId = this.entityModelVersionId
    ControllerConfigApi.query(this.queryData)
      .then(resp => {
        this.pageData = resp.data!
        this.controllerList = this.pageData.items
        if (this.controllerList && this.controllerList.length > 0) {
          this.onSelectMenu(this.controllerList[0].id + '', true)
        }
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
        this.forceUpdateMenuSelected()
      })
  }

  /// 新增controlbutton
  onClickAddNewControllerButton () {
    const that = this
    Modals.showControllerConfigCreatorModal(async data => {
      try {
        data.curVersionId = that.entityModelVersionId
        data.projectId = getterProjectId()
        data.querybeanUuid = getShortUuid()
        data.fromEntityName = data.entity?.name
        const entity = _.cloneDeep(data.entity)!
        const res = await ControllerConfigApi.insertItem(data)
        if (data.ifFromEntity) {
          const querybean = this.createQuerybeanByEntity(
            data.querybeanUuid,
            res.data?.id!,
            entity
          )
          const querybeanProperties = await entityToQueryBeanConfig(
            entity,
            data.querybeanUuid
          )
          const qb = queryBeanConfigApi.insertItem(querybean)
          const qbp =
            queryBeanConfigPropertyApi.insertItems(querybeanProperties)
          await Promise.all([qb, qbp])
        }
        this.$Message.success('操作成功')
        updateCurControllerIdAndInfo(res.data!.id!, {
          name: res.data!.className!,
          title: res.data!.title!
        })
        this.loadDataFromServer()
      } catch (err) {
        this.$Message.error({
          content: err.message
        })
      }
    })
  }

  /// 删除当前control
  onClickDeleteControlButton () {
    const controlId = getterCurControllerId()!
    if (!controlId) return
    this.$Modal.confirm({
      title: '删除control吗',
      content: '确定删除control吗？删除之后不可恢复！',
      onOk: () => {
        ControllerConfigApi.deleteItemByIds([controlId])
          .then(res => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  // 禁用control
  onDisableControlButton () {
    const controlId = getterCurControllerId()!
    if (!controlId) return

    this.$Modal.confirm({
      title: '确认',
      content: '确定要禁用control吗？',
      onOk: () => {
        ControllerConfigApi.disableControl(controlId)
          .then(resp => {
            this.$Message.success('禁用control成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error('禁用control失败:' + err.message)
          })
      }
    })
  }

  // 启用control
  onEnableControlButton () {
    const controlId = getterCurControllerId()!
    if (!controlId) return

    this.$Modal.confirm({
      title: '确认',
      content: '确定要启用control吗？',
      onOk: () => {
        ControllerConfigApi.enableControl(controlId)
          .then(resp => {
            this.$Message.success('启用control成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error('启用control失败:' + err.message)
          })
      }
    })
  }

  /**
   * 菜单选择
   * @param name 菜单名称
   * @param notforceRoute 是否不强制跳转
   */
  onSelectMenu (name: string, notforceRoute: boolean) {
    const controlId = parseInt(name)

    // 通过id找到对应的control
    const index = this.controllerList.findIndex(item => item.id === controlId)
    const controlInfo = this.controllerList[index]

    // 执行跳转操作
    updateCurControllerIdAndInfo(controlInfo.id!, {
      name: controlInfo.className!,
      title: controlInfo.title!
    })
    if (!notforceRoute && this.$route.name !== 'controller-view') {
      this.$router.push({ name: 'controller-view' })
    }
  }

  /**
   * 当前controlinfo变化
   */
  @Watch('curControlInfo')
  onCurControlInfoChange (value?: CurControllInfo) {
    const id = getterCurControllerId()
    if (id) {
      const index = this.controllerList.findIndex(item => item.id === id)
      if (index >= 0) {
        this.controllerList[index].title = value?.title
        this.controllerList[index].className = value?.name
      }
    }
  }

  onQueryControllerConfigs () {
    this.loadDataFromServer()
  }

  onContextmenu () {
    this.$contextmenu({
      items: [
        {
          label: '返回(B)',
          onClick: () => {
            console.log('返回(B)')
          }
        }
      ]
    })
  }

  // 强制更新选择的菜单
  forceUpdateMenuSelected () {
    this.onCurControllerIdChange(getterCurControllerId())
    const menu = this.$refs.menu as Menu
    menu.updateActiveName()
  }

  /**
   * 创建关联实体的querybean实例
   * @param {string} querybeanUuid
   * @param {number} controllerId 控制器ID
   * @param {string} entityUuid 关联实体UUID
   */
  createQuerybeanByEntity (
    querybeanUuid: string,
    controllerId: number,
    entity: Entity
  ): QueryBeanConfig {
    return {
      uuid: querybeanUuid,
      controllerConfigId: controllerId,
      title: `关联实体${entity.title}的QueryBean`,
      className: 'QueryBean',
      packageName: 'controller',
      description: `关联实体${entity.title}的QueryBean`,
      fromEntityUuid: entity.uuid,
      disable: false,
      ifFromEntity: true
    }
  }
}
