
import { getterProject } from '@/store'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'ProjectInfo'
})
export default class ProjectInfo extends Vue {
  get projectName () {
    return getterProject()?.title
  }
}
