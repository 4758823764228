
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { getProjectDataTypes, DataTypeDescript } from '@/libs/entitymodel/field-type-descript'

/**
 * 数据类型自动选择完成组件
 */
@Component({
  name: 'dataTypesAutoComplete'
})
export default class DataTypesAutoComplete extends Vue {
  // 可选择项目
  items: DataTypeDescript[]=[]

  @Prop({
    required: false,
    type: String,
    default: ''
  })
  value!: string

  /// 可查询项
  get seachItems () {
    const list = this.items.map((item) => item.fullName)
    if (!this.value || this.value === '') return list

    return list.filter((item) => item.toUpperCase().startsWith(this.value.toUpperCase()) ||
        item.toUpperCase().indexOf('.' + this.value.toUpperCase()) >= 0)
  }

  /// 组件创建事件
  created () {
    getProjectDataTypes()
      .then((resp) => {
        this.items = resp
      })
      .catch((err) => {
        this.$Message.error('加载数据失败:' + err.message)
      })
  }

  /**
   * 数据变化事件 实现v-mode
   */
  onChange (v: string) {
    this.$emit('input', v)
  }

  // 过滤函数
  filterMethod (value: string, option: any) {
    console.log(option)
  }
}
