import DefaultApiService, { BaseHasTimeEntity, HasStateMachineVersionEntity } from '@/libs/api-request'
import request, { DataApiPageResult, DataApiResult, PagerQueryBean } from '@/libs/http-request'

export interface ProjectMemberItems {
  // 添加对象id列表
  ids: number[];
  // 添加对象类型
  memberType: number;
  // 对应的项目id
  projectId: number;
}

export interface ProjectMemberRight{
  /// 更新模型权限
  updateDataModel: boolean;

  /// 更新工程权限
  updateProjectInfo: boolean;

  /// 更新api权限
  updateApiInterface: boolean;

  /// 工程成员管理
  manageProjectMember: boolean;

  /// 代码生成
  codegener: boolean;
}

/**
 * 项目成员
 */
export interface ProjectMember extends BaseHasTimeEntity, HasStateMachineVersionEntity{
    // 项目成员名称
    memberName?: string;

    // 加入时间
    joinTime?: string;

    // 修改数据模型
    updateDataModel?: boolean;

    // 修改项目信息
    updateProjectInfo?: boolean;

    // 修改api接口
    updateApiInterface?: boolean;

    // 管理项目成员
    manageProjectMember?: boolean;

    // 生成代码
    codeGener?: boolean;

    // 项目id
    projectId?: number;

    // 成员id:个人成员id,小组id
    memberId?: number;

    // 团队id
    teamId?: number;

    // 成员类型:0个人,1小组
    memberType?: number;

    // 成员类型名称
    memberTypeName?: string;
}

class ProjectMemberApi extends DefaultApiService<ProjectMember> {
  /**
   * 个人
   */
  public Individual = 0
  /**
   * 小组
   */
  public Group = 1

  public updateDataModel = 'updateDataModel'
  public updateProjectInfo = 'updateProjectInfo'
  public updateApiInterface = 'updateApiInterface'
  public manageProjectMember = 'manageProjectMember'
  public codeGener = 'codeGener'

  constructor () {
    super('/api/project-member')
  }

  /**
     * 团队小组接口
     * @param query 查询bean对象
     */
  queryProjectMemberList (query: PagerQueryBean) {
    return this.queryByQueryBean('project-member-list', query)
  }

  /**
   * 保存项目成员(个人/组)
   * @param data - {ids: (个人成员id/组成员id) , memberType: 1个人,2小组,projectId:项目id}
   */
  saveProjectMember (data: ProjectMemberItems) {
    return this.request('saveProjectMember', data, 'POST')
  }

  /**
   * 修改项目成员权限
   * @param id 主键id
   * @param disable  false,true
   * @param flag 修改数据模型(updateDataModel) 修改项目信息(updateProjectInfo) 修改api接口(updateApiInterface) 管理项目成员(manageProjectMember)
   */
  projectMemberPermission (id: number, disable: boolean, flag: string) {
    const url = 'projectMemberPermission/' + id + '/' + disable + '/' + flag
    return this.request(url, null, 'GET')
  }

  /**
   * 取得项目的成员列表
   * @param projectId
   * @param type
   * @returns
   */
  getMemberListByProjectId (projectId: number, type: number) {
    return this.requestList(
      type === this.Individual ? `getIndividualMemberListByProjectId/${projectId}`
        : `getGroupMemberListByProjectId/${projectId}`,
      null,
      'GET'
    )
  }

  /**
   * 取得当前项目成员
   * @param projectId
   */
  getUserProjectMemberRight (projectId: number): Promise<DataApiResult<ProjectMemberRight>> {
    return request({
      url: `${this.baseUrlPath}/getUserProjectMemberRight/${projectId}`,
      method: 'GET'
    })
  }
}

export default new ProjectMemberApi()
