import { ControllerConfig, HasEntityControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import { HasEntityQueryBeanConfig, QueryBeanConfig } from '@/api/project/apimanager/query-bean-config/query-bean-config'
import { ControllerConfigDetail } from '@/api/project/apimanager/api-config/controller-config-detail'
import { showModal } from '@/libs/modal-helper'
import ControllerCreatorModal from './controller-creator-modal.vue'
import QueryBeanCreatorModal from './query-bean-creator-modal.vue'
import VueComponentConfigModal from './vue-component-config-modal.vue'
import ApiConfigCreatorModal from './api-config-creator-modal.vue'
import Entity from '@/libs/entitymodel/entity'
import { FormComponentConfig } from '@/libs/form-generate'
import RightEditModal from './right-edit-modal.vue'

export default class Modals {
  static showControllerConfigCreatorModal (onOk?: (data: HasEntityControllerConfig) => Promise<void>) {
    showModal<any>(ControllerCreatorModal, {
      props: {
        dataProps: { ifFromEntity: 0 },
        newMode: true
      }
    }, true, onOk, undefined)
  }

  static showControllerConfigUpdaterModal (config: HasEntityControllerConfig, onOk?: (data: ControllerConfig) => Promise<void>) {
    showModal<any>(ControllerCreatorModal, {
      props: {
        title: '编辑Controller',
        dataProps: config,
        newMode: false
      }
    }, true, onOk, undefined)
  }

  static showQueryBeanConfigCreatorModal (ifFromEntity: boolean, onOk?: (data: HasEntityQueryBeanConfig) => Promise<void>) {
    showModal<any>(QueryBeanCreatorModal, {
      props: {
        dataProps: { ifFromEntity: ifFromEntity ? 1 : 0 },
        newMode: true
      }
    }, true, onOk, undefined)
  }

  /**
   * 显示查询bean更新对话框
   * @param config
   * @param onOk
   */
  static showQueryBeanConfigUpdateModal (config: QueryBeanConfig, onOk?: (data: QueryBeanConfig) => Promise<void>) {
    showModal<any>(QueryBeanCreatorModal, {
      props: {
        title: '编辑QueryBean',
        dataProps: config,
        newMode: false
      }
    }, true, onOk, undefined)
  }

  static showApiConfigCreatorModal (newMode: boolean, onOk?: (data: ControllerConfigDetail) => Promise<void>) {
    showModal<any>(ApiConfigCreatorModal, {
      props: {
        dataProps: { },
        newMode: newMode
      }
    }, true, onOk, undefined)
  }

  static showVueComponentConfigModal (config: Entity, onOk?: (data: FormComponentConfig) => Promise<void>) {
    showModal<any>(VueComponentConfigModal, {
      props: {
        title: '配置Vue组件',
        dataProps: config,
        newMode: true
      }
    }, true, onOk, undefined)
  }

  static showRightEditModal (rights: string, onOk?: (data: string) => Promise<void>) {
    showModal<any>(RightEditModal, {
      props: {
        rights
      }
    }, true, onOk, undefined)
  }
}
