import { render, staticRenderFns } from "./show-team-info-modal.vue?vue&type=template&id=7bc64ec0&scoped=true&"
import script from "./show-team-info-modal.vue?vue&type=script&lang=ts&"
export * from "./show-team-info-modal.vue?vue&type=script&lang=ts&"
import style0 from "./show-team-info-modal.vue?vue&type=style&index=0&id=7bc64ec0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc64ec0",
  null
  
)

export default component.exports