
import { Vue, Component, Watch } from 'vue-property-decorator'
import CollapsePanel from '@/components/collapse-panle.vue'
import { QueryBeanConfig } from '@/api/project/apimanager/query-bean-config/query-bean-config'
import QueryBeanConfigApi from '@/api/project/apimanager/query-bean-config/query-bean-config-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import Modals from '@/views/project/api-manager/controller-view/modal/modals'
import QueryBeanPropertyList from './query-bean-property-list/query-bean-property-list.vue'
import { getterCurControllerId } from '@/store'
import queryBeanPropertyConfigApi from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config-api'
import { entityToQueryBeanConfig } from '@/libs/querybean-proerty-helper'
import { getShortUuid } from '@/libs/uuid'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-createTime']
}

@Component({
  name: 'QueryBeanList',
  components: { CollapsePanel, QueryBeanPropertyList }
})
export default class QueryBeanList extends Vue {
  // 当前打开的panel
  openPanel = ''

  // 返回当前的controllid
  get curControllerId () {
    return getterCurControllerId()
  }

  @Watch('curControllerId')
  onIdChange () {
    this.loadDataFromServer()
  }

  isShowSpin = false

  queryBeanList: Array<QueryBeanConfig> = []

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)

  pageData: PageListData<QueryBeanConfig> = {
    items: [],
    total: 0,
    pageSize: 10000
  }

  created () {
    this.loadDataFromServer()
  }

  loadDataFromServer () {
    if (!this.curControllerId) {
      return
    }
    this.queryData.controllerConfigId = this.curControllerId
    this.isShowSpin = true
    QueryBeanConfigApi.query(this.queryData)
      .then(resp => {
        this.pageData = resp.data!
        this.queryBeanList = this.pageData.items
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  // 添加新的querybean
  onClickAddNewQueryBeanButton (ifFromEntity: boolean) {
    const that = this
    if (!that.curControllerId) {
      return
    }
    Modals.showQueryBeanConfigCreatorModal(ifFromEntity, async data => {
      try {
        data.controllerConfigId = that.curControllerId
        data.uuid = data.uuid ?? getShortUuid()
        if (data.ifFromEntity) {
          data.fromEntityUuid = data.entityUuid
          const cqb = QueryBeanConfigApi.insertItem(data)
          const querybeanProperties = await entityToQueryBeanConfig(data.entity!, data.uuid)
          const cqbp = queryBeanPropertyConfigApi.insertItems(querybeanProperties)
          await Promise.all([cqb, cqbp])
        } else {
          await QueryBeanConfigApi.insertItem(data)
        }
        this.$Message.success('操作成功')
        this.loadDataFromServer()
      } catch (err) {
        this.$Message.error({
          content: err.message
        })
      }
    })
  }

  editingIndex = -1

  onClickActiveEditQueryBean (index: number) {
    this.editingIndex = index
  }

  onClickCancelEditQueryBean () {
    this.editingIndex = -1
  }

  onClickUpdateQueryBeanButton (queryBeanConfig: QueryBeanConfig) {
    const that = this
    if (!that.curControllerId) {
      return
    }
    Modals.showQueryBeanConfigUpdateModal(queryBeanConfig, data => {
      return new Promise<void>((resolve, reject) => {
        QueryBeanConfigApi.updateItem(data)
          .then(() => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
            reject(err)
          })
      })
    })
  }

  // 禁用或启用querybean
  enableOrDisable (querybean: QueryBeanConfig) {
    let text = '禁用'
    if (querybean.disable) {
      text = '启用'
    }
    this.$Modal.confirm({
      title: text,
      content: `是否${text} ${querybean.title} QueryBean？`,
      onOk: () => {
        QueryBeanConfigApi.enableOrDisable(querybean)
          .then(() => {
            this.$Message.success(`${text}操作成功`)
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
          .finally(() => {
            this.loadDataFromServer()
          })
      },
      onCancel: () => {
        this.loadDataFromServer()
      }
    })
  }

  // 删除querybean
  onDelete (querybeanId: number) {
    this.$Modal.confirm({
      title: '删除',
      content: '是否删除该QueryBean？',
      onOk: () => {
        QueryBeanConfigApi.deleteItemByIds([querybeanId])
          .then(() => {
            this.$Message.success('删除操作成功')
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
          .finally(() => {
            this.loadDataFromServer()
          })
      }
    })
  }
}
