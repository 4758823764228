
import { Prop, Vue, Component, Model } from 'vue-property-decorator'
import server from '@/api/project/entity-model-version/entity-model-version-api'
import { dispatchCurEntityModelVersion, getterProjectId } from '@/store'

// 选择组件
@Component({ name: 'EntityModelVersionSelector', components: {}, mixins: [] })
export default class EntityModelVersionSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  /**
   * 项目id
   */
  @Prop({
    required: false,
    type: Number,
    default: getterProjectId()
  })
  projectId!: number

  curValue = 0

  @Prop({
    type: String,
    default: '版本标题'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  isDefalutSelect!: boolean

  isDefalutSeleted = false

  loading = false

  datas: Array<any> = []

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
    this.$emit('on-change', newValue)
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
    this.$emit('on-select', newValue)
    dispatchCurEntityModelVersion()
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const that = this as any
    const queryData = {
      page: 1,
      pageSize: 100,
      state: 1,
      userId: this.userId,
      sorts: []
    }
    server
      .getVersionListByProjectId(this.projectId)
      .then((respose) => {
        const items = respose.data!
        that.loading = false
        this.datas = items.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
        if (this.isDefalutSelect && !this.isDefalutSeleted && this.datas.length > 0) {
          this.onSelect(this.datas[0])
          this.isDefalutSeleted = true
        }
      }).catch(err => {
        this.$Notice.error({ title: '错误', desc: '加载实体版本数据失败:' + err.message })
      }).finally(() => {
        this.$emit('onReady')
      })
  }

  created () {
    this.loadDataFromServer()
  }
}
