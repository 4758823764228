/**
 * QueryBean属性配置模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { ConditionTypeItem, QueryBeanPropertyConfig } from './query-bean-property-config'

/**
 * QueryBean属性配置服务请求api接口
 */
class QueryBeanPropertyConfigApi extends DefaultApiService<QueryBeanPropertyConfig> {
  /**
 * 返回当前系统支持的所有条件类型
 * @returns
 */
  getConditionTypeItems () {
    return request<DataApiResult<ConditionTypeItem[]>>({
      url: `${this.baseUrlPath}/getConditionTypeItems`,
      method: 'GET'
    })
  }

  /**
    * 新增指定的数据
    * @param data
    */
  public insertItems (datas: QueryBeanPropertyConfig[]): Promise<DataApiResult<QueryBeanPropertyConfig[]>> {
    datas.map(e => ({ ...e, id: undefined }))
    return this.request('/insertItems', datas, 'PUT') as Promise<any>
  }

  /**
   * 更新querybean的所属控制器
   */
  // public updateControllerId(controllerId): Promise
}

export default new QueryBeanPropertyConfigApi('/api/queryBeanPropertyConfig')
