import { showModal } from '@/libs/modal-helper'
import EnumerationCreatorModal from './enumeration-creator-modal.vue'
import EnumerationItemCreatorModal from './enumeration-item-creator-modal.vue'
import { EnumerationConfig } from '@/api/project/enummanager/enum-config/enum-config'
import { EnumerationItem } from '@/api/project/enummanager/enum-item/enum-item'

export default class Modals {
  /**
   * 打开枚举创建窗口
   * @param onOk
   */
  static showEnumerationConfigCreatorModal (onOk?: (data: EnumerationConfig) => Promise<void>) {
    showModal<any>(EnumerationCreatorModal, {
      props: {
        newMode: true
      }
    }, true, onOk, undefined)
  }

  /**
   * 打开枚举编辑窗口
   * @param config
   * @param onOk
   */
  static showEnumerationConfigUpdaterModal (config: EnumerationConfig, onOk?: (data: EnumerationConfig) => Promise<void>) {
    showModal<any>(EnumerationCreatorModal, {
      props: {
        title: '编辑Enumeration',
        dataProps: config,
        newMode: false
      }
    }, true, onOk, undefined)
  }

  /**
   * 打开枚举项创建窗口
   * @param onOk
   * @param defaultConfig 新枚举项的默认参数
   */
  static showEnumerationItemConfigCreatorModal (onOk?: (data: EnumerationItem) => Promise<void>, defaultConfig?: EnumerationItem) {
    showModal<any>(EnumerationItemCreatorModal, {
      props: {
        newMode: true,
        dataProps: defaultConfig
      }
    }, true, onOk, undefined)
  }

  /**
   * 打开枚举项编辑窗口
   * @param onOk
   */
  static showEnumerationItemConfigUpdaterModal (config: EnumerationItem, onOk?: (data: EnumerationConfig) => Promise<void>) {
    showModal<any>(EnumerationItemCreatorModal, {
      props: {
        title: '编辑EnumerationItem',
        dataProps: config,
        newMode: false
      }
    }, true, onOk, undefined)
  }
}
