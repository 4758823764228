
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import queryBeanPropertyConfigApi from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config-api'
import { ConditionTypeItem } from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config'

// 选择组件
@Component({ name: 'ConditionTypeSelector', components: {}, mixins: [] })
export default class ConditionTypeSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Prop({
    required: true,
    type: Number,
    default: -1
  })
  readonly value!: number

  /**
   * 当前值
   */
  get curValue () {
    return this.value
  }

  @Prop({
    type: String,
    default: '输入条件名称'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  // 当前系统支持的条件类型
  conditionTypes: ConditionTypeItem[]=[]

  created () {
    // 加载条件列表
    queryBeanPropertyConfigApi.getConditionTypeItems()
      .then((resp) => {
        this.conditionTypes = resp.data!
      }).catch((err) => {
        this.$Message.error('加载表条件数据错误:' + err.message)
      })
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
  }

  initData () {
    this.$emit('input', undefined)
  }
}
