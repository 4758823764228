import JSZip from 'jszip'
import FileSaver from 'file-saver'

/**
 * 文件打包管理器
 */
export default class Filepack {
  /** 根目录实例 */
  readonly rootFolder: JSZip

  constructor () {
    this.rootFolder = new JSZip()
  }

  /**
   * 添加文件到目录
   * @param path
   * @param content
   * @param option
   */
  addFile (path: string, content: any, option?: JSZip.JSZipFileOptions): void {
    this.rootFolder.file(path, content, option)
  }

  /**
   * 保存到本地
   * @param fileName
   */
  async save (fileName: string): Promise<void> {
    if (fileName && fileName === '') {
      throw new Error('文件名不能为空')
    }
    const content = await this.rootFolder.generateAsync({ type: 'blob' })
    FileSaver.saveAs(content, `${fileName}.zip`)
  }
}
