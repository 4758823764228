import { ConfigApplication } from './../api/maintain/config-application'
import Cookies from 'js-cookie'
import { BaseProjectInfo } from '@/api/project'
import { ProjectMemberRight } from '@/api/project-member'
import { UserInfo } from '@/api/security'
import { CurControllInfo } from '@/store/module/api-manager'
import { CurEnumerationInfo } from '@/store/module/enum-manager'
import { RouteMoudleInfo } from '@/store/module/moudle'
import ProjectVersion from './entitymodel/project-verion'
import { ModuleItem } from './sys-modules'
import { ModuleRightInfo } from './user-rights'
import config from '@/config'
import { AppInstance } from '@/api/maintain/app-instance'

const KeyUserId = 'SystemWeb_UserId'
const KeyTeamCode = 'SystemWeb_TeamCode'
const KeyTagList = 'SystemWeb_TagList'
const KeyCurMenuCode = 'SystemWeb_CurMenuCode'
const KeyCurRouteMoudleCode = 'SystemWeb_CurRouteCode'
const KeyCurUserInfo = 'SystemWeb_CurUserInfo'
const KeyCurUserModuleRights = 'SystemWeb_CurUserModuleRights'
const KeyBreadCrumbList = 'SystemWeb_BreadCrumbList'
const KeyLastPath = 'SystemWeb_LastPath'
const KeyProject = 'SystemWeb_Project'
const KeyProjectId = 'SystemWeb_ProjectId'
const KeyActiveEntityUuid = 'SystemWeb_ActiveEntityUuid'
const KeyProjectLanguages = 'SystemWeb_ProjectLanguages'
const KeyLockOrgIds = 'SystemWeb_LockOrgIds'
const KeyEntityModelVersion = 'SystemWeb_entiyModelVersion'
const KeyCurPageId = 'SystemWeb_CurPageId'
const KeyCurPageMode = 'SystemWeb_CurPageMode'
const KeyCurPageExtData = 'SystemWeb_CurPageExtData'
const KeyUserLastTeamId = 'SystemWeb_LastUserTeamId'
const KeyCurControlId = 'SystemWeb_CurControlId'
const KeyCurControlInfo = 'SystemWeb_CurControlInfo'
const KeyCurEnumerationId = 'SystemWeb_CurEnumerationId'
const KeyCurEnumerationInfo = 'SystemWeb_CurEnumerationInfo'
const KeyCurProjectMemberRight = 'SystemWeb_KeyCurProjectMemberRight'
const KeyCurInstanceIp = 'SystemWeb_KeyCurInstanceIp'
const KeyCurConfigApplication = 'SystemWeb_KeyCurConfigApplication'
const KeyCurProfile = 'SystemWeb_KeyCurProfile'
const KeyGateway = 'SystemWeb_KeyGateway'
const KeyConsul = 'SystemWeb_Consul'
const KeyConfigCenter = 'SystemWeb_ConfigCenter'

/**
 * 本地存贮辅助类
 */
class LocalStorage {
  saveUserId (id?: number) {
    const data = id ? id + '' : ''
    sessionStorage.setItem(KeyUserId, data)
  }

  /**
   * 加载用户id
   * @returns
   */
  loadUserId (): number|undefined {
    const data = sessionStorage.getItem(KeyUserId)
    if (data) return parseInt(data)
    return undefined
  }

  /**
   * 保存团队编号
   * @param code
   */
  saveTeamCode (code?: string) {
    const data = code || ''
    sessionStorage.setItem(KeyTeamCode, data)
  }

  /**
   * 读取团队编号
   * @returns
   */
  loadTeamCode (): string|undefined {
    const data = sessionStorage.getItem(KeyTeamCode)
    if (data === '' || !data) return undefined
    return data!
  }

  /**
   * 记录当前页面的id
   * @param id
   */
  saveCurPageId (id: number) {
    sessionStorage.setItem(KeyCurPageId, id + '')
  }

  /**
   * 记录当前页的额外数据
   */
  setCurExtraData (extraData: string) {
    sessionStorage.setItem(KeyCurPageExtData, extraData || '')
  }

  /**
   * 记录当前页的额外数据
   */
  loadCurExtraData () {
    const data = sessionStorage.getItem(KeyCurPageExtData)
    if (!data || data === '') return undefined
    return data
  }

  /**
   * 保存当前页的模式
   * @param mode
   */
  saveCurPageMode (mode: string) {
    sessionStorage.setItem(KeyCurPageMode, mode || '')
  }

  /**
   * 加载当前页面模式
   * @returns
   */
  loadCurPageMode (): string|undefined {
    const data = sessionStorage.getItem(KeyCurPageMode)
    if (data === '' || !data) return undefined
    return data!
  }

  /**
   * 加载当前页面的pageId
   * @returns
   */
  loadCurPageId () {
    const data = sessionStorage.getItem(KeyCurPageId)
    if (data) return parseInt(data)
    return undefined
  }

  /**
     * 保存标签列表
     * @param tagList
     */
  saveTagList (tagList: any) {
    sessionStorage.setItem(KeyTagList, JSON.stringify(tagList))
  }

  /**
   * 取得标签列表
   */
  loadTagList () {
    const data = sessionStorage.getItem(KeyTagList)
    if (data) return JSON.parse(data)
    return { main: [], webapi: [] }
  }

  /**
   * 保存当前菜单项Code
   * @param menuItem
   */
  saveCurMenuItemCode (menuItemCode: string|undefined) {
    sessionStorage.setItem(KeyCurMenuCode, menuItemCode || '')
  }

  /**
   * 加载当前菜单code
   */
  loadCurMenuItemCode (): string|undefined {
    const data = sessionStorage.getItem(KeyCurMenuCode)
    if (data === '' || !data) return undefined
    return data!
  }

  /**
   * 保存当前的路由位置的模块code
   * @param routeCode
   */
  saveCurRouteMoudelInfo (routeMoudleInfo: RouteMoudleInfo|undefined) {
    const data = routeMoudleInfo ? JSON.stringify(routeMoudleInfo) : ''
    sessionStorage.setItem(KeyCurRouteMoudleCode, data)
  }

  /**
   * 加载当前的路由位置的模块code
   */
  loadCurRouteMoudelInfo (): RouteMoudleInfo|undefined {
    const data = sessionStorage.getItem(KeyCurRouteMoudleCode)
    if (data === '' || !data) return undefined
    return JSON.parse(data!)
  }

  /**
   * 加载当前用户的信息
   */
  loadCurUserInfo (): UserInfo|undefined {
    const data = sessionStorage.getItem(KeyCurUserInfo)
    if (data === '' || !data) return undefined
    return JSON.parse(data!)
  }

  /**
   * 保存当前用户数据
   * @param userInfo
   */
  saveCurUserInfo (userInfo: UserInfo|undefined) {
    const data = userInfo ? JSON.stringify(userInfo) : ''
    sessionStorage.setItem(KeyCurUserInfo, data)
  }

  /**
   * 加载当前项目成员
   * @returns
   */
  loadCurProjectMemberRight (): ProjectMemberRight|undefined {
    const data = sessionStorage.getItem(KeyCurProjectMemberRight)
    if (data === '' || !data) return undefined
    return JSON.parse(data!)
  }

  /**
   * 保当前项目成员
   * @param teamUser
   */
  saveProjectMemberRight (projectMemberRight: ProjectMemberRight|undefined) {
    const data = projectMemberRight ? JSON.stringify(projectMemberRight) : ''
    sessionStorage.setItem(KeyCurProjectMemberRight, data)
  }

  /**
   * 保存当前用户的模块权限
   * @param moduleRights
   */
  loadCurUserModuleRights (): Record<string, ModuleRightInfo>|undefined {
    const data = sessionStorage.getItem(KeyCurUserModuleRights)
    if (data === '' || !data) return undefined
    return JSON.parse(data!)
  }

  /**
   * 保存当前用户的模块权限
   * @param moduleRights
   */
  saveCurUserModuleRights (moduleRights: Map<string, ModuleRightInfo>|undefined) {
    const data = moduleRights ? JSON.stringify(moduleRights) : ''
    sessionStorage.setItem(KeyCurUserModuleRights, data)
  }

  /**
   * 保存当前用户的位置
   * @param items
   */
  saveBreadCrumbList (items: Array<ModuleItem>|undefined) {
    const data = items ? JSON.stringify(items) : ''
    sessionStorage.setItem(KeyBreadCrumbList, data)
  }

  /**
   * 取得当前用户的位置
   */
  loadBreadCrumbList (): Array<ModuleItem> {
    const data = sessionStorage.getItem(KeyBreadCrumbList)
    if (data === '' || !data) return []
    return JSON.parse(data!)
  }

  /**
   * 保存最后的位置
   * @param path
   */
  saveLastPath (path?: string) {
    const data = path || ''
    sessionStorage.setItem(KeyLastPath, data)
  }

  /**
   * 取得最后的位置
   */
  loadLastPath (): string|undefined {
    const data = sessionStorage.getItem(KeyLastPath)
    if (data === '' || !data) return undefined
    return data
  }

  /**
   * 保存工程信息
   * @param project
   */
  saveProject (project?: BaseProjectInfo) {
    const data = project ? JSON.stringify(project) : ''
    sessionStorage.setItem(KeyProject, data)
  }

  /**
   * 读取工程信息
   */
  loadProject (): BaseProjectInfo|undefined {
    const data = sessionStorage.getItem(KeyProject)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  /**
   * 设置工程id
   * @param projectId
   */
  saveProjectId (projectId?: number) {
    const data = projectId ? projectId + '' : ''
    sessionStorage.setItem(KeyProjectId, data)
  }

  /**
   * 加载工程id
   */
  loadProjectId (): number|undefined {
    const data = sessionStorage.getItem(KeyProjectId)
    if (data === '' || !data) return undefined
    return parseInt(data)
  }

  /// 保存当前选择的实体uuid
  saveActiveEntityUuid (uuid?: string) {
    const data = uuid || ''
    sessionStorage.setItem(KeyActiveEntityUuid, data)
  }

  /**
   * 取得当前选择的实体uuid
   */
  loadActiveEntityUuid (): string|undefined {
    const data = sessionStorage.getItem(KeyActiveEntityUuid)
    if (data === '' || !data) return undefined
    return data
  }

  /**
   * 保存当前工程语言配置列表
   * @param list
   */
  saveLanguages (list: string[]) {
    if (!list) list = []
    const data = JSON.stringify(list)
    sessionStorage.setItem(KeyProjectLanguages, data)
  }

  /**
   * 取得当前语言的配置列表
   */
  loadLanguages (): string[] {
    // 先固定返回值
    // const data = sessionStorage.getItem(KeyProjectLanguages)
    // if (data === '' || !data) return []
    // return JSON.parse(data)
    return ['cn', 'en']
  }

  /**
   * 锁定的组织Ids
   * @param ids  组织的id列表
   */
  saveLockOrgIds (ids: string[]) {
    if (!ids) ids = []
    const data = JSON.stringify(ids)
    sessionStorage.setItem(KeyLockOrgIds, data)
  }

  /**
   * 加载锁定的组织Ids
   * @returns
   */
  loadLockOrgIds (): string[] {
    const data = sessionStorage.getItem(KeyLockOrgIds)
    if (data === '' || !data) return []
    return JSON.parse(data)
  }

  /**
   * 保存实体模型的版本
   * @param version 版本号
   */
  saveEntityModelVersion (projectId: number, version: ProjectVersion|undefined) {
    const key = KeyEntityModelVersion + '_' + projectId
    const data = version ? JSON.stringify(version) : ''
    sessionStorage.setItem(key, data)
  }

  /**
   * 取得当前实体模型的版本
   * @returns
   */
  loadEntityModelVersion (projectId: number): ProjectVersion|undefined {
    const key = KeyEntityModelVersion + '_' + projectId
    const data = sessionStorage.getItem(key)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  /**
   * 保存用户最近使用的团队id
   * @param teamId
   */
  saveUserLastTeamId (teamId?: number) {
    const data = teamId ? teamId + '' : ''
    Cookies.set(KeyUserLastTeamId, data, { expires: config.cookieExpires || 1 })
    // sessionStorage.setItem(KeyUserLastTeamId, data)
  }

  /**
   * 加载用户最近使用的团队id
   * @returns
   */
  loadUserLastTeamId () {
    const data = Cookies.get(KeyUserLastTeamId)
    // sessionStorage.getItem(KeyUserLastTeamId)
    if (data === '' || !data) return undefined
    return parseInt(data)
  }

  /**
   * 保存当前controlid
   * @param controlId
   */
  saveCurControlId (controlId?: number) {
    const data = controlId ? controlId + '' : ''
    sessionStorage.setItem(KeyCurControlId, data)
  }

  /**
   * 读取当前controlid
   * @return
   */
  loadCurControlId () {
    const data = sessionStorage.getItem(KeyCurControlId)
    if (data === '' || !data) return undefined
    return parseInt(data)
  }

  /**
   * 加载当前control的信息
   * @param controlInfo
   * @returns
   */
  loadCurControlInfo (controlInfo?: CurControllInfo) {
    const data = sessionStorage.getItem(KeyCurControlInfo)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  /**
   * 保存当前control信息
   * @param controlInfo
   */
  saveCurControlInfo (controlInfo?: CurControllInfo) {
    const data = controlInfo ? JSON.stringify(controlInfo) : ''
    sessionStorage.setItem(KeyCurControlInfo, data)
  }

  /**
   * 保存当前Enumerationid
   * @param enumerationId
   */
  saveCurEnumerationId (enumerationId?: number) {
    const data = enumerationId ? enumerationId + '' : ''
    sessionStorage.setItem(KeyCurEnumerationId, data)
  }

  /**
   * 读取当前Enumerationid
   * @return
   */
  loadCurEnumerationId () {
    const data = sessionStorage.getItem(KeyCurEnumerationId)
    if (data === '' || !data) return undefined
    return parseInt(data)
  }

  /**
   * 加载当前Enumeration的信息
   * @param EnumerationInfo
   * @returns
   */
  loadCurEnumerationInfo () {
    const data = sessionStorage.getItem(KeyCurEnumerationInfo)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  /**
   * 保存当前Enumeration信息
   * @param EnumerationInfo
   */
  saveCurEnumerationInfo (enumInfo?: CurEnumerationInfo) {
    const data = enumInfo ? JSON.stringify(enumInfo) : ''
    sessionStorage.setItem(KeyCurEnumerationInfo, data)
  }

  /**
   * 获取当前实例ip
   * @returns
   */
  loadCurInstanceIp () {
    const data = sessionStorage.getItem(KeyCurInstanceIp)
    if (data === '' || !data) return undefined
    return data
  }

  /**
   * 设置当前实例ip
   * @param curInstanceIp
   */
  saveCurInstanceIp (curInstanceIp?: string) {
    const data = curInstanceIp || ''
    sessionStorage.setItem(KeyCurInstanceIp, data)
  }

  /**
   * 加载当前配置项目信息
   * @param EnumerationInfo
   * @returns
   */
  loadCurConfigApplication () {
    const data = sessionStorage.getItem(KeyCurConfigApplication)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  /**
   * 保存当前配置项目信息
   * @param capplication
   */
  saveCurConfigApplication (capplication?: ConfigApplication) {
    const data = capplication ? JSON.stringify(capplication) : ''
    sessionStorage.setItem(KeyCurConfigApplication, data)
  }

  /**
   * 加载当前配置项目版本信息
   * @param EnumerationInfo
   * @returns
   */
  loadCurProfile () {
    const data = sessionStorage.getItem(KeyCurProfile)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  /**
     * 保存当前配置项目版本信息
     * @param capplication
     */
  saveCurProfile (curProfile?: ConfigApplication) {
    const data = curProfile ? JSON.stringify(curProfile) : ''
    sessionStorage.setItem(KeyCurProfile, data)
  }

  /** 网关默认实例 */
  loadGatewayInstance () {
    const data = sessionStorage.getItem(KeyGateway)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  saveGatewayInstance (gatewayInstance?: AppInstance) {
    const data = gatewayInstance ? JSON.stringify(gatewayInstance) : ''
    sessionStorage.setItem(KeyGateway, data)
  }

  /** consul默认实例 */
  loadConsulInstance () {
    const data = sessionStorage.getItem(KeyConsul)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  saveConsulInstance (consulInstance?: AppInstance) {
    const data = consulInstance ? JSON.stringify(consulInstance) : ''
    sessionStorage.setItem(KeyConsul, data)
  }

  /** 配置中心默认实例 */
  loadConfigCenterInstance () {
    const data = sessionStorage.getItem(KeyConfigCenter)
    if (data === '' || !data) return undefined
    return JSON.parse(data)
  }

  saveConfigCenterInstance (configCenterInstance?: AppInstance) {
    const data = configCenterInstance ? JSON.stringify(configCenterInstance) : ''
    sessionStorage.setItem(KeyConfigCenter, data)
  }
}

export default new LocalStorage()
