
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { RawLocation, Route } from 'vue-router'
import { dispatchAddTag, dispatchRemoveTag, getterTagCollectsGetter, dispatchUpdateCurTagName, dispatchClearTags } from '@/store'
import { TagInfo, routeEqual, TagInfoCollection } from '@/store/module/app'
import _ from 'lodash'

/**
 * 架构页签面版
 * 当前还没有处理关闭还有菜单的操作
 */

@Component({
  name: 'tagbarPanel'
})
export default class TagbarPanel extends Vue {
  @Prop({
    required: true,
    type: String
  })
  name!: string

  @Watch('tagListCollection', { deep: true })
  private watchTagListCollection (tagList: TagInfoCollection) {
    // 获取当前tag
    const curTag = this.tagListCollection[this.name].find(item => item.checked)!
    // 获取根元素的domrect对象
    const rootDom = (this.$refs.tagpanel as Element).getBoundingClientRect() as DOMRect
    // 获取当前元素的domrect对象
    this.$nextTick(() => {
      const dom = (this.$refs[`tag_${curTag.id}`] as any)[0].$el.getBoundingClientRect() as DOMRect
      // 判断当前元素是否在根元素内
      if (dom.x - rootDom.x < 0 || dom.x - rootDom.x > rootDom.width) {
        ;(this.$refs.tagpanel as any).scrollLeft += dom.x - rootDom.x
      } else if (rootDom.width + rootDom.x - dom.x < dom.width) {
        ;(this.$refs.tagpanel as any).scrollLeft += dom.width + dom.x - (rootDom.width + rootDom.x)
      }
    })
  }

  /**
   * 面版滚动事件
   */
  scrollPanel (event: MouseEvent | number) {
    let offset = 0
    if (typeof (event) !== 'number') {
      offset = (event as any).wheelDelta
    } else {
      offset = event as number
    }
    (this.$refs.tagpanel as any).scrollLeft += offset
  }

  /**
   * 选择一个项
   */
  onCheckItem (cItem: TagInfo) {
    const tagList = this.tagListCollection[this.name]
    // const arr = this.tagList.filter(item => item.id === name)
    for (const item of tagList) {
      if (item.id === cItem.id) {
        item.checked = true
        item.lastModiTime = new Date().valueOf()
      } else item.checked = false
    }
    if (!routeEqual(this.$route, cItem.route)) {
      // 找开对应的页面
      this.$router.push(cItem.route as RawLocation)
    }
  }

  /**
   * 关闭一个页签
   */
  onCloseItem (index: number) {
    const tagList = _.clone(this.tagListCollection[this.name])
    if (tagList.length <= 1) {
      this.$Notice.info({
        title: '提醒',
        desc: '只有一个页面了，不能再关闭了'
      })
      return
    }
    const item = tagList[index]
    tagList.sort((a, b) => a.lastModiTime - b.lastModiTime)
    const sortIndex = tagList.indexOf(item)
    const nextIndex = (sortIndex === tagList.length - 1) ? sortIndex - 1 : sortIndex + 1
    const nextItem = tagList[nextIndex]
    dispatchRemoveTag(this.name, item.route)
    // 跳到下一个页面
    this.$router.push(nextItem.route as RawLocation)
  }

  /**
   * 清除所有的标签
   */
  onClearAllItems () {
    dispatchClearTags(this.name, this.$route)
  }

  /**
   * 加载页面时记录页面
   */
  created () {
    dispatchUpdateCurTagName(this.name)
    dispatchAddTag(this.$route, this, this.name)
  }

  @Watch('$route')
  onRouteChange (curRoute: Route) {
    // 添加当前的页签列表
    dispatchAddTag(curRoute, this, this.name)
  }

  /**
   * 当前页签
   */
  get tagListCollection () {
    return getterTagCollectsGetter()
  }
}
