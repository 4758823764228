import { render, staticRenderFns } from "./org-selector-modal.vue?vue&type=template&id=72b2c0ba&scoped=true&"
import script from "./org-selector-modal.vue?vue&type=script&lang=ts&"
export * from "./org-selector-modal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b2c0ba",
  null
  
)

export default component.exports