/**
 * 选择团队对话框
 */
import { showModal } from '@/libs/modal-helper'
import modal from './org-selector-modal.vue'

/**
   * 显示选择组织信息的弹出对话框
   * @param userId
   * @returns
   */
export default function showSelectOrgTreeModal (): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    showModal<string>(modal, {
      props: {
      }
    }, true, (fullName: string) => {
      resolve(fullName)
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
