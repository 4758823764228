/**
 * Controller配置模块
 */

import DefaultApiService from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'
import { ControllerConfig } from './controller-config'

/**
 * Controller配置服务请求api接口
 */
class ControllerConfigApi extends DefaultApiService<ControllerConfig> {
  /**
     * 禁用control
     * @param controlerId
     * @returns
     */
  disableControl (controlerId: number) {
    return this.request(`disable/${controlerId}`, null, 'PUT')
  }

  /**
     * 启用control
     * @param controlerId
     * @returns
     */
  enableControl (controlerId: number) {
    return this.request(`enable/${controlerId}`, null, 'PUT')
  }

  /**
    * 新增指定的数据
    * @param data
    */
  public insertItem (data: ControllerConfig): Promise<DataApiResult<ControllerConfig>> {
    (data as any).id = undefined
    ;(data as any).entity = undefined
    return this.request('', data, 'PUT')
  }

  /**
    * 更新指定的数据
    * @param data 要删除数据对象
    */
  public updateItem (data: ControllerConfig): Promise<DataApiResult<ControllerConfig>> {
    (data as any).entity = undefined
    return this.request('', data, 'POST')
  }
}

export default new ControllerConfigApi('/api/controllerConfig')
