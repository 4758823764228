
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ControllerConfigDetail } from '@/api/project/apimanager/api-config/controller-config-detail'
import ApiConfigApi from '@/api/project/apimanager/api-config/controller-config-detail-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import Modals from '@/views/project/api-manager/controller-view/modal/modals'
import controllerConfigApi from '@/api/project/apimanager/controller-config/controller-config-api'
import EntityModelManger from '@/libs/entitymodel'
import { getterProjectId, getterEntityModelVersion } from '@/store'
import createComponent from '@/libs/form-generate/index'
import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-createTime']
}

@Component({
  name: 'ApiList',
  components: {}
})
export default class ApiList extends Vue {
  @Prop({
    type: Number,
    default: undefined
  })
  controllerConfigId?: number

  @Watch('controllerConfigId', { immediate: true })
  onIdChange () {
    this.loadDataFromServer()
  }

  isShowSpin = false

  // vue组件生成loading
  createComponentLoading = false

  apiList: Array<ControllerConfigDetail> = []

  /** 控制器信息实例 */
  controller: ControllerConfig = {}

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)

  pageData: PageListData<ControllerConfigDetail> = {
    items: [],
    total: 0,
    pageSize: 10000
  }

  loadDataFromServer () {
    if (!this.controllerConfigId) {
      return
    }
    this.isShowSpin = true
    this.queryData.controllerConfigId = this.controllerConfigId
    controllerConfigApi.getItemById(this.controllerConfigId).then(res => {
      this.controller = res.data!
    }).catch(err => {
      this.$Message.error('查询失败，原因：' + err.message)
    })
    ApiConfigApi.query(this.queryData)
      .then(resp => {
        this.pageData = resp.data!
        this.apiList = resp.data!.items
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  /**
   * 文件生成
   */
  async onCreateComponent () {
    this.createComponentLoading = true
    try {
      if (!this.controllerConfigId) { throw new Error('controllerConfigId为空') }
      if (!this.controller.fromEntityUuid) { throw new Error('控制器没有关联实体') }
      const manager = new EntityModelManger()
      await manager.loadAllOrgsAndEntities(getterProjectId()!, getterEntityModelVersion()!.uuid!)
      const entity = manager.getHasPackageInfoEntity(manager.getEntityByUuid(this.controller.fromEntityUuid!)!)
      if (!entity) { throw new Error(`没有找到uuid为${this.controller.fromEntityUuid}的实体`) }
      Modals.showVueComponentConfigModal(entity, (options) => {
        return createComponent(entity, this.controller, options)
      })
    } catch (err) {
      this.$Message.error(`文件生成失败：${err}`)
      console.error(err)
    } finally {
      this.createComponentLoading = false
    }
  }

  /**
   * 添加api事件
   */
  onClickAddNewApiButton () {
    const that = this
    if (!that.controllerConfigId) {
      return
    }
    Modals.showApiConfigCreatorModal(true, data => {
      data.controllerConfigId = that.controllerConfigId
      return new Promise<void>((resolve, reject) => {
        data.controllerConfigId = that.controllerConfigId
        ApiConfigApi.insertItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
            resolve()
            this.$router.push({
              name: 'api-editor',
              params: { id: res.data!.id }
            } as any)
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  /// 禁用或启用api
  enableOrDisable (api: ControllerConfigDetail) {
    let text = '禁用'
    if (api.disable) {
      text = '启用'
    }
    this.$Modal.confirm({
      title: text,
      content: `是否${text} ${api.title} Api？`,
      onOk: () => {
        ApiConfigApi.enableOrDisable(api)
          .then(resp => {
            this.$Message.success(`${text}操作成功`)
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
          .finally(() => {
            this.loadDataFromServer()
          })
      },
      onCancel: () => {
        this.loadDataFromServer()
      }
    })
  }

  /// 删除api
  onClickDeleteApiButton (api: ControllerConfigDetail) {
    if (!api.canDelete) {
      return
    }
    this.$Modal.confirm({
      title: '确认',
      content: `是否删除 ${api.title} Api？`,
      onOk: () => {
        ApiConfigApi.deleteItemByIds([api.id!])
          .then(resp => {
            this.$Message.info('操作成功')
          })
          .catch(err => {
            this.$Message.error('删除失败:' + err.message)
          })
          .finally(() => {
            this.loadDataFromServer()
          })
      }
    })
  }

  /** 统计权限数量 */
  getRightCount (api: ControllerConfigDetail) {
    return api.permitRights?.split('/')?.filter(e => e.length > 0)?.length || 0
  }

  onRightClick (api: ControllerConfigDetail, index: number) {
    Modals.showRightEditModal(api.permitRights || '', async (data: string) => {
      api.permitRights = data
      ApiConfigApi.updateItem(api).then(() => {
        this.$set(this.apiList, index, {
          ...api,
          permitRights: data
        })
        this.$Notice.success({
          title: '操作成功',
          desc: '权限修改成功'
        })
      }).catch((err: any) => {
        this.$Notice.error({
          title: '操作失败',
          desc: err.message
        })
      })
    })
  }
}
