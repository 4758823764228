
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import ControllerInfoPanel from './controller-info-panel.vue'
import APIList from './api-list.vue'
import QueryBeanList from './query-bean-list/querybean-list.vue'
import { getterCurControllerId } from '@/store'

@Component({
  name: 'ControllerView',
  components: { ControllerInfoPanel, APIList, QueryBeanList }
})
export default class ControllerView extends Vue {
  // 当前的contorlid
  get currentControlId () {
    return getterCurControllerId()
  }

  labelOfApiList (h: any) {
    return h('div', [
      h('span', 'API列表')

    ])
  }

  labelOfQueryBeanList (h: any) {
    return h('div', [
      h('span', 'QueryBean列表')

    ])
  }
}
