import request, { ApiResult, DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { Method } from 'axios'
import { expect } from 'chai'

/**
 * 基础实体
 */
export interface BaseEntity {

  // 主键
  id?: number;

  version?: number;
}

/**
 * 拥有时间的实体
 */
export interface BaseHasTimeEntity extends BaseEntity {
  /** 创建时间 */
  createTime?: string;

  /** 修改时间 */
  modifyTime?: string;
}

/**
 * 拥有状态机的实体
 */
export interface HasStateMachineVersionEntity extends BaseEntity{
  /// 状态机版本
  stateMachineVersion?: string;
  /// 当前状态
  state?: number;
  /// 当前状态名称
  stateName?: string;
}

/**
 * 拥有创建者的实体
 */
export interface BaseHasCreatorEntity extends BaseEntity{

  // 创建者ID
  creator?: number;

  // 修改者ID
  mender?: number;

  // 创建者名称
  creatorName?: string;

  // 修改者名称
  menderName?: string;
}

/** 同时拥有时间与创建修改者信息的实体 */
export interface BaseHasCreatorAndTimeEntity extends BaseHasCreatorEntity{
  /** 创建时间 */
  createTime?: string;

  /** 修改时间 */
  modifyTime?: string;
}

export interface PageInfo{
  /// 页面大小
  pageSize?: number;
  /// 当前页号
  curPage?: number;
}

/**
 * Api请求封装，实现了后台访问的标准请求功能
 */

export default class DefaultApiService<T> {
   protected baseUrlPath: string

   constructor (baseUrlPath: string) {
     expect(baseUrlPath, '参数baseUrlPath不允许为空').not.empty.and.not.undefined

     this.baseUrlPath = baseUrlPath.endsWith('/') ? baseUrlPath : baseUrlPath + '/'
   }

   /**
    * 通过查询bean查询数据
    * @param query 查询bean数据对象
    */
   protected queryByQueryBean (url: string, query: PagerQueryBean, method: Method = 'POST', useToken = true): Promise<PageDataApiResult<T>> {
     return request<PageDataApiResult<T>>({
       url: this.baseUrlPath + url,
       method: method,
       data: query
     }, useToken)
   }

   /**
    * 执行api操作
    * @param url 请求地址
    * @param data 请求的数据
    * @param method 请求方式
    * @param useToken 是否使用token
    */
   protected request (url: string, data: any, method: Method = 'POST', useToken = true): Promise<DataApiResult<T>> {
     return request<DataApiResult<T>>({
       url: this.baseUrlPath + url,
       method: method,
       data: data
     }, useToken)
   }

   /**
    * 执行api操作
    * @param url 请求地址
    * @param data 请求的数据
    * @param method 请求方式
    * @param useToken 是否使用token
    */
   protected requestList (url: string, data: any, method: Method = 'POST', useToken = true): Promise<DataApiResult<Array<T>>> {
     return request<DataApiResult<Array<T>>>({
       url: this.baseUrlPath + url,
       method: method,
       data: data
     }, useToken)
   }

   /**
    * 取得id对应的对象的值
    * @param id 要删除记录的主键id
    */
   public getItemById (id: number): Promise<DataApiResult<T>> {
     return this.request(`getItemById/${id}`, undefined, 'GET')
   }

   /**
    * 新增指定的数据
    * @param data
    */
   public insertItem (data: T): Promise<DataApiResult<T>> {
     (data as any).id = undefined
     return this.request('', data, 'PUT')
   }

   /**
    * 更新指定的数据
    * @param data 要删除数据对象
    */
   public updateItem (data: T): Promise<DataApiResult<T>> {
     return this.request('', data, 'POST')
   }

   /**
    * 删除id列表指定的数据
    * @param ids 记录主键列表
    */
   public deleteItemByIds (ids: number[]): Promise<ApiResult> {
     return this.request('', ids, 'DELETE')
   }

   /**
    * 动态条件查询
    * @param query 查询数据bean
    */
   public query (query: PagerQueryBean): Promise<PageDataApiResult<T>> {
     return this.queryByQueryBean('query', query)
   }
}
