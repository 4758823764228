
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component, Ref } from 'vue-property-decorator'
import ControllerBaseModal from './controller-base-modal.vue'
import { Form } from 'view-design'
import Entity from '@/libs/entitymodel/entity'
import {
  FormComponentConfig,
  FormComponentFieldGroupConfig
} from '@/libs/form-generate'
import EntityField from '@/libs/entitymodel/entity-field'

@Component({
  name: 'VueComponentConfigModal',
  components: { ControllerBaseModal }
})
export default class extends Vue implements BaseModal {
  /**
   * 是否新增模式
   */
  @Prop({
    type: Boolean,
    required: true,
    default: true
  })
  readonly newMode!: boolean

  @Prop({
    type: String,
    default: '新建窗口'
  })
  readonly title!: string

  @Prop({
    type: Object,
    required: true,
    default: () => ({})
  })
  readonly dataProps!: Entity

  /** 搜索用字段列表索引 */
  searchFieldsIndex: number[] = []
  /** 显示字段列表索引 */
  showFieldIndex: number[] = []
  /** 表单字段列表索引 */
  formFieldIndex: number[] = []
  /** 分组信息 */
  fg: Record<string, { title?: string; fields?: string[] }[]>={ fieldGroup: [{}] }
  /** 是否使用分组 */
  group = false

  @Ref()
  readonly dlg!: ControllerBaseModal

  @Ref()
  readonly form!: Form

  get selectableField () {
    const selectedIndex: any[] = this.fg.fieldGroup
      .map(e => e.fields)
      .filter(e => !!e)
      .flat()
    return this.dataProps.fields.filter(e => !selectedIndex.includes(e.name))
  }

  show (): void {
    this.dlg.show()
  }

  close (): void {
    this.dlg.close()
  }

  doSave (): void {
    this.form.validate(result => {
      if (result) {
        this.$emit('onOk', this.createFormComponentConfig())
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  /**
   * 生成配置
   */
  createFormComponentConfig (): FormComponentConfig {
    const res: FormComponentConfig = {
      showFields: [],
      searchFields: [],
      formFields: [],
      fieldGroup: [],
      group: this.group
    }
    this.showFieldIndex.forEach(i =>
      res.showFields.push(this.dataProps.fields[i])
    )
    this.searchFieldsIndex.forEach(i =>
      res.searchFields.push(this.dataProps.fields[i])
    )
    if (!this.group) {
      this.showFieldIndex.forEach(i =>
        res.formFields.push(this.dataProps.fields[i])
      )
    } else {
      res.fieldGroup = this.fg.fieldGroup.map(item => {
        const fields: EntityField[] = []
        const fieldNameIndex = this.dataProps.fields.map(e => e.name)
        item.fields?.forEach(e => {
          const index = fieldNameIndex.indexOf(e)
          if (~index) {
            fields.push(this.dataProps.fields[index])
          }
        })
        return { title: item.title, fields }
      }) as FormComponentFieldGroupConfig[]
    }
    return res
  }

  onAddGroup () {
    this.fg.fieldGroup.push({})
  }

  onDeleteGroup (index: number) {
    this.fg.fieldGroup.splice(index, 1)
  }

  created () {
    this.dataProps.fields.forEach((_item, index) =>
      this.showFieldIndex.push(index)
    )
    this.dataProps.fields.forEach((_item, index) =>
      this.formFieldIndex.push(index)
    )
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
