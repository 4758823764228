
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import TagbarPanel from '@/frame/share/tagbar-panel.vue'
import GroupInfo from '@/frame/share/group-info.vue'
import MainMenu from '@/frame/main/main-menu.vue'
import SubMenu from '@/frame/main/sub-menu.vue'
import BreadcrumbPanel from '@/frame/share/breadcrumb.vue'
import { ModuleItem } from '@/libs/sys-modules'
import { getterTagCollectsGetter, getterUserInfo } from '@/store'
import { TagInfo } from '@/store/module/app'

export interface CacheItem {
  componentInstance?: Vue;
  name: string;
  tag: string;
}

export type KeepAliveCache = Record<string, CacheItem>

/**
 * 系统的主页面布局
 */
@Component({
  name: 'mainLayout',
  components: { TagbarPanel, GroupInfo, MainMenu, SubMenu, BreadcrumbPanel }
})
export default class MainLayout extends Vue {
  curActionModule: ModuleItem | null = null

  get userInfo () {
    return getterUserInfo()
  }

  get actionModuleMenus () {
    return this.curActionModule?.children?.filter(item => {
      if (item?.authorized && item.authorized.length > 0) {
        return item.authorized.every(auth => !!(this.userInfo as any)?.[auth])
      } else {
        return true
      }
    }) || []
  }

  get actionModuleCode () {
    return this.curActionModule?.code || ''
  }

  /**
   * 主菜单选择变化
   */
  onMainMenuChange (value: ModuleItem) {
    this.curActionModule = value
    ;(this.$refs.SubMenu as any).updateMenu()
  }

  @Ref()
  readonly routerView!: Vue

  /** 页面keepAlive缓存 */
  get pageCache (): KeepAliveCache | undefined {
    return (this.routerView?.$vnode?.parent?.componentInstance as any)
      ?.cache as KeepAliveCache
  }

  /**
   * 删除一个页面实例
   */
  @Watch('tagListCollection', { deep: true })
  removePageInstance (tags: TagInfo[]) {
    const _tags: string[] =
      tags.map(e => e?.route?.fullPath).filter(e => !!e) || []

    for (const fullPath in this.pageCache) {
      if (!_tags.includes(fullPath)) {
        this.pageCache[fullPath].componentInstance?.$destroy()
        delete this.pageCache[fullPath]
      }
    }
  }

  /**
   * 删除所有缓存中的页面实例
   */
  removeAllPageInstance () {
    Object.values(this.pageCache || {}).forEach(e => {
      e.componentInstance?.$destroy()
    })
  }

  /**
   * 当前页签
   */
  get tagListCollection () {
    return Object.values(getterTagCollectsGetter() || {}).flat() || []
  }
}
