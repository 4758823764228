
import { BaseModal } from '@/libs/modal-helper'
import { Form } from 'view-design'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  name: 'RightEditModal'
})
export default class RightEditModal extends Vue implements BaseModal {
  showModal = false
  formData: {rights: string[]} = { rights: [] }

  @Ref()
  readonly form!: Form

  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly rights!: string

  readonly rules = { validator: this.checkRightString, trigger: 'blur' }

  checkRightString (rule: any, value: string, callback: any) {
    if (value === undefined || value === '') {
      callback(new Error('请输入权限项'))
    } else if (/^([0-9a-zA-Z-]+\.)*([0-9a-zA-Z-]+:){1}([0-9a-zA-Z-]+,{1})*[0-9a-zA-Z-]+$/.test(value)) {
      callback()
    } else {
      callback(new Error('请按格式输入权限项 xbg.user-info.user-info:view,remove'))
    }
  }

  show (): void {
    this.showModal = true
  }

  close (): void {
    this.showModal = false
  }

  doSave (): void {
    this.form.validate(result => {
      if (result) {
        this.$emit('onOk', this.formData.rights.map(e => e.trim()).filter(item => item.length > 0).join('/'))
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  created () {
    this.formData.rights = this.rights?.split('/') || []
    if (this.formData.rights.length === 0) {
      this.formData.rights = ['']
    }
  }

  onRightRemove (index: number) {
    this.formData.rights.splice(index, 1)
  }
}
