import MainFrame from '@/frame/main/main-layout.vue'
import { RouteConfig } from 'vue-router'

const routers: Array<RouteConfig> = [
  {
    name: 'project',
    component: MainFrame,
    path: '/project',
    meta: {
      showOnBreadCrumb: true
    },
    children: [
      {
        name: 'my-project',
        path: 'my-project',
        component: () => import('@/views/project/my-project/data-list/index.vue'),
        meta: {
          title: '我的项目组',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'my-project-start',
        path: 'my-project-start',
        component: () => import('@/views/project/my-project/data-list/index.vue'),
        meta: {
          title: '我的关注项目',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'my-project-close',
        path: 'my-project-close',
        component: () => import('@/views/project/my-project/data-list/index.vue'),
        meta: {
          title: '我的关闭项目',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'sub-project',
        path: 'sub-project',
        component: () => import('@/views/project/my-project/sub-project/data-list/index.vue'),
        meta: {
          title: '我的项目',
          showOnBreadCrumb: true,
          showOnMenu: false,
          hiddenOnTagsTab: true
        }
      },
      {
        name: 'base-info-page',
        path: 'base-info-page',
        component: () => import('@/views/project/base-info/data-page/base-info-page.vue'),
        meta: {
          title: '项目基础信息'
        }
      }
    ]
  }
]

export default routers
