
/// 实体字段类型
export type FieldType=
  'Enum'|
  'Integer'|
  'int'|
  'Long'|
  'long'|
  'Short'|
  'short'|
  'Byte'|
  'byte'|
  'LocalDate'|
  'LocalDateTime'|
  'Double'|
  'double'|
  'Float'|
  'float'|
  'String'|
  'Boolean'|
  'boolean'|
  'Char'|
  'char'|
  'BigDecimal'

/**
   * 数据类型描述
   */
export interface DataTypeDescript{
  /// 类型名称
  title: string;
  /// 全名
  fullName: string;
}

/// 字段类型的描述
export interface FieldTypeDescript extends DataTypeDescript{
    /// 是否可以设置长度
    canSetLength: boolean;
    /// 可以设置精度
    canSetPrecision: boolean;
    ///  可以设置小数点后的位数
    canSetScale: boolean;
    /// 是否可以设置字段请允许为空 是时可设置为空，否则不能设
    canSetNullable?: boolean;
  }

const fieldTypeDescripts: Record<FieldType, FieldTypeDescript> = {
  Enum: {
    title: 'Enum',
    fullName: 'Enum',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  Integer: {
    title: 'Integer',
    fullName: 'Integer',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  int: {
    title: 'int',
    fullName: 'Integer',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false
  },
  Long: {
    title: 'Long',
    fullName: 'Long',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  long: {
    title: 'long',
    fullName: 'long',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false
  },
  Short: {
    title: 'Short',
    fullName: 'Short',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  short: {
    title: 'short',
    fullName: 'short',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false
  },
  Byte: {
    title: 'Byte',
    fullName: 'Byte',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  byte: {
    title: 'byte',
    fullName: 'byte',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false
  },
  LocalDate: {
    title: 'LocalDate',
    fullName: 'java.time.LocalDate',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  LocalDateTime: {
    title: 'LocalDateTime',
    fullName: 'java.time.LocalDateTime',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  Double: {
    title: 'Double',
    fullName: 'Double',
    canSetLength: false,
    canSetPrecision: true,
    canSetScale: true,
    canSetNullable: true
  },
  double: {
    title: 'double',
    fullName: 'double',
    canSetLength: false,
    canSetPrecision: true,
    canSetScale: true
  },
  Float: {
    title: 'Float',
    fullName: 'Float',
    canSetLength: false,
    canSetPrecision: true,
    canSetScale: true,
    canSetNullable: true
  },
  float: {
    title: 'float',
    fullName: 'float',
    canSetLength: false,
    canSetPrecision: true,
    canSetScale: true
  },
  String: {
    title: 'String',
    fullName: 'String',
    canSetLength: true,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  Boolean: {
    title: 'int',
    fullName: 'int',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  boolean: {
    title: 'boolean',
    fullName: 'boolean',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false
  },
  Char: {
    title: 'Char',
    fullName: 'Char',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false,
    canSetNullable: true
  },
  char: {
    title: 'char',
    fullName: 'char',
    canSetLength: false,
    canSetPrecision: false,
    canSetScale: false
  },
  BigDecimal: {
    title: 'BigDecimal',
    fullName: 'java.math.BigDecimal',
    canSetLength: false,
    canSetPrecision: true,
    canSetScale: true,
    canSetNullable: true
  }
}

export default fieldTypeDescripts

/**
 * 取得项目支持的数据类型描述
 */
export function getProjectDataTypes (): Promise<DataTypeDescript[]> {
  return new Promise<DataTypeDescript[]>((resolve, reject) => {
    const res: DataTypeDescript[] = []
    for (const key in fieldTypeDescripts) {
      const define = fieldTypeDescripts[key as FieldType]
      res.push({
        title: define.title,
        fullName: define.fullName
      })
    }
    resolve(res)
  })
}
