import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import storage from '@/libs/local-storage'

export interface CurControllInfo{
  name: string;
  title: string;
}

interface ApiManagerStore {
    curControllerId?: number;
    curControllerInfo?: CurControllInfo;
}

class ApiManagerStoreModule implements Module<ApiManagerStore, any> {
    namespaced = true // 限制作用域

    state: ApiManagerStore = {
      curControllerId: storage.loadCurControlId(),
      curControllerInfo: storage.loadCurControlInfo()
    }

    mutations: MutationTree<ApiManagerStore> = {
      setCurControllerId (state: ApiManagerStore, curControllerId: number) {
        state.curControllerId = curControllerId
        storage.saveCurControlId(curControllerId)
      },
      setCurControllerInfo (state: ApiManagerStore, controlInfo: CurControllInfo) {
        state.curControllerInfo = controlInfo
        storage.saveCurControlInfo(controlInfo)
      }
    }

    actions: ActionTree<ApiManagerStore, any> = {
      /**
       * 更新当前contorl的id
       * @param param0
       * @param controlId
       */
      updateCurControllerIdAndInfo ({ commit }, { controlId, info }) {
        commit('setCurControllerId', controlId)
        commit('setCurControllerInfo', info)
      },

      /**
       * 更新当前的control信息
       * @param param0
       * @param controlInfo
       */
      updateCurControllerInfo ({ commit }, controlInfo: CurControllInfo) {
        commit('setCurControllerInfo', controlInfo)
      }
    }

    getters: GetterTree<ApiManagerStore, any> = {
      /**
       * 取得controller id 的getter方法
       * @param state
       * @param getters
       * @returns
       */
      curControllerIdGetter (state: ApiManagerStore, getters: unknown) {
        return state.curControllerId
      },
      /**
       * 取得当前controlinfo信息
       * @param state
       * @param getters
       * @returns
       */
      curControllerInfoGetter (state: ApiManagerStore, getters: unknown) {
        return state.curControllerInfo
      }
    }
}

// 导出对象实例
export default new ApiManagerStoreModule()
