import '@/libs/directive'
import '@/style/iview-variables.less'
import '@/style/module/vxe-table.scss'
import 'xe-utils'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import iView from 'view-design'
import Vue from 'vue'
import SlideVerify from 'vue-monoplasty-slide-verify'
import VXETable from 'vxe-table'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import App from './App.vue'
import i18n from './locales/i18n'
import router from './router'
import Contextmenu from 'vue-contextmenujs'
import store, { dispatchRefreshToken } from './store'
import Clipboard from 'v-clipboard'
import KdIpInput from 'kd-ip-input'
import './plugins/event-bus.js'

Vue.config.productionTip = false

Vue.use(iView)
Vue.use(VXETable)
Vue.use(mavonEditor)
Vue.use(SlideVerify)
Vue.use(Contextmenu)
Vue.use(Clipboard)
Vue.use(KdIpInput)

VXETable.use(VXETablePluginExportXLSX)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// 处理token的定时刷新
const hander = window.setInterval(() => {
  dispatchRefreshToken().then(() => {
    console.log('token update ok')
  }).catch((err) => {
    console.error('token update 失败' + err)
  })
}, 20 * 1000) // 3 分钟刷新一下token
// 关闭页面前删除定时器
window.onbeforeunload = () => {
  window.clearInterval(hander)
}
