
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { dispathUpdateCurMenuItemCode, getterCurrentMenuModuleCode } from '@/store'
import { ModuleItem, MenuCodeSpitChar } from '@/libs/sys-modules'
import SubMenuItem from './sub-menu-item.vue'

@Component({
  name: 'subMenu',
  components: { SubMenuItem }
})
export default class SubMenu extends Vue {
  /**
   * 当前的项目列表
   */
  @Prop({
    type: Array,
    required: true,
    default: () => []
  })
  menus!: Array<ModuleItem>;

  /// 已经打开的菜单项
  openList: Array<string>=[]

  /**
   * 当前选择中菜单的根编号
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  rootMenuCode!: string

  /**
 * 是否有父级菜单
 */
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  hasRoot!: boolean

  @Watch('rootMenuCode')
  watchRootMenuCode (newCode: string) {
    this.computerOpenList(newCode)
  }

  /**
   * 计算打开菜单列表
   */
  computerOpenList (curRootMenuCode: string|undefined) {
    if (!curRootMenuCode || !this.curMenuCode) {
      this.openList = []
      return
    }

    let code = this.curMenuCode.substring(curRootMenuCode.length)
    if (code.startsWith(MenuCodeSpitChar)) {
      code = code.substring(1)
    }

    const codes = code.split(MenuCodeSpitChar)

    if (this.hasRoot) codes.pop()
    while (codes.length > 0) {
      let subMenuCode = `${curRootMenuCode}${MenuCodeSpitChar}` + codes.join('-')
      if (subMenuCode.startsWith(MenuCodeSpitChar)) {
        subMenuCode = subMenuCode.substring(1)
      }
      this.openList.push(subMenuCode)
      codes.pop()
    }
  }

  /**
   * 检查菜单项是否有子菜单
   */
  hasChidrenMenu (item: ModuleItem | undefined) {
    if (item && item.children && item.children.length > 0) return true
    return false
  }

  /**
   * 当前菜单项
   */
  get curMenuCode (): string {
    return getterCurrentMenuModuleCode()
  }

  /**
   * 展示组件事件
   */
  mounted () {
    this.computerOpenList(this.rootMenuCode)
    this.updateMenu()
  }

  /**
   * 更新菜单状态
   */
  updateMenu () {
    const that = this
    this.$nextTick(() => {
      (that.$refs.menu as any).updateOpened();
      (that.$refs.menu as any).updateActiveName()
    })
  }

  /**
   * 当菜单项选择时发生事件
   */
  onMenuSelect (name: string) {
    // 记录当前选择的菜单的fullcode ，也就是当前菜单的name
    dispathUpdateCurMenuItemCode(name)
  }
}
