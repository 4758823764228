import store from '@/store'

const httpcodeOk = 200 // http请求成功
const apirequestOk = 0 // api请求成功
const httpErrorCodes: Map<number, string> = new Map()
  .set(401, '没有权限')
  .set(402, '没有权限')
  .set(500, '服务器未知错误')
  .set(404, '找不到请求api接口')

/**
 * 检查请求是否错误并返回错误说明，如果不是错误则返回null
 */
export function checkRequestErrorMessage (code: number): string|null{
  if (code === apirequestOk) return null

  const codes: Map<number, string> = store.getters['errorCodes/errorCodeGetter']
  const message = httpErrorCodes.get(code)
  if (message) return message

  return '服务Api接口返回未知错误'
}

/**
 * 检查http请求是否错误并返回错误说明，如果成功返回空
 */
export function chechHttpRequetStatusMessage (code: number): string|null{
  if (code === httpcodeOk) return null
  const message = httpErrorCodes.get(code)
  if (message) return message
  return '服务器请求未知错误'
}
