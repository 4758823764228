import { Route } from 'vue-router'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import _ from 'lodash'
import storage from '@/libs/local-storage'
import { getMoudleItemByPath, ModuleItem } from '@/libs/sys-modules'
import { getterUserModules } from '..'
import { stateValuesEqual } from 'xstate/lib/State'

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export function routeEqual (route1: Route, route2: Route) {
  const params1 = route1.params || {}
  const params2 = route2.params || {}
  const query1 = route1.query || {}
  const query2 = route2.query || {}

  /// 排除了from进行比较
  const p1 = _.cloneDeep(params1)
  const p2 = _.cloneDeep(params2)
  p1.from = ''
  p2.from = ''
  p1.mode = ''
  p2.mode = ''

  return (route1.name === route2.name) && _.isEqual(p1, p2) && _.isEqual(query1, query2)
}

/**
 * 取得路由的标题
 * @param route 路由对象
 * @param vm 当前的vue对象
 */
export function getRouteTitle (route: Route, vm: Vue): string {
  const moduleItem = getMoudleItemByPath(getterUserModules(), route.fullPath)

  let title = '未命名页'
  if (!moduleItem || !moduleItem.title) {
    if (route?.meta?.title) title = route.meta.title
  } else {
    title = moduleItem.title
  }

  // if (title.includes('{{') && title.includes('}}')) { title = title.replace(/({{[\s\S]+?}})/, (m: any, str: any) => str.replace(/{{([\s\S]*)}}/, (m: any, _: any) => vm.$t(_.trim()))) } else if (__titleIsFunction__) title = route.meta.title

  return title
}

/**
 * 页签信息
 */
export interface TagInfo{
  /**
   * 页签标题
   */
  title: string;
  /// 当前模式
  mode?: string;
  /**
   * 路由列表
   */
  route: Route;
  // 当前是否选择
  checked: boolean;
  /**
   * 标签最后修改时间
   */
  lastModiTime: number;
  // 标签的唯一id
  id: string;
}
/**
 * 标签集合
 */
export interface TagInfoCollection{
  [tagName: string]: Array<TagInfo>;
}

/**
 * 应用程序状态
 */
interface AppStore{
    // 页面面包屑的列表
    breadCrumbList: Array<ModuleItem>;
    /// 页面面包屑最后对应的路径
    breadCrumbLastPath?: string;
    // 当前的tagList列表
    tagCollects: TagInfoCollection;
    // 当前tag列表的名称
    curTagName: string;
}

/**
 * 应用程序状态模块
 */
class AppStoreModule implements Module<AppStore, unknown> {
    // 限制作用域
    namespaced=true

    // 状态
    state: AppStore={
      breadCrumbList: storage.loadBreadCrumbList(),
      breadCrumbLastPath: storage.loadLastPath(),
      tagCollects: storage.loadTagList(),
      curTagName: 'default'
    }

    // 修改器
    mutations: MutationTree<AppStore>={
      /**
       * 设置当前的面包屑
       * @param state
       * @param breadCrumbList
       */
      setBreadCrumbList (state, breadCrumbList: Array<ModuleItem>) {
        state.breadCrumbList = breadCrumbList
        storage.saveBreadCrumbList(breadCrumbList)
      },
      /**
       * 设置当前的面包屑对应的路径
       * @param state
       * @param path
       */
      setBreadCrumbLastPath (state, path: string|undefined) {
        state.breadCrumbLastPath = path
        storage.saveLastPath(path)
      },

      /**
       * 设置当前的页签列表
       * @param state
       * @param tagList
       */
      setTagCollects (state, { tagName, tagList }) {
        state.tagCollects[tagName] = [...tagList]
        storage.saveTagList(state.tagCollects)
      },

      /**
       * 设置当前tagName
       * @param state
       * @param tagName
       */
      setCurTagName (state, tagName: string) {
        state.curTagName = tagName
      }

    }

    // 操作列表
    actions: ActionTree<AppStore, unknown>={
      /**
        * 更新当前的面包屑的列表
        * @param param0
        * @param breadCrumbList
      */
      updateBreadCrumbList ({ commit }, { breadCrumbList, path }) {
        commit('setBreadCrumbList', breadCrumbList)
        commit('setBreadCrumbLastPath', path)
      },

      /**
       * 添加一个页签
       * @param param0
       * @param param1
       */
      addTag ({ state, commit }, { route, vm, tagName }) {
        const { name, params, query, meta } = route

        // 如果不显示为tab标签则跳过
        if (meta && meta.hiddenOnTagsTab) {
          return
        }

        const tags = [...(state.tagCollects[tagName] ? state.tagCollects[tagName] : [])]
        /// 现有标签全部标为未激活
        tags.forEach(tag => { tag.checked = false })

        // 检查是否已经存在同一路由
        for (const tag of tags) {
          if (routeEqual(tag.route, route)) {
            tag.checked = true
            tag.lastModiTime = new Date().valueOf()
            return
          }
        }

        /// 添加新的页签
        tags.push({
          title: params.tagName || getRouteTitle(route, vm),
          mode: route.params ? route.params.mode : undefined,
          route: {
            name: name,
            query: query,
            params: params,
            path: route.path,
            fullPath: route.fullPath
          } as Route,
          checked: true,
          lastModiTime: new Date().valueOf(),
          id: ((new Date()).valueOf()) + ''
        })

        storage.saveTagList(tags)
        commit('setTagCollects', {
          tagName: tagName,
          tagList: tags
        })
      },
      /// 更新当前的tagName
      updateCurTagName ({ commit }, tagName: string) {
        commit('setCurTagName', tagName)
      },

      /**
       * 更新标题模式
       * @param param0
       * @param mode
       */
      updateCheckTageTitle ({ state, commit }, { tagName, mode }) {
        state.tagCollects[tagName].filter((item) => item.checked)
          .forEach((item) => { item.mode = mode })

        commit('setTagCollects', {
          tagName: tagName,
          tagList: state.tagCollects[tagName]
        })
      },

      /**
       * 删除一个页签
       * @param param0
       * @param route
       */
      removeTag ({ state, commit }, { tagName, route }) {
        const tags = [...state.tagCollects[tagName]]
        const curTags = tags.filter(tag => !routeEqual(tag.route, route))
        commit('setTagCollects', {
          tagName: tagName,
          tagList: curTags
        })
      },

      /**
       * 清除标签 ,只保留第一个
       */
      clearTag ({ state, commit }, { tagName, route }) {
        const tags = [...state.tagCollects[tagName]]
        const curTags = tags.filter(tag => routeEqual(tag.route, route))
        commit('setTagCollects', {
          tagName: tagName,
          tagList: curTags
        })
      }
    }

    // 访问器
    getters: GetterTree<AppStore, unknown>={

      /**
     * 返加当前的面包屑的列表
     * @param state
     * @param getters
    */
      breadCrumbListGetter (state: AppStore, getters: unknown) {
        return state.breadCrumbList
      },
      /**
       * 返回当前面包屑的对应的路径
       * @param state
       * @param getters
       */
      breadCrumbLastPathGetter (state: AppStore, getters: unknown) {
        return state.breadCrumbLastPath
      },

      /**
       * 取得当前的页签列表
       * @param state
       * @param getters
       */
      tagCollectsGetter (state: AppStore, getters: unknown) {
        return state.tagCollects
      },
      /**
       * 取得当前 tagName
       * @param state
       * @param getters
       * @returns
       */
      curTagNameGetter (state: AppStore, getters: unknown) {
        return state.curTagName
      }
    }
}

export default new AppStoreModule()
