
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import ControllerConfigApi from '@/api/project/apimanager/controller-config/controller-config-api'

// 团队选择组件
@Component({ name: 'ControllerConfigSelector', components: {}, mixins: [] })
export default class ControllerConfigSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue = 0

  @Prop({
    type: String,
    default: '输入标题或者名称'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  curVersionId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData (curVersionId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.curVersionId = curVersionId
    if (this.curVersionId) {
      this.loadDataFromServer()
    }
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const that = this as any
    const queryData = {
      page: 1,
      pageSize: 100,
      state: 1,
      curVersionId: this.curVersionId,
      sorts: []
    }
    ControllerConfigApi
      .query(queryData)
      .then((respose) => {
        const items = respose.data!.items
        that.loading = false
        this.datas = items.map(item => {
          return {
            value: item.id,
            label: item.title + `(${item.className})`
          }
        })
      }).catch(err => {
        this.$Notice.error({ title: '错误', desc: '加载团队数据失败:' + err.message })
      })
  }
}
