import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import { EnumerationConfig } from '@/api/project/enummanager/enum-config/enum-config'
import _ from 'lodash'
import { FormComponentConfig, FormComponentFieldGroupConfig } from '.'
import { HasPackageInfoEntity } from '../entitymodel/entity'
import enumApi from '@/api/project/enummanager/enum-config/enum-config-api'
import EntityField from '../entitymodel/entity-field'
import { FieldType } from '../entitymodel/field-type-descript'

/**
 * 表单校验类型转换
 * @param type
 * @returns
 */
function toTsType (type: FieldType) {
  switch (type) {
    case 'BigDecimal':
    case 'Byte':
    case 'byte':
    case 'Char':
    case 'char':
    case 'Double':
    case 'double':
    case 'Float':
    case 'float':
    case 'Long':
    case 'long':
    case 'Short':
    case 'short':
    case 'int':
    case 'Enum':
    case 'Integer':
      return 'number'
    case 'Boolean':
    case 'boolean':
      return 'boolean'
    case 'String':
    case 'LocalDate':
    case 'LocalDateTime':
      return 'string'
  }
}
/**
 * 通过枚举uuid获取枚举信息
 * @param {string} uuid
 * @param {EnumerationConfig[]} enumList 枚举列表
 */
function getEnumDefineByUuid (uuid: string, enumList: EnumerationConfig[]): EnumerationConfig | undefined {
  for (let i = 0; i < enumList.length; i++) {
    if (enumList[i].uuid === uuid) { return enumList[i] }
  }
}

/**
 * 首字母大写
 * @param content
 * @returns
 */
function upperFirst (content: string): string {
  return content.slice(0, 1).toUpperCase() + content.slice(1)
}

/**
 * 首字母小写
 * @param content
 * @returns
 */
function lowerFirst (content: string): string {
  return content.slice(0, 1).toLowerCase() + content.slice(1)
}

/**
 * 驼峰转横杠
 * @param content
 * @returns
 */
function lowerMiddleline (content: string): string {
  const res = content.replace(/([A-Z])/g, '-$1').toLowerCase().replace('_', '-').trim()
  return res.startsWith('-') ? res.slice(1, res.length) : res
}

/**
 * 驼峰转下划线
 * @param content
 * @returns
 */
function lowerUnderline (content: string): string {
  const res = content.replace(/([A-Z])/g, '_$1').toLowerCase().replace('-', '_').trim()
  return res.startsWith('_') ? res.slice(1, res.length) : res
}

/**
 * 引入api
 * @param packageName
 * @param controllerName
 * @returns
 */
function importApi (controller: ControllerConfig): string {
  return `import ${lowerFirst(controller.className!)}Api from '@/api/${lowerMiddleline(controller.packageName!)}/${lowerMiddleline(controller.className!)}-api'`
}

/**
 * 引入实体
 * @param entityName
 * @returns
 */
function importEntity (entity: HasPackageInfoEntity): string {
  return `import ${upperFirst(entity.name)} from '@/xbgcoding/entity${lowerMiddleline(entity.fullName.replace(entity.basePackageName, '')).replace('.', '/')}/${lowerMiddleline(entity.name)}'`
}

/**
 * 读取包名
 * @param entity
 * @returns
 */
function getEntityName (entity: HasPackageInfoEntity): string {
  return upperFirst(entity.name)
}

/**
 * 读取api名称
 * @param controller
 * @returns
 */
function getControllerName (controller: ControllerConfig): string {
  return lowerFirst(controller.className!)
}

/**
 * 从字段配置中获取表单规则
 * @param field
 * @returns
 */
function getRuleByField (field: EntityField): string {
  let res = ''
  if (!field.nullable) {
    res += `\t{ required: true, type: '${toTsType(field.type)}', message: '${field.title}不允许为空', trigger: 'blur' },\n`
  }
  field.fieldContraintes?.map(c => {
    switch (c.type) {
      case 'NotBlank':
      case 'NotNull':
        res += `\t{ required: true, type: '${toTsType(field.type)}', message: '${field.title}不允许为空', trigger: 'blur' },\n`
        break
      case 'Email':
        res += `\t{ type: 'email', message: '${c.messages.cn}}', trigger: 'blur' },\n`
        break
      case 'DecimalMax':
      case 'Max':
        res += `\t{ type: 'number', message: '${c.messages.cn}${c.values[0]}}', trigger: 'blur', max: ${c.values[0]} },\n`
        break
      case 'DecimalMin':
      case 'Min':
        res += `\t{ type: 'number', message: '${c.messages.cn}${c.values[0]}}', trigger: 'blur', min: ${c.values[0]} },\n`
        break
      case 'Range':
        if (field.type === 'LocalDate' || field.type === 'LocalDateTime') {
          res += `\t{ validator: (_rule: any, value: any) => moment(value).isBetween('${c.values[0]}','${c.values[1]}'), message:'${c.messages.cn}},\n`
        } else {
          res += `\t{ type: 'number', message: '${c.messages.cn}}', trigger: 'blur', min: ${c.values[0]}, max: ${c.values[1]} },\n`
        }
        break
      case 'Phone':
        res += `\t{ type: 'string', message: '${c.messages.cn}}', trigger: 'blur', pattern: /^1(3|4|5|6|7|8|9)d{9}$/ },\n`
        break
      case 'Past':
        res += `\t{ validator: (_rule: any, value: any) => moment().isAfter(value), message:'${c.messages.cn}}', trigger: 'blur' },\n`
        break
      case 'Future':
        res += `\t{ validator: (_rule: any, value: any) => moment().isBefore(value), message:'${c.messages.cn}}', trigger: 'blur' },\n`
    }
  })

  return `${field.name}: [
  ${res}
  ],\n`
}

/**
 * 表单规则
 * @param options
 * @returns
 */
function rules (options: FormComponentConfig): string {
  let res = ''
  if (options.group) {
    options.fieldGroup.map(g => g.fields).flat().forEach(f => {
      res += `${getRuleByField(f)}`
    })
  } else {
    options.formFields.forEach(f => {
      res += `${getRuleByField(f)}`
    })
  }
  return res
}

/**
 * 渲染枚举列表
 * @param options
 */
function enums (enums: EnumerationConfig[]): string {
  let res = ''
  enums.forEach(e => { res += `readonly ${upperFirst(e.name!)} = ${upperFirst(e.name!)}\n` })
  return res
}

/**
 * 引入枚举
 * @param enums
 * @param entity
 * @returns
 */
function importEnums (enums: EnumerationConfig[], entity: HasPackageInfoEntity): string {
  let res = ''
  enums.forEach(e => {
    res += `import ${upperFirst(e.name!)} from '@/xbgcoding/entity${lowerMiddleline(e.packageName!.replace(entity.basePackageName, '')).replace('.', '/')}/${lowerMiddleline(e.name!)}'`
  })
  return res
}

/**
 * 将字段信息渲染为对应类型的组件
 */
function fieldToComponent (field: EntityField, enumList: EnumerationConfig[]): string {
  let res = ''

  switch (field.type) {
    case 'BigDecimal':
    case 'Byte':
    case 'byte':
    case 'Char':
    case 'char':
    case 'Double':
    case 'double':
    case 'Float':
    case 'float':
    case 'Long':
    case 'long':
    case 'Short':
    case 'short':
    case 'int':
    case 'Integer':
      res = `<Input
                v-model="formData.${field.name}"
                style="width: 100%"
                placeholder="请输入${field.title}"
              />`
      break
    case 'Enum':
      res = `<EnumSelect
                v-model="formData.${field.name}"
                :enumDefine="${upperFirst(getEnumDefineByUuid(field.enumUuid!, enumList)!.name!)}"
                style="width: 100%"
                placeholder="请输入${field.title}"
              />`
      break
    case 'Boolean':
    case 'boolean':
      // TODO 开关
      res = `<Switch
                v-model="formData.${field.name}"
              />`
      break
    case 'String':
      res = `<Input
                v-model="formData.${field.name}"
                style="width: 100%"
                placeholder="请输入${field.title}"
              />`
      break
    case 'LocalDate':
      res = `<DatePicker
              v-model="formData.${field.name}"
              type="date"
              placeholder="请选择${field.title}"
            />`
      break
    case 'LocalDateTime':
      // TODO 日期时间选择器
      res = `<DatePicker
              v-model="formData.${field.name}"
              type="datetime"
              placeholder="请选择${field.title}"
            />`
      break
  }

  return `<StateFormItem prop="${field.name}" label="${field.title}">
  \t${res}
  </StateFormItem>`
}

/**
 * 按栅格渲染字段列表
 * @param field
 * @returns
 */
function renderInGrid (fields: EntityField[], enumList: EnumerationConfig[]): string {
  let res = ''
  for (let i = 0; i < fields.length; i += 2) {
    res += `<Row>
    \t<Col :span="10">
    \t\t${fieldToComponent(fields[i], enumList)}
    \t</Col>
    `
    if (fields[i + 1]) {
      res += `
    \t<Col :span="1"><pre/></Col>
    \t<Col :span="10">
    \t\t${fieldToComponent(fields[i + 1], enumList)}
    \t</Col>`
    }
    res += '</Row>\n'
  }
  return res
}

/**
 * 按分组渲染栅格
 * @param group
 * @returns
 */
function renderGroup (group: FormComponentFieldGroupConfig[], enumList: EnumerationConfig[]): string {
  let res = ''
  group.forEach(g => {
    res += `<CollapsePanel title="${g.title}">
      ${renderInGrid(g.fields, enumList)}
    </CollapsePanel>\n`
  })
  return res
}

/**
 * 根据字段配置渲染页面
 * @param options
 * @returns
 */
function renderFields (options: FormComponentConfig, enumList: EnumerationConfig[]): string {
  if (options.group) {
    return renderGroup(options.fieldGroup, enumList)
  } else {
    return renderInGrid(options.formFields, enumList)
  }
}

/**
 * 渲染数据页面
 * @returns
 */
function render (entity: HasPackageInfoEntity, controller: ControllerConfig, options: FormComponentConfig, enumList: EnumerationConfig[]) {
  return `<template>
  <Form
    ref="form"
    :rules="rules"
    :model="formData"
    label-position="left"
    :label-width="100"
  >
    <DataFormPageView
      :model="formData"
      :formContext="formContext"
      :formStateSerive="formStateSerive"
      :loading="loading"
      :validate="doFormValidate"
    >
      ${renderFields(options, enumList)}
    </DataFormPageView>
  </Form>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-property-decorator'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import StateDataFormPage from '@/frame/share/state-form-page'
import CollapsePanel from '@/components/collapse-panle.vue'
import StateFormItem from '@/components/state-formitem.vue'
import StateButton from '@/components/state-formbutton.vue'
import EnumSelect from '@/components/enum-select.vue'
import { Form } from 'view-design'
import moment from 'moment'
${importEntity(entity)}
${importApi(controller)}
${importEnums(enumList, entity)}
import { DataApiResult } from '@/libs/http-request'

/**
 * ${entity.title}数据页面
 */
@Component({
  name: '${getEntityName(entity)}DataPage',
  components: {
    DataFormPageView,
    CollapsePanel,
    StateFormItem,
    StateButton,
    EnumSelect
  }
})
export default class extends StateDataFormPage<${getEntityName(entity)}, any> {
  /**
   * 当前表单数据
   */
  protected formData: ${getEntityName(entity)} = {}

  /**
   * 服务器状态
   */
  serverState=''

  /**
   * 表单验证规则
   */
  rules = {
    ${rules(options)}}

  /**
   * 枚举
   */
  ${enums(enumList)}

  /**
   * 表单对象
   */
  @Ref()
  readonly form!: Form

  created () {
    this.initFromState({})
  }

  /**
   * 加载指定id的表单数据
   */
  protected onLoadFormData (id: number): Promise<${getEntityName(entity)}> {
    return new Promise<${getEntityName(entity)}>((resolve, reject) => {
      ${getControllerName(controller)}Api
        .getItemById(id)
        .then(response => {
          this.serverState = 'view'
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 生成新增的表单数据
   */
  protected onCreateNewFormData (): Promise<${getEntityName(entity)}> {
    return new Promise<${getEntityName(entity)}>((resolve, reject) => {
      resolve({
        id: undefined
      })
    })
  }

  /**
   * 表单认证
   */
  protected doFormValidate (callback: (valid?: boolean | undefined) => void) {
    this.form.validate(valid => callback(valid))
  }

  /**
   * 执行新增数据保存
   */
  protected onInsertFormData (): Promise<${getEntityName(entity)}> {
    return new Promise<${getEntityName(entity)}>((resolve, reject) => {
      ${getControllerName(controller)}Api
        .insertItem(this.formData)
        .then(response => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 保存修改数据
   */
  protected onSaveFormData (): Promise<${getEntityName(entity)}> {
    return new Promise<${getEntityName(entity)}>((resolve, reject) => {
      ${getControllerName(controller)}Api
        .updateItem(this.formData)
        .then((response: DataApiResult<${getEntityName(entity)}>) => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }
}
</script>

<style lang="less" scoped>
.label {
  font-family: sans-serif;
  font-weight: 400 !important;
  color: #3d4954;
  line-height: 48px;
}
</style>
`
}

/**
 * 渲染表单页面
 */
export default async function (entity: HasPackageInfoEntity, controller: ControllerConfig, options: FormComponentConfig): Promise<string> {
  // 获取枚举信息
  let enumList: EnumerationConfig[] = []
  if (!options.group) {
    const enumUuidList: string[] = _.uniq(options.formFields.map(e => e.enumUuid).filter(e => !!e) as any[])
    enumList = (await Promise.all(enumUuidList.map(e => enumApi.getItemByUuid(e)))).map(e => e.data!)
  } else {
    const enumUuidList: string[] = _.uniq(options.fieldGroup.map(g => g.fields).flat().map(f => f.enumUuid).filter(u => !!u) as any[])
    enumList = (await Promise.all(enumUuidList.map(e => enumApi.getItemByUuid(e)))).map(e => e.data!)
  }
  return render(entity, controller, options, enumList)
}
