
import { Vue, Component } from 'vue-property-decorator'
import TagbarPanel from '@/frame/share/tagbar-panel.vue'
import GroupInfo from '@/frame/share/group-info.vue'
import ProjectMenu from '@/frame/project/project-menu.vue'
import BreadcrumbPanel from '@/frame/share/breadcrumb.vue'
import ProjectInfo from './project-info.vue'

/**
 * 系统的主页面布局
 */
@Component({
  name: 'mainLayout',
  components: {
    TagbarPanel,
    GroupInfo,
    ProjectMenu,
    BreadcrumbPanel,
    ProjectInfo
  }
})
export default class MainLayout extends Vue {
  backMain () {
    this.$router.push({ path: '/' })
  }
}
