import { expect } from 'chai'
import _ from 'lodash'
import Entity from './entity'

/// 实体面版的宽度
const entityPanleWidth = 250
/// 实体初始化显示是，离上方的距离
const entityBaseTop = 80
/// 实体初始化显示是，离左方的距离
const entityBaseLeft = 50

/**
 * 实体位置定义
 */
export interface EntityPosition{
  /// 实体的唯一id
  uuid: string;
  /// 对象归属的orgId
  orgUuid: string;
  /// left位置值
  left: number;
  /// top位置值
  top: number;
  /// 是否展开
  expended?: boolean;
}
/**
 * 数据模型视图定义
 */
export interface EntityModelView{
  /// 实体位置列表
  entityPos: Record<string, EntityPosition>;
  /// 对应的组织id
  orgId: string;
  /// 当前的缩放值
  scale: number;
  /// 滚动位置x
  scrollX: number;
  /// 滚动位置y
  scrollY: number;
}

/**
 * 实体模型视图管理器
 */
export default class EntityModelViewManger {
  entityPos: Record<string, EntityPosition>={};
  orgId='';
  scale=1;
  scrollX=0;
  scrollY=0;

  /**
   * 通过定义进行初始化
   * @param define
   */
  constructor (define: EntityModelView) {
    expect(define, '参数define不允许为空').not.undefined
    this.entityPos = define.entityPos || {}
    this.orgId = define.orgId
    this.scale = define.scale
    this.scrollX = define.scrollX
    this.scrollY = define.scrollY
  }

  /**
   * 通过实体对象进行默认初始化
   * @param entityPos
   * @param autoLayout 自动布局
   */
  initByEntities (entities: Entity[]) {
    if (!entities) entities = []
    /// 对实体按名称排序
    const list = entities.sort((item1, item2) => item1.name.localeCompare(item2.name))
    // 自动调整布局，方法是使用字母排序，一行只放4个
    let row = 0
    let count = 0
    this.entityPos = {}
    for (const item of list) {
      /// 控制四个一行
      if (count !== 0 && count % 4 === 0) {
        row++
        count = 1
      } else {
        count++
      }
      this.entityPos[item.uuid] = {
        left: (count - 1) * (entityPanleWidth + 10) + entityBaseLeft,
        top: row * (entityPanleWidth + 10) + entityBaseTop,
        uuid: item.uuid,
        /// 让实体处于关闭状态
        expended: false,
        orgUuid: item.orgId
      }
    }
  }

  /**
   * 创建或者新增实体对象的位置信息
   * @param uuid
   */
  getOrCreateEntityPos (uuid: string, orgUuid: string): EntityPosition {
    let pos: EntityPosition = this.entityPos[uuid]
    if (!pos) {
      pos = {
        left: 200,
        top: 100,
        uuid: uuid,
        expended: false,
        orgUuid: orgUuid
      }
      this.entityPos[uuid] = pos
    } else {
      pos = this.entityPos[uuid]
    }
    return pos
  }

  /**
   * 取得视图的实体位置信息,如果不存在，则返回空
   * @param uuid 实体的uuid
   */
  getEntityPos (uuid: string): EntityPosition|undefined {
    return this.entityPos[uuid]
  }

  /**
   * 删除视图中的实体位置信息
   * @param uuid
   */
  removeEntityPos (uuid: string) {
    expect(uuid, '参数uuid不允许为空').not.undefined.and.not.empty

    _.unset(this.entityPos, uuid)
  }

  /**
   * 转换为ModelView以方便存贮
   */
  toEntityModelView (): EntityModelView {
    const poses: Record<string, EntityPosition> = {}
    for (const key in this.entityPos) {
      const pos = this.entityPos[key]
      poses[key] = {
        left: pos.left,
        top: pos.top,
        uuid: pos.uuid,
        orgUuid: pos.orgUuid,
        expended: pos.expended
      }
    }
    return {
      orgId: this.orgId,
      scale: this.scale,
      scrollX: this.scrollX,
      scrollY: this.scrollY,
      entityPos: poses
    }
  }
}
