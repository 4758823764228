
import { Vue, Component } from 'vue-property-decorator'
import { getterUserInfo } from '@/store'
import teamService from '@/api/team'
import moment from 'moment'
import showTeamInfoModal from './modal/show-team-info-modal'

/**
 * 团队信息，在系统右上角显示当前团队的基本信息
 */
@Component({
  name: 'groupInfo'
})
export default class GroupInfo extends Vue {
  // 团队名称
  teamName = '';
  // 团队编号
  teamCode = '';

  teamIcon: any = '';

  enterpriseUser = false;

  expired = false;

  hover = false;

  created () {
    const userInfo = getterUserInfo()
    if (userInfo.operaExpirationTime) {
      this.enterpriseUser = true
      this.expired = moment(userInfo.operaExpirationTime).isBefore(moment.now())
    } else {
      this.enterpriseUser = false
    }
    teamService.getItemById(userInfo.activedTeamId!).then((response) => {
      this.teamName = response.data!.name!
      this.teamCode = response.data!.code!
      this.teamIcon = response.data!.iconUrl
        ? require(response.data!.iconUrl)
        : undefined
    })
  }

  viewTeamInfo () {
    showTeamInfoModal()
  }

  toOpen () {
    if (this.$route.fullPath !== '/team/company-regist-record') {
      this.$router.push({
        path: '/team/company-regist-record'
      })
    }
  }
}
