import { EntityModel } from '@/libs/entitymodel/entity-model'
import ProjectVersion from '@/libs/entitymodel/project-verion'
import OrgInfo, { OrgInfoData } from '@/libs/entitymodel/entity-orginfo'
import request, { ApiResult, DataApiResult, PageDataApiResult, PagerQueryBean, requestRaw } from '@/libs/http-request'

class WebApiEntityModelApi {
    private baseUrl: string

    constructor (baseUrl: string) {
      this.baseUrl = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl
    }

    private getUrl (url: string) {
      url = url.startsWith('/') ? url : '/' + url

      return this.baseUrl + url
    }

    /**
      * 取得模型组织列表
      *
      * @param projectId
      * @param version
      * @return
      */
    public getModelOrgInfoList (projectId: number, version: string): Promise<DataApiResult<OrgInfo[]>> {
      return request<DataApiResult<OrgInfo[]>>({
        url: this.getUrl(`getModelOrgInfoList/${projectId}/${version}`),
        method: 'GET'
      })
    }

    /**
     * 取得组织信息数据
     * @param projectId
     * @param version
     */
    public getOrNewModelOrgInfoData (projectId: number, version: string): Promise<OrgInfoData> {
      return new Promise<OrgInfoData>((resolve, reject) => {
        request<DataApiResult<OrgInfoData>>({
          url: this.getUrl(`getModelOrgInfoData/${projectId}/${version}`),
          method: 'GET'
        }).then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            // 如果原来没有这个组织数据，则新增一个
            this.newModelOrgInfoData(projectId, version)
              .then((respData) => {
                resolve(respData.data!)
              })
              .catch((er: Error) => reject(er))
          }
        }).catch((err: Error) => reject(err))
      })
    }

    /**
     * 取得组织模型数据
     * @param projectId
     * @param version
     * @returns
     */
    public getModelOrgInfoData (projectId: number, version: string): Promise<DataApiResult<OrgInfoData>> {
      return request<DataApiResult<OrgInfoData>>({
        url: this.getUrl(`getModelOrgInfoData/${projectId}/${version}`),
        method: 'GET'
      })
    }

    /**
     * 新增实体模型的组织数据
     * @param projectId 项目id
     * @param version  版本号
     * @returns
     */
    public newModelOrgInfoData (projectId: number, version: string): Promise<DataApiResult<OrgInfoData>> {
      return request<DataApiResult<OrgInfoData>>({
        url: this.getUrl(`newModelOrgInfoData/${projectId}/${version}`),
        method: 'POST'
      })
    }

    /**
     * 修改指定的组织节点
     * @param projectId
     * @param versionUuid
     * @param orgUuid
     * @returns
     */
    public modiOrgNode (projectId: number, versionUuid: string, orgUuid: string, node: OrgInfo): Promise<DataApiResult<OrgInfoData>> {
      return request<DataApiResult<OrgInfoData>>({
        url: this.getUrl(`modiOrgNode/${projectId}/${versionUuid}/${orgUuid}`),
        method: 'POST',
        data: node
      })
    }

    /**
     * 移动组织节点
     * @param projectId
     * @param versionUuid
     * @param orgUuid
     * @param toParentUuid
     * @returns
     */
    public moveOrgNode (projectId: number, versionUuid: string, orgUuid: string, toParentUuid: string, toIndex: number) {
      return request<DataApiResult<OrgInfoData>>({
        url: this.getUrl(`moveOrgNode/${projectId}/${versionUuid}/${orgUuid}/${toParentUuid}/${toIndex}`),
        method: 'POST'
      })
    }

    /**
   * 取得当前用户的锁定数据模型的组织列表
   * @param id
   * @returns
   */
    public getCurrentUserLockOrgIds (projectId: number, versionUuid: string): Promise<DataApiResult<string[]>> {
      return request<DataApiResult<string[]>>({
        url: this.getUrl(`getUserLockOrgIds/${projectId}/${versionUuid}`),
        method: 'GET'
      })
    }

    /**
   * 尝试锁定指定的组织
   * @param id 要锁定的组织id
   * @returns 当前用户所有锁定的组织
   */
    public tryLockOrgId (projectId: number, versionUuid: string, orgUuid: string): Promise<DataApiResult<string[]>> {
      return request<DataApiResult<string[]>>({
        url: this.getUrl(`tryLockOrgId/${projectId}/${versionUuid}/${orgUuid}`),
        method: 'GET'
      })
    }

    /**
   * 解锁组织id
   * @param projectId
   * @param id
   * @returns
   */
    public unLockOrgId (projectId: number, versionUuid: string, orgUuid: string): Promise<DataApiResult<string[]>> {
      return request<DataApiResult<string[]>>({
        url: this.getUrl(`unLockOrgId/${projectId}/${versionUuid}/${orgUuid}`),
        method: 'GET'
      })
    }

    /**
     * 添加一个新的组织节点
     * @param projectId 项目id
     * @param versionUuid  版本号
     * @param parentUuid 父节点id
     * @param node 节点id
     * @returns
     */
    public addOrgNode (projectId: number, versionUuid: string,
      parentUuid: string, node: OrgInfo): Promise<DataApiResult<OrgInfoData>> {
      return request<DataApiResult<OrgInfoData>>({
        url: this.getUrl(`/addOrgNode/${projectId}/${versionUuid}/${parentUuid}`),
        data: node,
        method: 'POST'
      })
    }

    /**
     * 删除一个节点
     * @param projectId
     * @param versionUuid
     * @param orgUuid
     * @returns
     */
    public removeOrgNode (projectId: number, versionUuid: string,
      orgUuid: string): Promise<DataApiResult<OrgInfoData>> {
      return request<DataApiResult<OrgInfoData>>({
        url: this.getUrl(`delOrgNode/${projectId}/${versionUuid}/${orgUuid}`),
        method: 'DELETE'
      })
    }

    /**
     * 取得工程的最新版本号
     * @param projectId
     */
    public getEntityModelNewestVersion (projectId: number): Promise<DataApiResult<ProjectVersion>> {
      return request<DataApiResult<ProjectVersion>>({
        url: this.getUrl(`/getNewestVersion/${projectId}`),
        method: 'GET'
      })
    }

    /**
     * 新建一个版本
     * @param version
     * @returns
     */
    public newEntityVerson (version: ProjectVersion): Promise<DataApiResult<ProjectVersion>> {
      return request<DataApiResult<ProjectVersion>>({
        url: this.getUrl('newVersion'),
        data: version,
        method: 'POST'
      })
    }

    /**
     * 上传模拟型数据
     * @returns
     */
    public upLoadModelData (data: EntityModel, projectId: number, orgUuid: string, version: string): Promise<ApiResult> {
      return request<ApiResult>({
        url: this.getUrl(`upLoadModelData/${projectId}/${version}/${orgUuid}`),
        data: data,
        method: 'POST'
      }, true, true)
    }

    /**
     * 取得模型数据
     * @param projectId
     * @param orgUuid
     * @param version
     * @returns
     */
    public getModelData (projectId: number, version: string, orgUuid: string): Promise<EntityModel> {
      return requestRaw<EntityModel>({
        url: this.getUrl(`downModelData/${projectId}/${version}/${orgUuid}`),
        method: 'GET'
      })
    }

    /**
     * 检查工程的对应版本的组织的实体模型数据是否存在
     * @param projectId
     * @param version
     * @param orgUuid
     * @returns
     */
    public hasModelData (projectId: number, version: string, orgUuid: string): Promise<DataApiResult<boolean>> {
      return request<DataApiResult<boolean>>({
        url: this.getUrl(`hasModelData/${projectId}/${version}/${orgUuid}`),
        method: 'GET'
      })
    }
}

export default new WebApiEntityModelApi('/api/webapi/entity-model-manger')
