import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import storage from '@/libs/local-storage'
import project, { BaseProjectInfo } from '@/api/project'
import modelApi from '@/api/webapi-entity-model/webapi-entity-model-api'
import { expect } from 'chai'
import ProjectVersion from '@/libs/entitymodel/project-verion'
import { getShortUuid } from '@/libs/uuid'
import projectMemberApi, { ProjectMemberRight } from '@/api/project-member'

/**
 * 当前工程store
 */
export interface ProjectStore{
    projectId?: number;
    project?: BaseProjectInfo;
    activeEntityUuid?: string;
    /// 工程的语言配置
    languages?: string[];
    /// 当前用户锁定的组织
    lockOrgIds?: string[];
    /// 当前实体模型版本
    projectVersion?: ProjectVersion;
     /// 用户团队成员
    projectMemberRight?: ProjectMemberRight;
}

class ProjectStoreModule implements Module<ProjectStore, unknown> {
    // 限制作用域
    namespaced=true

    // 状态
    state: ProjectStore={
      projectId: storage.loadProjectId(),
      project: storage.loadProject(),
      activeEntityUuid: storage.loadActiveEntityUuid(),
      languages: storage.loadLanguages(),
      lockOrgIds: undefined,
      projectVersion: storage.loadEntityModelVersion(storage.loadProjectId()!),
      projectMemberRight: storage.loadCurProjectMemberRight()
    }

    // 修改器
    mutations: MutationTree<ProjectStore>={

      /**
         * 设置工程数据
         * @param state
         * @param project
         */
      setProject (state, project: BaseProjectInfo) {
        state.projectId = project.id
        state.project = project
        state.languages = project.languages || []

        storage.saveProject(project)
        storage.saveProjectId(project.id)
        storage.saveLanguages(state.languages)
      },
      /// 保存激活的uuid
      setActiveEntityUuid (state, uuid?: string) {
        state.activeEntityUuid = uuid
        storage.saveActiveEntityUuid(uuid)
      },

      /// 保存锁定的组织id列表
      setLockOrgIds (state, ids: string[]) {
        state.lockOrgIds = ids || []
        storage.saveLockOrgIds(ids)
      },
      /// 保存实体模型版本信息
      setEntityModelVersion (state, version: ProjectVersion|undefined) {
        state.projectVersion = version
        storage.saveEntityModelVersion(state.project?.id!, version)
      },
      /// 设置当前团队用户信息
      setProjectMemberRight (state, projectMemberRight?: ProjectMemberRight) {
        state.projectMemberRight = projectMemberRight
        storage.saveProjectMemberRight(projectMemberRight)
      }

    }

    // 操作列表
    actions: ActionTree<ProjectStore, unknown>={

      /**
       * 更新工程信息
       * @param projectId  工程id
       */
      updateProjectInfo ({ state, commit }, projectId: number): Promise<BaseProjectInfo> {
        return new Promise<BaseProjectInfo>((resolve, reject) => {
          let res: BaseProjectInfo|undefined
          project.getItemById(projectId)
            .then((data) => {
              if (data.code === 0) {
                commit('setProject', data.data!)
                res = data.data!
              } else {
                reject(new Error(`返回工程id=${projectId}失败:` + data.message))
                return
              }
              return projectMemberApi.getUserProjectMemberRight(projectId)
            }).then((respose) => {
              if (respose!.data) {
                commit('setProjectMemberRight', respose!.data)
                resolve(res!)
              } else {
                reject(new Error('您还不是这个项目的成员'))
              }
            })
            .catch((err: Error) => {
              console.error(`拉取id=${projectId}的工程时出错:` + err.message)
              reject(err)
            })
        })
      },
      /// 更新激活的entity
      updateActiveEntityUuid ({ commit }, uuid?: string) {
        commit('setActiveEntityUuid', uuid)
      },
      /// 更新当前实体模型的版本
      setCurEntityModelVersion ({ state, commit }, curVersion: string) {
        commit('setEntityModelVersion', curVersion)
      },
      /// 更新项目当前的最新版本号
      updateCurEntityModelVersion ({ state, commit }): Promise<ProjectVersion> {
        return new Promise<ProjectVersion>((resolve, reject) => {
          /// 当前版本号已经存在
          if (state.projectVersion) {
            resolve(state.projectVersion)
            return
          }
          modelApi.getEntityModelNewestVersion(state.project?.id!)
            .then((data) => {
              if (!data.data) {
                modelApi.newEntityVerson({
                  projectId: state.project?.id!,
                  uuid: getShortUuid(),
                  title: 'v1.0.0'
                })
                  .then((response) => {
                    commit('setEntityModelVersion', response.data)
                    resolve(response.data!)
                  })
                  .catch((err: Error) => reject(err))
              } else {
                commit('setEntityModelVersion', data.data)
                resolve(data.data!)
              }
            })
            .catch((err: Error) => {
              reject(err)
            })
        })
      },
      /// 更新锁定的组织ids
      updateLockOrgIds ({ state, commit }, { projectId, version }): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
          if (state.lockOrgIds) resolve(state.lockOrgIds)

          modelApi.getCurrentUserLockOrgIds(projectId, version)
            .then((response) => {
              const ids = response.data!
              commit('setLockOrgIds', ids)
              resolve(ids)
            })
            .catch((err: Error) => {
              console.error(`拉取${projectId}的数据模型锁定组织列表时出错:` + err.message)
              reject(err)
            })
        })
      },

      /**
       * 尝试锁定组织
       * @param param0
       * @param param1
       * @returns
       */
      tryLockOrgId ({ state, commit }, { projectId, version, orgId }): Promise<string[]> {
        expect(orgId, '参数orgId不允许为空').not.undefined.and.not.empty

        return new Promise<string[]>((resolve, reject) => {
          modelApi.tryLockOrgId(projectId, version, orgId)
            .then((response) => {
              const ids = response.data!
              commit('setLockOrgIds', ids)
              resolve(ids)
            })
            .catch((err: Error) => {
              console.error(`加锁${projectId}的数据模型${orgId}时出错:` + err.message)
              reject(err)
            })
        })
      },

      /// 解锁组织id
      unLockOrgId ({ state, commit }, { projectId, version, orgId }): Promise<string[]> {
        expect(orgId, '参数orgId不允许为空').not.undefined.and.not.empty

        return new Promise<string[]>((resolve, reject) => {
          modelApi.unLockOrgId(projectId, version, orgId)
            .then((response) => {
              const ids = response.data!
              commit('setLockOrgIds', ids)
              resolve(ids)
            })
            .catch((err: Error) => {
              console.error(`解锁${projectId}的数据模型${orgId}时出错:` + err.message)
              reject(err)
            })
        })
      }
    }

    // 访问器
    getters: GetterTree<ProjectStore, unknown>={
      /**
       * 返回当前工程的id
       * @param state
       * @param getters
       */
      projectIdGetter (state: ProjectStore, getters: unknown) {
        return state.projectId
      },
      /**
       * 返回当前工程信息
       * @param state
       * @param getters
       */
      projectGetter (state: ProjectStore, getters: unknown) {
        return state.project
      },
      /**
       * 返回当前激活的实体uuid
       * @param state
       * @param getters
       */
      activeEntityUuidGetter (state: ProjectStore, getters: unknown) {
        return state.activeEntityUuid
      },

      /**
       * 返回当前锁定的组织id列表
       * @param state
       * @param getters
       * @returns
       */
      lockOrgIdsGetter (state: ProjectStore, getters: unknown) {
        return state.lockOrgIds || []
      },

      /**
       * 返回当前实体模型的版本
       * @param state
       * @param getters
       * @returns
       */
      entityModelVersionGetter (state: ProjectStore, getters: unknown) {
        return state.projectVersion
      },
      /**
     * 取得当前用户的团队权限信息
     * @param state
     * @param getters
     * @returns
     */
      projectMemberRightGetter (state: ProjectStore, getters: unknown) {
        return state.projectMemberRight
      }

    }
}

export default new ProjectStoreModule()
