import { RouteConfig } from 'vue-router'
import MainFrame from '@/frame/main/main-layout.vue'

const routers: Array<RouteConfig> = [
  {
    name: 'team',
    component: MainFrame,
    path: '/team',

    children: [
      /* {
        name: 'team-request',
        path: 'request',
        component: () => import('@/views/team/team-request/data-list/index.vue'),
        meta: {

        }
      }, */
      {
        name: 'team-request-view',
        path: 'logview',
        component: () => import('@/views/team/team-request/data-page/index.vue'),
        meta: {

          title: '用户申请'
        }
      },
      {
        name: 'team-request-approval',
        path: 'team-request-approval',
        component: () => import('@/views/team/team-request-approval/tab-approval.vue'),
        meta: {
          title: '团队申请'

        }
      },
      {
        name: 'team-group',
        path: 'team-group',
        component: () => import('@/views/team/team-group/index.vue'),
        meta: {
          title: '小组管理',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'team-members',
        path: 'team-members',
        component: () => import('@/views/team/team-members/index.vue'),
        meta: {
          title: '团队成员',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'company-regist-record',
        path: 'company-regist-record',
        component: () => import('@/views/team/company-regist-record/data-list.vue'),
        meta: {
          title: '企业开通记录',
          showOnBreadCrumb: true
        }
      }
    ]
  }
]

export default routers
