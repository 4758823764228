/**
 * 实体定义处理工具
 */

import { expect } from 'chai'
import _ from 'lodash'
import { EntityModel } from './entity-model'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import { getShortUuid } from '../uuid'
import Entity, { entityToEntiyDefine, EntityType, HasPackageInfoEntity } from './entity'
import EntityModelViewManger, { EntityModelView } from './entity-model-view'
import EntityField from './entity-field'
import EntityLeftJoin from './entity-leftjoin'
import { getterLockOrgIds, getterUserInfo } from '@/store'
import moment from 'moment'
import entityModelApi from '@/api/webapi-entity-model/webapi-entity-model-api'

/**
 * 数据模形对象
 */
export default class EntityModelManger {
  /// 组织信息
  orgs: Record<string, OrgInfo> = {}

  /// 以uuid组织的实体对象列表
  entitiesMap: Record<string, Entity> = {}

  /// 实体模型视图定义
  views: Record<string, EntityModelViewManger> = {}

  /// 粘贴板缓存
  pasteboardCache: EntityField[] | Entity[] = []
  /// 粘贴板内容类型
  pasteboardType: 'field' | 'entity' | 'none' = 'none'

  /// 版本号
  version = '';
  /// 项目id
  projectId = -1

  /// 创建时间
  createTime = '';

  /// 最后修改时间
  lastModiTime = '';

  /// 创建者id
  creator = -1;

  /// 修改者id
  modifier = -1;

  /// 新增实体计数
  newEntityCount = 0;

  /**
   * 通过版本号与工程id进行初始化
   * @param projectId
   * @param version
   */
  public loadOrgData (projectId: number, version: string) {
    this.projectId = projectId
    this.version = version
    const that = this
    return new Promise<OrgInfo>((resolve, reject) => {
      /// 初始组织节点信息
      entityModelApi.getOrNewModelOrgInfoData(projectId, version)
        .then((orgInfoData) => {
          that.loadOrgInfos(orgInfoData.data)
          resolve(orgInfoData.data)
        }).catch((err: Error) => reject(err))
    })
  }

  /**
   * 加载指定的组织以及组织实图中相关的数据
   * @param orgUuid
   */
  public loadDataByOrgIdAndRation (orgUuid: string, forced = false) {
    return new Promise<void>((resolve, reject) => {
      this.loadDataByOrgId(orgUuid, forced)
        .then((viewData) => {
          // 统计出有关联的视图
          const rationViews: string[] = []
          for (const key in viewData.entityPos) {
            const pos = viewData.entityPos[key]
            if (pos.orgUuid !== orgUuid) {
              expect(pos.orgUuid, '视图pos数据的orgUuid不允许为空')
              rationViews.push(pos.orgUuid)
            }
          }
          return rationViews
        }).then((rationOrgs) => {
          let sequence = Promise.resolve()
          for (const orgId of rationOrgs) {
            sequence = sequence.then(() => {
              return new Promise<void>((resolve, reject) => {
                this.loadDataByOrgId(orgId, forced)
                  .then(() => resolve())
                  .catch((err: Error) => reject(err))
              })
            })
          }
          return sequence
        }).then(() => {
          resolve()
        }).catch((err: Error) => reject(err))
    })
  }

  /**
   * 通过组织id加载模型数据
   * @param orgUuid
   */
  private loadDataByOrgId (orgUuid: string, forced: boolean) {
    expect(orgUuid, '参数orgUuid不允许为空').not.undefined.and.not.empty

    return new Promise<EntityModelView>((resolve, reject) => {
      // 如果要加载的组织数据当前已经锁定，并且当前已经有数据了，则直接返回
      const lockedOrgs = getterLockOrgIds()
      const orgIsLocked = lockedOrgs.findIndex((item) => item === orgUuid) >= 0
      if (!forced && orgIsLocked && this.views[orgUuid]) {
        resolve(this.views[orgUuid])
        return
      }

      // 否则加载指定的组织数据
      entityModelApi.hasModelData(this.projectId, this.version, orgUuid)
        .then((response) => {
          if (response.data) {
            return entityModelApi.getModelData(this.projectId, this.version, orgUuid)
          } else {
            /// 服务器原来没有数据，则初始化
            return new Promise<EntityModel>((resolve, reject) => {
              resolve({
                version: this.version,
                entities: {},
                views: {},
                orgInfoDefines: [],
                createTime: '',
                lastModiTime: '',
                creator: getterUserInfo().userId,
                modifier: getterUserInfo().userId
              })
            })
          }
        }).then((response) => {
          this.loadEntities(response.entities)
          this.loadEntityView(response.views)
          resolve(response.views[orgUuid] || {})
        }).catch((err: Error) => reject(err))
    })
  }

  /**
  * 从定义列表到得组织信息列表
  * @param define
  */
  public loadOrgInfos (root: OrgInfo) {
    expect(root, '参数root不允许为空').not.undefined
    const orgs = {}
    this.loadOrgNode(root, orgs, undefined)
    this.orgs = orgs
  }

  private loadOrgNode (root: OrgInfo, orgs: Record<string, OrgInfo>, parent?: OrgInfo): OrgInfo {
    expect(root, '参数root不允许为空').not.undefined

    const node: OrgInfo = {
      uuid: root.uuid,
      name: root.name,
      title: root.title,
      parentOrg: parent,
      children: []
    }
    for (const ch of root.children) {
      node.children.push(this.loadOrgNode(ch, orgs, node))
    }
    orgs[node.uuid] = node
    return node
  }

  /**
   * 加载所有的组织以及对应的实体
   * @param projectId
   * @param version
   * @returns
   */
  public loadAllOrgsAndEntities (projectId: number, version: string) {
    return new Promise<void>((resolve, reject) => {
      this.loadOrgData(projectId, version).then((res: OrgInfo) => {
        const orgList: OrgInfo[] = []
        this.getOrgList(res, orgList)
        return Promise.all(orgList.map(o => this.loadDataByOrgIdAndRation(o.uuid)))
      }).then(() => resolve()).catch((err) => reject(err))
    })
  }

  private getOrgList (root: OrgInfo, list: OrgInfo[]) {
    list.push(root)
    root?.children.map(e => {
      this.getOrgList(e, list)
    })
  }

  /**
   * 加载实体定义
   * @param defs
   */
  public loadEntities (defs: Record<string, Entity>) {
    expect(defs, '参数defs不允许为空').not.undefined

    for (const key in defs) {
      this.loadEntity(defs[key])
    }
  }

  /**
   * 加载实体到管理器
   * @param entity 实体对象
   */
  private loadEntity (entity: Entity) {
    entity.org = this.getOrgInfoById(entity.orgId)
    if (!entity.indexes) entity.indexes = []
    if (!entity.constraints) entity.constraints = []
    if (!entity.fields) entity.fields = []
    if (!entity.joins) entity.joins = []

    this.entitiesMap[entity.uuid] = entity
  }

  /**
 * 查找实体的全名
 */
  /* private getEntityFullNameByEntity (entity: Entity): string {
    const r = (org: OrgInfo): string | undefined => {
      if (org.uuid === entity.orgId) {
        return org.name
      }
      for (const index in org.children) {
        const res = r(org.children[index])
        if (res) {
          return `${org.name}.${res}`
        }
      }
    }
    for (const index in this.orgs) {
      const res = r(this.orgs[index])
      if (res) {
        return res
      }
    }
    return ''
  } */

  /**
   * 查找实体的全名
   * @param entity
   * @returns
   */
  private getEntityFullNameByEntity (entity: Entity): string {
    const r = (org?: OrgInfo): string | undefined => {
      if (org?.parentOrg) {
        return `${r(org?.parentOrg)}.${org.name}`
      } else {
        return org ? org.name : undefined
      }
    }
    return r(entity?.org) || ''
  }

  /**
   * 查找基础包名
   * @param entity
   * @returns
   */
  private getBasePackageNameByEntity (entity: Entity): string {
    const r = (org?: OrgInfo): string | undefined => {
      if (org?.parentOrg) {
        return r(org?.parentOrg)
      } else {
        return org ? org.name : undefined
      }
    }
    return r(entity?.org) || ''
  }

  /**
   * 加载实体视图
   * @param views 视图定义
   */
  public loadEntityView (views: Record<number, EntityModelView>) {
    if (!views) {
      this.views = {}
      return
    }
    /// 确保实体位置数组正确
    for (const key in views) {
      const view = views[key]
      if (!view.entityPos) view.entityPos = {}
      this.views[key] = new EntityModelViewManger(view)
    }
  }

  /**
   * 通过组织id取得组织
   * @param id 组织id
   */
  getOrgInfoById (id: string): OrgInfo {
    const org = this.orgs[id]
    expect(org, `组织id=${id}不存在`).not.undefined
    return org
  }

  /**
   * 通过组织id取得组织的实体对象列表
   * @param id 组织id
   */
  getEnitiesByOrgId (id: string): Entity[] {
    expect(id, '参数id不允许为空').not.undefined
    const entities: Entity[] = []
    for (const uuid in this.entitiesMap) {
      const entity = this.entitiesMap[uuid]
      if (entity && entity.orgId === id) {
        entities.push(entity)
      }
    }

    return entities
  }

  /**
   * 通过uuid取得实体对象
   * @param uuid 实体对象的uuid
   */
  getEntityByUuid (uuid: string): Entity | undefined {
    expect(uuid, '参数uuid不允许为空').not.undefined
    return this.entitiesMap[uuid]
  }

  /**
   * 通过组织id取得视图对象
   * @param orgId 组织id
   */
  getDataViewMangerByOrgId (orgId: string): EntityModelViewManger {
    expect(orgId, '参数orgId不允许为空').not.undefined.and.not.empty
    let view = this.views[orgId]
    if (!view) {
      const newView = new EntityModelViewManger({
        scrollX: 0,
        scrollY: 0,
        scale: 1,
        entityPos: {},
        orgId: orgId
      })

      // 对实体视图进行初始化
      newView.initByEntities(this.getEnitiesByOrgId(orgId))
      view = newView
      this.updateDataViewByOrgId(orgId, newView)
    }

    view.orgId = orgId
    return view as EntityModelViewManger
  }

  /**
   * 更新视图对象
   * @param orgId
   * @param view
   */
  updateDataViewByOrgId (orgId: string, view: EntityModelViewManger) {
    expect(orgId, '参数orgId不允许为空').not.undefined.not.empty
    expect(view, '参数view不允许为空').not.undefined
    this.views[orgId] = view
  }

  /**
   * 添加新的实体对象，并初始化设置视图数据
   * @param orgId 组织id
   * @param entity 实体对象
   */
  private addNewEntityAndSetOrgView (orgId: string, entity: Entity) {
    // 不允许添加重复的实体
    expect(this.entitiesMap[entity.uuid], `uuid=${entity.uuid}的实体已经存在，不允许重复添加`).is.undefined

    this.entitiesMap[entity.uuid] = entity

    // 在组织的视图中添加 实体的位置

    let left = 300
    let top = 300

    const view = this.getOrCreateOrgViewManger(orgId)

    // 取得当前视图中最后一个 位置信息
    const lastPosKey = _.findLastKey(view.entityPos)

    if (lastPosKey) {
      const lastPos = view.entityPos[lastPosKey]
      left = lastPos.left + 100
      top = lastPos.top + 100
    }

    view.entityPos[entity.uuid] = {
      left: left,
      top: top,
      orgUuid: orgId,
      uuid: entity.uuid
    }

    return entity.uuid
  }

  /**
   * 添加一个新的实体对象到组织下
   * @param orgId
   */
  addNewEntity (orgId: string, entity?: Entity, type?: EntityType): Entity {
    if (!entity) {
      const uuid = getShortUuid()
      this.newEntityCount++
      entity = {
        uuid: uuid,
        name: 'Entity' + (new Date()).getTime(),
        title: '实体' + this.newEntityCount,
        orgId: orgId,
        type: 'Entity',
        autoTable: true,
        fields: [],
        constraints: [],
        indexes: [],
        joins: []
      }
    }
    entity.org = this.orgs[orgId]
    if (type) entity.type = type

    this.addNewEntityAndSetOrgView(orgId, entity)
    return entity
  }

  /// 移除实体对象
  removeEntity (uuid: string) {
    const entity = this.entitiesMap[uuid]
    if (entity) {
      _.unset(this.entitiesMap, uuid)

      // 搜索所有的视图，把它删除
      for (const orgId in this.orgs) {
        const view = this.views[orgId]
        if (view) {
          view.removeEntityPos(uuid)
        }
      }
    }
  }

  /**
   * 取得或创建组织的视图管理员
   * @param orgId 组织id
   */
  getOrCreateOrgViewManger (orgId: string): EntityModelViewManger {
    let view = this.views[orgId]
    if (!view) {
      view = new EntityModelViewManger({
        orgId: orgId,
        entityPos: {},
        scale: 1,
        scrollX: 0,
        scrollY: 0
      })

      this.views[orgId] = view
    }

    return view
  }

  /**
   * 通过uuid取得字段对象
   * @param entityUuid 实体对象uuid
   * @param fieldUuid  字段对象uuid
   */
  getFieldByUuid (entityUuid: string, fieldUuid: string): EntityField | undefined {
    expect(entityUuid, '参数entityUuid不允许为空').not.undefined.and.not.empty
    expect(fieldUuid, '参数fieldUuid不允许为空').not.undefined.and.not.empty

    const entity = this.getEntityByUuid(entityUuid)
    if (!entity) return undefined
    const index = entity.fields.findIndex((item) => item.uuid === fieldUuid)
    if (index < 0) return undefined
    return entity.fields[index]
  }

  /**
   * 通过实体uuid取得实体的所有字段，也包括基础类的字段
   * @param entityUuid 实体的uuid
   */
  getEntityAllFieldes (entityUuid: string): EntityField[] {
    expect(entityUuid, '参数entityUuid不允许为空').not.undefined.and.not.empty

    const res: EntityField[] = []
    let entity = this.entitiesMap[entityUuid]

    expect(entity, `没有找到uuid=${entityUuid}实体对象`).not.undefined
    // 添加实体的所有字段
    res.push(...entity.fields)

    // 找出所有的父级字段
    while (entity.baseEntityUuid) {
      entity = this.entitiesMap[entity.baseEntityUuid]
      res.push(...entity.fields)
    }

    return res
  }

  /**
   * 通过join的uuid取得联结对象
   * @param entityUuid 实体uuid
   * @param joinUuid  join的uuid
   * @returns 对应的联结对象
   */
  getEntityJoinByUuid (entityUuid: string, joinUuid: string): EntityLeftJoin | undefined {
    expect(entityUuid, '参数entityUuid不允许为空').not.undefined.and.not.empty
    expect(joinUuid, '参数joinUuid不允许为空').not.undefined.and.not.empty

    const entity = this.getEntityByUuid(entityUuid)
    if (!entity) return undefined
    const index = entity.joins.findIndex((item) => item.uuid === joinUuid)
    if (index < 0) return undefined
    return entity.joins[index]
  }

  /**
   * 添加实体左联结
   * @param join
   * @returns
   */
  addEntityLeftJoin (entityUuid: string, join: EntityLeftJoin) {
    const entity = this.getEntityByUuid(entityUuid)
    if (!entity) return
    if (!entity.joins) entity.joins = []
    entity.joins.push(join)
  }

  /**
   * 删除left join
   * @param join
   */
  removeEntityLeftJoin (join: EntityLeftJoin) {
    expect(join, '参数join不允许为空').not.undefined

    const entity = this.getEntityByUuid(join.mainEnityUuid)
    if (!entity) return
    const index = entity.joins.findIndex((item) => item.uuid === join.uuid)
    if (index < 0) return
    entity.joins.splice(index, 1)
  }

  /**
   * 将指定的组织数据转为entityModel以方便存贮
   * @param orgUuid 要转换的组织uuid
   */
  toEntityModel (orgUuid: string): EntityModel {
    // 视图转为定义
    const views: Record<string, EntityModelView> = {}

    /// 取得视图数据
    const view = this.views[orgUuid]
    if (!view) {
      views[orgUuid] = {
        entityPos: {},
        orgId: orgUuid,
        scale: 1,
        scrollX: 0,
        scrollY: 0
      }
    } else {
      views[orgUuid] = view.toEntityModelView()
    }

    // 实体列表转为定义
    const entities: Record<string, Entity> = {}
    for (const key in this.entitiesMap) {
      const entity = this.entitiesMap[key]
      if (entity.orgId !== orgUuid) continue
      entities[key] = entityToEntiyDefine(entity)
    }

    /**
     * 取得当前用户信息
     */
    const userInfo = getterUserInfo()

    return {
      version: this.version,
      orgInfoDefines: [],
      views: views,
      entities: entities,
      createTime: this.createTime,
      creator: this.creator,
      lastModiTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      modifier: userInfo.userId
    }
  }

  /// 保存组织模型的数据到服务器
  saveOrgModelData (orgUuid: string, version?: string) {
    expect(orgUuid, '参数orgUuid不允许为空').not.undefined

    const lockedOrgs = getterLockOrgIds()
    const islocked = lockedOrgs.findIndex((item) => item === orgUuid) >= 0

    return new Promise<void>((resolve, reject) => {
      if (!islocked) {
        reject(new Error('不允许保存没有锁定的数据'))
        return
      }
      entityModelApi.upLoadModelData(
        this.toEntityModel(orgUuid), this.projectId!, orgUuid, version || this.version)
        .then((response) => {
          resolve()
        }).catch((err: Error) => reject(err))
    })
  }

  /// 保存所有的数据
  saveAll (version?: string) {
    const lockedOrgs = getterLockOrgIds()

    if (lockedOrgs.length === 0) {
      return Promise.reject(new Error('当前没有要保存的数据'))
    }

    let sequence = Promise.resolve()
    for (const orgUuid of lockedOrgs) {
      /// 如果没有加载过不保存
      if (!this.views[orgUuid]) continue
      sequence = sequence.then(() => this.saveOrgModelData(orgUuid, version))
    }
    return sequence
  }

  // 暂存需要复制的字段
  cacheFields (fields: EntityField[]) {
    this.pasteboardCache = _.cloneDeep(fields)
    this.pasteboardType = 'field'
  }

  /**
   * 粘贴复制的字段
   * @param targetEntityUuid 模板实体uuid
   * @returns
   */
  pasteFields (targetEntityUuid: string) {
    if (this.pasteboardType !== 'field') { return }
    const entity = this.entitiesMap[targetEntityUuid]
    const fields = (this.pasteboardCache as EntityField[]).map((e: any) => {
      e.uuid = getShortUuid()
      return e
    })
    entity.fields.push(...fields)

    this.pasteboardCache = []
    this.pasteboardType = 'none'
  }

  /**
   * 为实体添加包信息
   * @param entity
   * @returns
   */
  getHasPackageInfoEntity (entity: Entity): HasPackageInfoEntity {
    const _entity = entity as HasPackageInfoEntity
    _entity.fullName = this.getEntityFullNameByEntity(entity)
    _entity.basePackageName = this.getBasePackageNameByEntity(entity)

    return _entity
  }
}
