import { ApplicationProfile } from '@/api/maintain/application-profile'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { ConfigApplication } from '@/api/maintain/config-application'
import storage from '@/libs/local-storage'
import { AppInstance } from '@/api/maintain/app-instance'
/**
 * 当前工程store
 */
export interface MaintainStore{
    curInstanceIp: string;
    curConfigApplciation?: ConfigApplication;
    curAppProfile?: ApplicationProfile;
    gatewayInstance?: AppInstance;
    consulInstance?: AppInstance;
    configCenterInstance?: AppInstance;
  }

class MaintainStoreModule implements Module<MaintainStore, unknown> {
    // 限制作用域
    namespaced=true

    // 状态
    state: MaintainStore={
      curInstanceIp: storage.loadCurInstanceIp() || 'localhost',
      curConfigApplciation: storage.loadCurConfigApplication(),
      curAppProfile: storage.loadCurProfile(),
      gatewayInstance: storage.loadGatewayInstance(),
      consulInstance: storage.loadConsulInstance(),
      configCenterInstance: storage.loadConfigCenterInstance()
    }

    // 修改器
    mutations: MutationTree<MaintainStore>={

      /// 设置当前实例ip
      setCurInstanceIp (state, ip: string) {
        state.curInstanceIp = ip
        storage.saveCurInstanceIp(ip)
      },
      /// 设置当前配置项目信息
      setCurConfigApplciation (state, curConfigApplciation?: ConfigApplication) {
        state.curConfigApplciation = curConfigApplciation
        storage.saveCurConfigApplication(curConfigApplciation)
      },
      /// 设置当前配置项目版本信息
      setCurAppProfile (state, curAppProfile?: ApplicationProfile) {
        state.curAppProfile = curAppProfile
        storage.saveCurProfile(curAppProfile)
      },
      /// 设置当前网关选中实例
      setGatewayInstance (state, gatewayInstance?: AppInstance) {
        state.gatewayInstance = gatewayInstance
        storage.saveGatewayInstance(gatewayInstance)
      },
      /// 设置consul选中实例
      setConsulInstance (state, consulInstance?: AppInstance) {
        state.consulInstance = consulInstance
        storage.saveConsulInstance(consulInstance)
      },
      /// 设置配置中心选中实例
      setConfigCenterInstance (state, configCenterInstance?: AppInstance) {
        state.configCenterInstance = configCenterInstance
        storage.saveConfigCenterInstance(configCenterInstance)
      }
    }

    // 操作列表
    actions: ActionTree<MaintainStore, unknown>={
      /// 设置当前实例ip
      updateCurInstanceIp ({ commit }, instanceIp?: string) {
        commit('setCurInstanceIp', instanceIp)
      },
      /// 设置当前配置项目信息
      setCurConfigApplciation ({ commit }, curConfigApplciation?: ConfigApplication) {
        commit('setCurConfigApplciation', curConfigApplciation)
      },
      /// 设置当前配置项目版本信息
      setCurAppProfile ({ commit }, curAppProfile?: ApplicationProfile) {
        commit('setCurAppProfile', curAppProfile)
      },
      /// 设置网关选中实例
      setGatewayInstance ({ commit }, gatewayInstance?: AppInstance) {
        commit('setGatewayInstance', gatewayInstance)
      },
      /// 设置consul选中实例
      setConsulInstance ({ commit }, consulInstance?: AppInstance) {
        commit('setConsulInstance', consulInstance)
      },
      /// 设置配置中心选中实例
      setConfigCenterInstance ({ commit }, configCenterInstance?: AppInstance) {
        commit('setConfigCenterInstance', configCenterInstance)
      }
    }

    // 访问器
    getters: GetterTree<MaintainStore, unknown>={
      /**
       * 返回当前实例ip
       * @param state
       * @param getters
       */
      curInstanceIpGetter (state: MaintainStore, getters: unknown) {
        return state.curInstanceIp
      },
      /**
       * 返回当前配置项目信息
       * @param state
       * @param getters
       */
      curConfigApplciationGetter (state: MaintainStore, getters: unknown) {
        return state.curConfigApplciation
      },
      /**
       * 返回当前配置项目版本信息
       * @param state
       * @param getters
       */
      curAppProfileGetter (state: MaintainStore, getters: unknown) {
        return state.curAppProfile
      },
      /** 返回当前网关缓存实例 */
      gatewayInstance (state: MaintainStore, getters: unknown) {
        return state.gatewayInstance
      },
      /** 返回当前consul缓存实例 */
      consulInstance (state: MaintainStore, getters: unknown) {
        return state.consulInstance
      },
      /** 返回当前配置中心缓存实例 */
      configCenterInstance (state: MaintainStore, getters: unknown) {
        return state.configCenterInstance
      }
    }
}

export default new MaintainStoreModule()
