import config from '@/config/index'
import Cookies from 'js-cookie'

// 当前是否与服务器联调
export const isDebugLocal: boolean = (process.env.NODE_ENV === 'development' && !config.isConnectDebugSever)
/**
 * token工具
 */
const TOKEN_KEY = 'token'
const TOKEN_RFRESH_KEY = 'refresh_token'
const TOKEN_INVALID_TIME_KEY = 'token_invalid_time'

export default class Token {
  /**
     * 设置当前的token
     * @param token
     */
  static setToken (token: string) {
    if (isDebugLocal) {
      Cookies.set(TOKEN_KEY, token, { expires: config.cookieExpires || 1 })
    } else {
      sessionStorage.setItem(TOKEN_KEY, token)
    }
  }

  /**
   * 设置当前的refresh token
   * @param token
   */
  static setRefreshToken (token: string) {
    if (isDebugLocal) {
      Cookies.set(TOKEN_RFRESH_KEY, token, { expires: config.cookieExpires || 1 })
    } else {
      sessionStorage.setItem(TOKEN_RFRESH_KEY, token)
    }

    // Cookies.set(TOKEN_RFRESH_KEY, token, { expires: config.cookieExpires || 1 })
  }

  /**
   * 取得当前的token
   */
  static getToken (): string|undefined {
    let token
    if (isDebugLocal) {
      token = Cookies.get(TOKEN_KEY)
    } else {
      token = sessionStorage.getItem(TOKEN_KEY)
    }

    if (token) return token
    else return undefined
  }

  /**
   * 取得当前的刷新token
   */
  static getRefreshToken (): string|undefined {
    let token
    if (isDebugLocal) {
      token = Cookies.get(TOKEN_RFRESH_KEY)
    } else {
      token = sessionStorage.getItem(TOKEN_RFRESH_KEY)
    }

    if (token) return token
    else return undefined
  }

  /**
   * 设置token过期时间
   * @param time
   */
  static setInvalidTime (time: Date|undefined) {
    const data = time ? time.toJSON() : ''
    sessionStorage.setItem(TOKEN_INVALID_TIME_KEY, data)
  }

  /**
   * 读取token过期时间
   * @returns
   */
  static loadInvalidTime () {
    const data = sessionStorage.getItem(TOKEN_INVALID_TIME_KEY)
    if (data === '') return undefined

    return new Date(data!)
  }
}
