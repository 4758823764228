
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { WithRightAndStateComponentMinx } from '@/libs/form-state'

@Component({
  name: 'collapsePanel',
  mixins: [WithRightAndStateComponentMinx]
})
export default class CollapsePanel extends Vue {
  /**
   * 面版开关状态
   */
  @Prop({
    type: Boolean,
    default: true,
    required: false
  })
  open!: boolean;

  /**
   * 是否可以折叠面版
   */
  @Prop({
    type: Boolean,
    default: true,
    required: false
  })
  canCollapse!: boolean;

  /**
   * 标题
   */
  @Prop({
    type: String,
    default: '',
    required: false
  })
  title!: string

  /**
   * 当前面版本开关状态
   */
  private curOpen = true;

  @Watch('open')
  watchOpen (openStatus: boolean) {
    this.curOpen = openStatus
  }

  created () {
    this.curOpen = this.open
  }

  /**
   * 开关面版
   */
  onCollapsePanle () {
    this.curOpen = !this.curOpen
  }

  // 当前图标
  get icon () {
    return this.curOpen
      ? 'iconfont iconpanel_close'
      : 'iconfont iconpanel_open'
  }
}
