import { render, staticRenderFns } from "./controller-info-panel.vue?vue&type=template&id=25c11ea1&scoped=true&"
import script from "./controller-info-panel.vue?vue&type=script&lang=ts&"
export * from "./controller-info-panel.vue?vue&type=script&lang=ts&"
import style0 from "./controller-info-panel.vue?vue&type=style&index=0&id=25c11ea1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c11ea1",
  null
  
)

export default component.exports