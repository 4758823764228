import { RouteConfig } from 'vue-router'
import MainLayout from '@/frame/main/main-layout.vue'
import reminder from './module/reminder'
import teamManger from './module/team'
import projectWebApi from './module/project-webapi'
import test2 from './module/test'
import projectRoutes from './module/project'
import maintain from './module/maintain'

/**
 * 路由配置文件的主入口文件
 */
const routers: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/project',
    name: 'project',
    redirect: '/project/my-project',
    component: MainLayout
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
    meta: {
      skipAuthentication: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      skipAuthentication: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/register.vue'),
    meta: {
      skipAuthentication: true
    }
  }
]

export default [
  // 主路由配置
  ...routers,
  ...teamManger,
  ...projectWebApi,
  ...reminder,
  ...test2,
  ...projectRoutes,
  ...maintain
]
