import { dispatchHandleLoginByOss, dispatchUpdateUserInfo, dispatchUserModules, getterCurrentRouteMoudleInfoGetter, getterUserToken } from '@/store'
import _ from 'lodash'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import { ErrorPage } from './module/reminder'
import routes from './routes'

Vue.use(VueRouter)

export const LOGIN_PAGE_NAME = 'login'
export const PAGE_404 = 'page404'
export const PAGE_401 = 'page401'
export const OSSKEYParamerName = 'seckey'

const router = new VueRouter({
  routes
})

/// 跳转到目标页面
function gotoNext (to: Route, next: NavigationGuardNext<Vue>) {
  /// 拉取用户的菜单，权限信息
  dispatchUpdateUserInfo().then(() => {
    return dispatchUserModules(false)
  }).then(() => {
    const moudleInfo = getterCurrentRouteMoudleInfoGetter()
    if (moudleInfo && moudleInfo.paramters && moudleInfo.fullPath === to.fullPath) {
      _.merge(to.params, moudleInfo.paramters)
    }
    next()
  }).catch((err: Error) => {
    Vue.prototype.$Loading.finish()
    console.error('router', err)

    // 跳到出错页面
    next({
      name: ErrorPage,
      params: {
        message: err.message
      }
    })
  })
}

/**
 * 添加路由守护，对权限等进行控制
 */
router.beforeEach((to, from, next) => {
  // 显示页面加载进度
  Vue.prototype.$Loading.start()

  /// 记录下每一个路由第一次调用时的上一次来源
  if (!to.params.from) {
    to.params.from = from.path
  }

  // 在请求中添加token信息
  const token = getterUserToken()

  // 不需要权限的页面
  if (to.name === PAGE_404 || to.name === PAGE_401 || to?.meta?.skipAuthentication) {
    next()
    return
  }

  let tokenFlag = true
  if (!token || token === 'undefined') {
    tokenFlag = false
  }
  if (!tokenFlag && to.name !== LOGIN_PAGE_NAME) {
    // 如果是带有了osskey的地址，先进行登入操作
    const ossKey = to.query[OSSKEYParamerName] as string
    if (ossKey !== undefined && ossKey !== '') {
      dispatchHandleLoginByOss(ossKey).then(() => {
        gotoNext(to, next)
      })
        .catch((ex) => {
          console.log('sso跳转失败:%o', ex)
          next({
            name: LOGIN_PAGE_NAME // 跳转到登录页
          })
        })
    } else {
      // 未登录且要跳转的页面不是登录页
      next({
        name: LOGIN_PAGE_NAME // 跳转到登录页
      })
    }
  } else if (!tokenFlag && to.name === LOGIN_PAGE_NAME) {
    next()
  } else if (tokenFlag && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    next({
      name: 'index' // 跳转到home页
    })
  } else {
    if (to.meta?.title) {
      document.title = to.meta.title
    }
    gotoNext(to, next)
  }
})

router.afterEach((to, from) => {
  // 关闭页面加载进度
  Vue.prototype.$Loading.finish()
})

export default router
