/**
 * 实体定义
 */

import { expect } from 'chai'
import Constraint from './entity-constraint'
import DelCheck from './entity-delcheck'
import EntityField from './entity-field'
import { Index } from './entity-index'
import EntityLeftJoin from './entity-leftjoin'
import OrgInfo from './entity-orginfo'
import HasUuid from './has-uuid'

/// 实体类型
export type EntityType =
  /// 实体
  'Entity' |
  /// 视图
  'View' |
  /// 基础实体
  'SupperEntity'

/**
 * 基础实体对象定义
 */
export default interface Entity extends HasUuid {

  /// 名称
  name: string;
  /// 标题
  title: string;
  /// 组织名
  orgId: string;
  /// 自动表管理
  autoTable: boolean;
  /// 表名
  tableName?: string;
  /// 类型
  type: EntityType;
  /// 字段列表
  fields: EntityField[];
  /// 基础类的uuid
  baseEntityUuid?: string;
  /// 实体左联结列表
  joins: EntityLeftJoin[];
  /// 约束列表
  constraints: Constraint[];
  /// 索引列表
  indexes: Index[];
  /// 组织对象
  org?: OrgInfo;
  /// 视图脚本
  viewScript?: string;
  /// 视图次序
  viewOrder?: number;
  /// 删除检查
  delCheck?: DelCheck[];
}

export interface HasPackageInfoEntity extends Entity {
  fullName: string;
  basePackageName: string;
}

/**
 * 把实体对象转化为定义，以方便存贮
 * @param entity
 * @returns
 */
export function entityToEntiyDefine (entity: Entity): Entity {
  return {
    uuid: entity.uuid,
    /// 名称
    name: entity.name,
    /// 标题
    title: entity.title,
    /// 组织名
    orgId: entity.orgId,
    /// 自动表管理
    autoTable: entity.autoTable,
    /// 表名
    tableName: entity.tableName,
    /// 类型
    type: entity.type,
    /// 字段列表
    fields: entity.fields,
    /// 基础类的uuid
    baseEntityUuid: entity.baseEntityUuid,
    /// 实体左联结列表
    joins: entity.joins,
    /// 约束列表
    constraints: entity.constraints,
    /// 索引列表
    indexes: entity.indexes,
    /// 视图脚本
    viewScript: entity.viewScript,
    /// 视图次序
    viewOrder: entity.viewOrder,
    /// 删除检查
    delCheck: entity.delCheck
  }
}
