/**
 *  系统配置文件可用于设置使用系统运行的参数
 * */
export default {
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,

  /**
   * 是否与服务器联调 要配合修改vue.config.js来实现
   */
  isConnectDebugSever: true,

  // 系统地址配置
  baseUrl: {
    // 调试时地址前缀
    dev: '',
    // 生产环境地址前缀 主要用于添加zuul服务器的前缀
    pro: ''
  },

  // oauth2客户端密码
  clientpwd: 'webserver!@#',
  // oauth2客户端名称
  clientname: 'customer_webapi',
  // oauth2客户端范围
  clientscope: 'xbgcoding_CustomerWebapi',

  // 是否模拟用户
  simulatorUser: false, // (process.env.NODE_ENV === 'development') && true,

  // 模拟用户信息
  simulatorUserInfo: {
    userId: 1,
    teamId: 1,
    name: '18680298387',
    userCode: '18680298387',
    teamCode: 'test001',
    phone: '18680298387',
    token: '12333333333',
    reftoken: '222222222222222222'
  },

  // 系统名称
  systemTitle: 'XBG-Coding',

  // 对oss的配置
  oss: {
    dev: {
      dataimport: 'shqc-carserver-files',
      images: 'shqc-carserver-image'
    },
    prod: {
      dataimport: 'shqc-carserver-files',
      images: 'shqc-carserver-image'
    }
  }

}
