
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import teamUserApi from '@/api/team-user'
import { Form } from 'view-design'

/**
 * 修改在团队内的名称对话框
 */
@Component({
  name: 'modiAtTeamName'
})
export default class ModiAtTeamNameModal extends Vue implements BaseModal {
    visiable=false

    loading=false

    rules={
      name: [
        { required: true, type: 'string', message: '名称不允许为空', trigger: 'blur' }
      ]
    }

    formData={
      name: ''
    }

    @Prop({
      type: Number,
      required: true
    })
    id!: number

    @Prop({
      type: String,
      required: true
    })
    name!: string

    title='修改成员名称'

    created () {
      this.formData.name = this.name
    }

    show (): void {
      this.visiable = true
    }

    close (): void {
      this.visiable = false
    }

    doSave (): void {
      const form: Form = this.$refs.form as Form
      form.validate((valid) => {
        this.loading = true
        if (!valid) {
          this.$Message.error('数据较验失败')
          return
        }
        teamUserApi.updateUserAtTeamName(this.id, this.formData.name)
          .then(() => {
            this.$Message.success('更改用户名称成功')
            this.$emit('onOk', this.formData.name)
          }).catch((err) => {
            this.$Message.error('更改用户名称失败:' + err.message)
          }).finally(() => {
            this.loading = false
          })
      })
    }

    doCancel (): void {
      this.$emit('onCancel')
    }
}
