import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import { FormComponentConfig } from '.'
import { HasPackageInfoEntity } from '../entitymodel/entity'
import EntityField from '../entitymodel/entity-field'

/**
 * 首字母大写
 * @param content
 * @returns
 */
function upperFirst (content: string): string {
  return content.slice(0, 1).toUpperCase() + content.slice(1)
}

/**
 * 首字母小写
 * @param content
 * @returns
 */
function lowerFirst (content: string): string {
  return content.slice(0, 1).toLowerCase() + content.slice(1)
}

/**
 * 驼峰转横杠
 * @param content
 * @returns
 */
function lowerMiddleline (content: string): string {
  const res = content.replace(/([A-Z])/g, '-$1').toLowerCase().replace('_', '-').trim()
  return res.startsWith('-') ? res.slice(1, res.length) : res
}

/**
 * 驼峰转下划线
 * @param content
 * @returns
 */
function lowerUnderline (content: string): string {
  const res = content.replace(/([A-Z])/g, '_$1').toLowerCase().replace('-', '_').trim()
  return res.startsWith('_') ? res.slice(1, res.length) : res
}

/**
 * 引入api
 * @param packageName
 * @param controllerName
 * @returns
 */
function importApi (controller: ControllerConfig): string {
  return `import ${lowerFirst(controller.className!)}Api from '@/api/${lowerMiddleline(controller.packageName!)}/${lowerMiddleline(controller.className!)}-api'`
}

/**
 * 引入实体
 * @param entityName
 * @returns
 */
function importEntity (entity: HasPackageInfoEntity): string {
  return `import ${upperFirst(entity.name)} from '@/xbgcoding/entity${lowerMiddleline(entity.fullName.replace(entity.basePackageName, '')).replace('.', '/')}/${lowerMiddleline(entity.name)}'`
}

/**
 * 读取包名
 * @param entity
 * @returns
 */
function getEntityName (entity: HasPackageInfoEntity): string {
  return upperFirst(entity.name)
}

/**
 * 读取api名称
 * @param controller
 * @returns
 */
function getControllerName (controller: ControllerConfig): string {
  return lowerFirst(controller.className!)
}

/**
 *
 * @param search
 * @returns
 */
function search (search: EntityField[]): string {
  let res = ''
  search.forEach(item => {
    res += `\n\t\t<i-input
        placeholder="${item.title}"
        clearable
        v-model="queryData.${item.name}"
      />`
  })
  return res
}

/**
 * 渲染列表vue文件
 */
function render (searchField: EntityField[], entity: HasPackageInfoEntity, controller: ControllerConfig) {
  return `<template>
  <div>
    <!-- 搜索条 -->
    <expend-search-panel
      @on-refresh="doPullDataList"
      @on-shrink-seachbar="shrinkSeachbar"
      :loading="loading"
    > ${search(searchField)}
      <template v-slot:dynamic> </template>
      <template v-slot:action> </template>
    </expend-search-panel>
    <!--表格-->
    <table-panel
      :tableConfig="tableConfig"
      :gridData="pageData"
      :loading="loading"
      @on-refresh="doPullDataList"
      @toolbarBtnClick="doToolBarButtonAction"
      ref="tablePanel"
    >
      <template v-slot:toolbar_buttons>
        <vxe-button
          status="primary"
          size="mini"
          @click="openFormPage('${lowerUnderline(getEntityName(entity))}-page', undefined, 'new')"
        >
          新增
        </vxe-button>
      </template>
      <vxe-table-column title="操作" slot="col_action">
        <template v-slot="{ row }">
          <vxe-button
            type="text"
            icon="iconfont iconviewdoc icon_color_normal"
            size="16"
            @click="openFormPage('${lowerUnderline(getEntityName(entity))}-page', row.id)"
          />
          <vxe-button
            type="text"
            icon="iconfont iconclose_red icon_color_warning"
            size="16"
            @click="deleteItemsById([row.id])"
          />
        </template>
      </vxe-table-column>
    </table-panel>
  </div>
</template>

<script lang="ts">
import { Component, Ref } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import BaseTablePanel from '@/frame/share/table-panel'
import {
  PagerQueryBean,
  PageListData,
  PageDataApiResult
} from '@/libs/http-request'
import { PageInfo } from '@/libs/api-request'
import _ from 'lodash'
${importApi(controller)}
import tableConfig from './table.config'
${importEntity(entity)}

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: []
}

/**
 * ${entity.title}列表
 */
@Component({
  name: '${getEntityName(entity)}DataList',
  components: { ExpendSearchPanel, TablePanel }
})
export default class extends BaseTablePanel<${getEntityName(entity)}> {
  /** 当前加载状态 */
  loading = false
  /** 表格定义 */
  tableConfig = tableConfig
  /** 查询数据 */
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)
  /** 表格面板对象 */
  @Ref()
  readonly tablePanel!: TablePanel

  /**
   * 页面数据
   */
  pageData: PageListData<${getEntityName(entity)}> = {
    items: [],
    total: 0,
    pageSize: 10
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  protected doPullDataList (pageInfo: PageInfo = { curPage: 1, pageSize: 10 }) {
    this.pullDataList(pageInfo).then(response => {
      this.pageData = response
      this.tablePanel.setCurPage(pageInfo.curPage!)
    })
  }

  /**
   * 工具栏按钮事件
   */
  protected doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, this.tablePanel.getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (
    pagerQueryBean: PagerQueryBean
  ): Promise<PageDataApiResult<${getEntityName(entity)}>> {
    return ${getControllerName(controller)}Api.query(pagerQueryBean)
  }

  protected deleteItemsById (ids:number[]) {
    this.$Modal.confirm({
      title: '是否删除？',
      content: '是否删除该参数？',
      onOk: () => {
        ${getControllerName(controller)}Api.deleteItemByIds(ids)
          .catch(err => {
            this.$Notice.error({ title: '数据删除失败', desc: err })
          }).finally(this.doPullDataList)
      }
    })
  }
}
</script>
<style lang="less" scoped>
@import '~@/style/index.less';
</style>
`
}

export default function (entity: HasPackageInfoEntity, controller: ControllerConfig, options: FormComponentConfig): string {
  // 目前暂时默认前两个字段提供搜索
  const searchFields = options.searchFields

  return render(searchFields, entity, controller)
}
