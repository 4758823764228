
import SubMenu from '@/frame/main/sub-menu.vue'
import { Vue, Component } from 'vue-property-decorator'
import { ModuleItem } from '@/libs/sys-modules'
import { dispatchUserProjectModules } from '@/store'

@Component({
  name: 'projectMenu',
  components: { SubMenu }
})
export default class ProjectMenu extends Vue {
  menus: Array<ModuleItem>=[]

  /// 创建时强制拉取项目菜单
  created () {
    this.$Loading.start()
    dispatchUserProjectModules(1, true)
      .then((items) => {
        this.menus = items
        this.$nextTick(() => {
          (this.$refs.SubMenu as any).updateMenu()
        })
      }).catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: '拉取项目菜单失败:' + err.message
        })
      }).finally(() => {
        this.$Loading.finish()
      })
  }
}
