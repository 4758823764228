
import { Prop, Vue, Component } from 'vue-property-decorator'
import Treeselect, { LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import OrgInfo from '@/libs/entitymodel/entity-orginfo'
import Entity from '@/libs/entitymodel/entity'
import { getterEntityModelVersion, getterProjectId } from '@/store'
import webApiEntityModelApi from '@/api/webapi-entity-model/webapi-entity-model-api'

export interface TreeNode {
  /// 节点id
  id: string;
  /// 子标题
  label: string;
  /// 是否可以选择
  isDisabled: boolean;
  /// 子节点
  children?: TreeNode[] | null;
  /// 原始数据
  raw?: any;
}
/**
 * 设置实体父亲实体对话框
 */
@Component({
  name: 'EntitySelector',
  components: { Treeselect }
})
export default class extends Vue {
  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly value!: string

  @Prop({
    type: String,
    default: '请选择关联实体',
    required: false
  })
  // 默认显示的label
  readonly label!: string

  // 组织模型数据
  modelInfoData: OrgInfo[] = []
  // 树列表
  options: any = null
  // 当前工程id
  projectId = -1
  // 当前版本uuid
  versionUuid = ''

  onSelect (entity: TreeNode) {
    this.$emit('input', entity.id)
    this.$emit('on-select', { ...entity, label: entity.label.replace(/\(.*$/, '') })
  }

  onDeselect () {
    console.log('deselect')

    this.$emit('input', undefined)
    this.$emit('on-select', undefined)
  }

  /**
   * 获取一个组织节点的实体
   */
  getOrgEntity (orgUuid: string): Promise<Record<string, Entity>> {
    return new Promise((resolve, reject) => {
      webApiEntityModelApi.hasModelData(this.projectId, this.versionUuid, orgUuid).then(res => {
        if (res.data) {
          return webApiEntityModelApi.getModelData(this.projectId, this.versionUuid, orgUuid)
        } else {
          resolve({})
        }
      }).then(res => {
        resolve(res?.entities!)
      }).catch(err => {
        this.$Notice.error({
          title: '获取实体失败',
          desc: err
        })
        reject(err)
      })
    })
  }

  /**
   * 获取组织模型信息
   */
  loadOrgInfoData () {
    webApiEntityModelApi.getModelOrgInfoList(this.projectId, this.versionUuid).then(res => {
      this.modelInfoData = res.data!
    }).catch(err => {
      this.$Notice.error({
        title: '加载组织模型失败',
        desc: err
      })
    })
  }

  /**
   * 将组织节点转换为树节点
   */
  orgToTreeNode (orgData: OrgInfo): TreeNode {
    return {
      id: orgData.uuid,
      label: orgData.title,
      isDisabled: false,
      children: null
    }
  }

  /**
   * 将实体转换为树节点
   */
  entityToTreeNode (entity: Entity): TreeNode {
    return {
      id: entity.uuid,
      label: `${entity.name}(${entity.title})`,
      isDisabled: false,
      raw: { ...entity, fullName: this.getEntityFullNameByEntity(entity), basePackageName: this.modelInfoData[0].name }
    }
  }

  /**
   * 根据组织uuid查找组织信息
   */
  getOrgInfoByUuid (uuid: string): OrgInfo | undefined {
    const index = this.modelInfoData.map(e => e.uuid).indexOf(uuid)
    return index >= 0 ? this.modelInfoData[index] : undefined
  }

  /**
   * 根据父组织节点uuid查找子节点uuid列表
   */
  getChildrenUuidListByParentUuid (uuid: string): string[] {
    const parentNodeInfo = this.getOrgInfoByUuid(uuid)
    return parentNodeInfo?.children?.map(e => e.uuid) ?? []
  }

  /**
   * 根据父组织节点uuid查找子节点树列表
   */
  getChildrenTreeNodesByParentUuid (uuid: string) {
    const childrenUuids = this.getChildrenUuidListByParentUuid(uuid)
    return childrenUuids.map((childrenUuid: string) => {
      const childrenOrgInfo = this.getOrgInfoByUuid(childrenUuid)
      return this.orgToTreeNode(childrenOrgInfo!)
    })
  }

  /**
   * 通过组织uuid获取一个带有实体的树节点
   */
  getTreeNodesByOrgUuid (uuid: string): Promise<TreeNode[]> {
    return new Promise<TreeNode[]>((resolve, reject) => {
      const orgInfo = this.getOrgInfoByUuid(uuid)
      if (!orgInfo) { resolve([]) }
      this.getOrgEntity(uuid).then((res) => {
        // const treeNode = this.orgToTreeNode(orgInfo!)
        const children = [...this.getChildrenTreeNodesByParentUuid(uuid)]
        for (const entity in res) {
          children.push(this.entityToTreeNode(res[entity]))
        }
        resolve(children)
      }).catch(err => { reject(err) })
    })
  }

  /**
 * 查找实体的全名
 */
  getEntityFullNameByEntity (entity: Entity): string {
    const r = (org: OrgInfo): string | undefined => {
      if (org.uuid === entity.orgId) {
        return org.name
      }
      for (const index in org.children) {
        const res = r(org.children[index])
        if (res) {
          return `${org.name}.${res}`
        }
      }
    }
    for (const index in this.modelInfoData) {
      const res = r(this.modelInfoData[index])
      if (res) {
        return res
      }
    }
    return ''
  }

  /**
   * 异步加载
   */
  async loadOptions ({ action, parentNode, callback }: Record<string, any>) {
    try {
      if (action === LOAD_ROOT_OPTIONS) {
        if (this.modelInfoData[0]) {
          const rootTreeNode = this.orgToTreeNode(this.modelInfoData[0])
          rootTreeNode.children = await this.getTreeNodesByOrgUuid(this.modelInfoData[0].uuid)
          this.options = [rootTreeNode]
        }
        callback()
      } else if (action === LOAD_CHILDREN_OPTIONS) {
        parentNode.children = await this.getTreeNodesByOrgUuid(parentNode.id)
        callback()
      }
    } catch (err) {
      callback(err)
    }
  }

  created () {
    this.projectId = getterProjectId()!
    this.versionUuid = getterEntityModelVersion()?.uuid!
    this.loadOrgInfoData()
  }
}
