/**
 * 对axios进行封装，提供系统的http请求的常用方法
 */

import i18n from '@/locales/i18n'
import { getterUserToken, dispatchClearToken } from '@/store'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosTransformer } from 'axios'
import zlib from 'zlib'

/**
 * api请求错误代码
 */
export interface RequestApiError{
    [code: number]: string;
  }

/**
   * 请求api错误定义
   */
export const requestApiError: RequestApiError = {
  401: i18n.t('apiRequest.errorMessage.no_right') as string,
  402: i18n.t('apiRequest.errorMessage.no_right') as string,
  404: i18n.t('apiRequest.errorMessage.not_found') as string,
  500: i18n.t('apiRequest.errorMessage.unknow') as string
}

/// 未知错误信息
const unkonwMessage = i18n.t('apiRequest.errorMessage.unknow') as string

/**
 * api结果返回码
 */
export enum ApiResultCode{
    /// 成功
    Sucess=0,
    /// 没有权限
    NotPermission=401,
    /// 未知错误
    NotUnknowError=500
}
/**
 * api返回结果对象
 */
export interface ApiResult{
    /**
     * 返回码，如果为0表示成功
     */
    code: number;
    /**
     * 返回的错误信息
     */
    message: string;
}

/**
 * 带有数据结果的api返回结果对象
 */
export interface DataApiResult<T> extends ApiResult{
    /**
     * 返回的数据
     */
    data?: T;
}

/**
 * 分页结果集
 */
export interface PageListData<T> {
    /**
     * 返回页的数据列表
     */
    items: Array<T>;
    /// 满足条件的记录总数
    total: number;
    /// 每页的大小
    pageSize: number;
}

/**
 * 分页结果对象
 */
export type PageDataApiResult<T> = DataApiResult<PageListData<T>>

/**
 * 分页查询bean
 */
export interface PagerQueryBean{
  /// 查询的页索引
  page: number;
  /// 每页的大小
  pageSize: number;
  /// 排序条件 例如 ['+time','-name']
  sorts: Array<string>;
  /// 其它查询条件
  [prop: string]: any;
}

/**
 * api分页结果对象
 */
export class DataApiPageResult<T> implements DataApiResult<PageListData<T>> {
    /// 数据项
    data?: PageListData<T> | undefined;
    /// 返回结果码
    code: number;
    /// 返回消息
    message: string;

    /**
     * 构造方法
     * @param response  api返回结果
     */
    constructor (response: DataApiResult<PageListData<T>>) {
      this.code = response.code
      this.message = response.message
      this.data = response.data
    }

    /**
     * 结果的总页数
     */
    get totalPage () {
      if (!this.data) return 0
      let totalPage = this.data.total / this.data.pageSize
      if ((this.data.total % this.data.pageSize) !== 0) totalPage++
      return totalPage
    }
}

/**
 * 检查当前请求是否成功
 * @param code
 */
export function IsSucceedRequest (code: number) {
  return code === ApiResultCode.Sucess
}

/**
 * 检查返回结果是否正确，不正确返回错误消息
 * @param response 要检查的网络请求对象
 */
export function checkRequestIsSuccess (response: AxiosResponse<any>): string|undefined {
  // 返回结果不是200
  // if (response.status !== 200) {
  if (Math.floor((response.status / 100)) !== 2) {
    const message = requestApiError[response.status]
    if (message) {
      return message
    }
    return unkonwMessage
  }

  // 没有错误
  return undefined
}

/**
 * 请求原始结果
 * @param req
 * @param useToken
 * @param useZip
 * @returns
 */
export function requestRawResponse<T> (req: AxiosRequestConfig, useToken = true, useZip = false) {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    const reqData = req

    if (!req.url) {
      reject(new Error('错误的请求地址,请求地址url不允许为空'))
      return
    }
    let url = req.url
    url = (url.startsWith('/') || url.startsWith('http')) ? url : '/' + url

    // 添加运行时地址前缀
    if ((!url.startsWith('http')) && process.env.VUE_APP_URL_PREFIX) {
      url = `/${process.env.VUE_APP_URL_PREFIX}${url}`
    }

    reqData.url = url

    if (useZip) {
      /// 如果使用压缩，使用zlib压缩流
      reqData.transformRequest = [
        (axios.defaults.transformRequest as AxiosTransformer[])[0],
        (data: any, header: any) => {
          header['Content-Encoding'] = 'gzip'

          const re = zlib.gzipSync(Buffer.from(data))

          return re
        }
      ]
    }

    // 在请求中添加token信息
    const token = getterUserToken()

    /// 添加当前用户token
    if (token && useToken) {
      if (!req.headers) req.headers = {}
      req.headers.authorization = 'Bearer ' + token
    }

    axios.request(reqData)
      .then(response => {
        resolve(response)
      }).catch((err) => {
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          // 如果是因为没有权限，则清除当前用户的token
          dispatchClearToken()
        }

        reject(err)
      })
  })
}

/**
 * 原始数据请求方法
 * @param req
 * @param useToken
 * @param useZip
 */
export function requestRaw<T> (req: AxiosRequestConfig, useToken = true, useZip = false) {
  return new Promise<T>((resolve, reject) => {
    requestRawResponse<any>(req, useToken, useZip)
      .then((response) => {
        const errorMessage = checkRequestIsSuccess(response)
        if (errorMessage) reject(new Error(errorMessage))
        resolve(response.data)
      }).catch((err) => reject(err))
  })
}

/**
 * 请求apiresult
 * @param req
 * @param useToken
 * @param useZip
 */
export function requestApiResult (req: AxiosRequestConfig, useToken = true, useZip = false) {
  return new Promise<ApiResult>((resolve, reject) => {
    requestRawResponse<ApiResult>(req, useToken, useZip)
      .then((response) => {
        const errorMessage = checkRequestIsSuccess(response)
        if (errorMessage) reject(new Error(errorMessage))
        if (response.data.code !== 0) {
          reject(new Error(response.data.message))
          return
        }
        resolve(response.data)
      }).catch((err) => reject(err))
  })
}

/**
 * 请求api并异步返回结果
 * @param req 请求数据
 * @param useToken 是否使用token,默认是使用
 */
export default function request<T extends DataApiResult<unknown>> (req: AxiosRequestConfig, useToken = true, useZip = false) {
  return new Promise<T>((resolve, reject) => {
    requestRaw<T>(req, useToken, useZip)
      .then((response) => {
        if (!IsSucceedRequest(response.code)) {
          const message = response.message ? response.message : unkonwMessage
          reject(new Error('服务器返回错误:' + message))
        }
        resolve(response)
      })
      .catch((err: Error) => {
        reject(err)
      })
  })
}
