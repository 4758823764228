
import { showModal } from '@/libs/modal-helper'
import modal from './modi-atteam-name-modal.vue'

/**
  * 显示创建团队的弹出对话框
  * @param userId
  * @param createMode 为真时是创建模式,否则是加入模式
  * @returns
  */
export default function showModiAtTeamNameModal (id: number, name: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    showModal<string>(modal, {
      props: {
        id: id,
        name: name
      }
    }, true, (newName) => {
      resolve(newName)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
