import DefaultApiService from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'

/**
 * 工程相关基础信息
 */
export interface BaseProjectInfo{
    /// id
    id?: number;
    /// 项目组id
    projectGroupId?: number;
    /// 工程名称
    name?: string;
    /// 工程标题
    title?: string;
    /// 工程图标
    projectLogoUrl?: string;
    /// 工程类型
    type?: number;
    /// 项目对应的git仓库的url地址
    gitUrl?: string;
    /// 项目对应的git仓库的用户
    gitUser?: string;
    /// 项目对应的git仓库的pwd
    gitPwd?: string;
    // 项目参数
    options?: string;
    /// 工程支持的语言
    languages?: string[];
}

export interface WebApiProjectInfo extends BaseProjectInfo{
    /// 工程的组织
    org: string;
}

/**
 *
 */
class ProjectInfoApi extends DefaultApiService<BaseProjectInfo> {
  /**
   * 重写返回工程信息方法
   * @param id
   */
  public getItemById (id: number): Promise<DataApiResult<BaseProjectInfo>> {
    /// 如果是模拟数据 返回本地的json
    /* if (process.env.VUE_APP_ProjectLocal) {
      return new Promise<DataApiResult<BaseProjectInfo>>((resolve, reject) => {
        axios.get('/test-data/project/project.json')
          .then((response) => {
            resolve({
              code: 0,
              data: response.data,
              message: ''
            })
          })
          .catch((err: Error) => reject(err))
      })
    } else {
      return super.getItemById(id)
    } */
    return super.getItemById(id)
  }
}

export default new ProjectInfoApi('/api/project')
