/**
 * uuid工具
 */
import shortUuid from 'short-uuid'

/**
 * 取得一个短的uuid值
 */
export function getShortUuid (): string {
  return shortUuid.generate()
}

export default function newGuid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}
