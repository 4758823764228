import axios from 'axios'
import { expect } from 'chai'
import _ from 'lodash'
import securityService, { UserInfo } from '@/api/security'

/**
 * 用户信息
 */
export interface BaseUserInfo {
  /// 用户名称
  name: string;
  /// 用户id
  id: number;
  /// 用户图标
  iconUrl?: string;
}

/**
 * 检查用户是否拥有指定的权限
 * @param right 权限字串，以 模板.权限名为格式
 */
function _userHashRight (right: string): boolean {
  // const arr = right.split('.')
  // expect(arr, `权限字串不合法:${right}`).has.length(2)
  // const module = arr[0]
  // const name = arr[1]

  // const m = getterUserRights()[module]
  // if (!m) return false

  // return m.rights.some((item) => item.name === name)
  return true
}

/**
 * 检查用户是否有指定权限中的任一权限
 * @param rights 要检查的权限列表，多个权限项以,号分隔
 */
export function userHasRight (rights?: string): boolean {
  // if (!rights) return true
  // const rightArray = rights.trim().split(',')
  // for (const right of rightArray) {
  //   if (_userHashRight(right)) return true
  // }
  // return false
  return true
}

/**
 * 权限项
 */
export interface RightItem {
  /// 权限项名称 ，在同一模块下，权限项名称要求唯一
  name: string;
  /// 权限项标题
  title: string;
  /// 权限项的code值 在整个系统中，这个值要求是唯一
  code: number;
}

/**
 * 模块权限信息
 */
export interface ModuleRightInfo {
  /// 模块名称 ，在同整个系统中，模块名要求唯一
  name: string;
  /// 模块标题
  title: string;
  /// 模块的code值 在整个系统中，这个值要求是唯一
  code: string;
  /// 模块对应的权限项列表
  rights: RightItem[];
}

/**
 * 用户权限工具
 */
class UserRights {
  /**
   * 从服务器取得当前用户的信息
   */
  getUserInfoFromServer (): Promise<UserInfo> {
    const pm: Promise<UserInfo> = this.getServerUserInfo()
    // process.env.VUE_APP_UserLocal === 'true' ? this.getLocalUserInfo() : this.getServerUserInfo()
    return pm
  }

  private getServerUserInfo (): Promise<UserInfo> {
    return new Promise<UserInfo>((resolve, reject) => {
      securityService.getUserInfo()
        .then((response) => {
          resolve(response.data!)
        })
        .catch((err) => {
          reject(new Error('从服务器拉取用户信息失败:' + err.message))
        })
    })
  }

  /**
   * 取得本地用户信息
   */
  // private getLocalUserInfo (): Promise<UserInfo> {
  //   return new Promise<UserInfo>((resolve, reject) => {
  //     axios.get('/test-data/userinfo.json')
  //       .then((response) => {
  //         if (response.status !== 200) {
  //           reject(new Error(`访问本地模拟用户信息失败,status=${response.status}`))
  //         } else {
  //           resolve(response.data)
  //         }
  //       }).catch((err: Error) => reject(err))
  //   })
  // }

  /**
   * 从服务器下载当前用户拥有权限的模块以及权限信息
   */
  // getModuleRightInfoFromServer (): Promise<ModuleRightInfo[]> {
  //   const pm: Promise<ModuleRightInfo[]> = process.env.VUE_APP_UserLocalRightData
  //     ? this.getLocalModuleRightInfos() : this.getLocalModuleRightInfos()
  //   return new Promise<ModuleRightInfo[]>((resolve, reject) => {
  //     pm.then((data) => {
  //       // 检查数据是否合法
  //       const dc = _.groupBy(data, (item) => item.name)

  //       /// 检查有没有同名的模块
  //       for (const key in dc) {
  //         if (dc[key].length > 1) {
  //           reject(new Error(`名为(${key})的模块出现了${dc[key].length}个`))
  //           return
  //         }
  //       }

  //       /// 检查有没有同name模块
  //       data.forEach((module) => {
  //         const dc = _.groupBy(module.rights, (item) => item.name)
  //         for (const name in dc) {
  //           if (dc[name].length > 1) {
  //             reject(new Error(`模块${module.name}权限项name=${name}配置错误，有${dc[name].length}个同名的模块`))
  //             return
  //           }
  //         }
  //       })

  //       resolve(data)
  //     }).catch((err: Error) => {
  //       reject(new Error(`取得当前用户权限模块清单失败:${err.message}`))
  //     })
  //   })
  // }

  /**
   * 取得本地的模拟权限数据
   */
  // private getLocalModuleRightInfos (): Promise<ModuleRightInfo[]> {
  //   return this.getLocalModulePaths()
  //     .then((list) => {
  //       const res = list.filter((path) => path.trim().length > 0)
  //         .map((path) => this.getLocalModuleRightByPath(path))
  //       return Promise.all(res)
  //     })
  // }

  // /**
  //  * 取得本地的模拟权限数据列表
  //  */
  // private getLocalModulePaths (): Promise<string[]> {
  //   return new Promise<string[]>((resolve, reject) => {
  //     axios.get('/test-data/module-rightlist.json')
  //       .then((response) => {
  //         if (response.status !== 200) {
  //           reject(new Error(`取得本地模块权限数据出错:${response.status}`))
  //         } else {
  //           resolve(response.data)
  //         }
  //       }).catch((err: Error) => {
  //         reject(new Error(`取得本地模块权限数据出错:${err.message}`))
  //       })
  //   })
  // }

  /**
   * 取得指定地址的模拟数据
   * @param path 数据
   */
  private getLocalModuleRightByPath (path: string) {
    return new Promise<ModuleRightInfo>((resolve, reject) => {
      axios.get(path)
        .then((response) => {
          if (response.status !== 200) {
            reject(new Error(`取得模块${path}权限数据出错:${response.status}`))
          } else {
            resolve(response.data)
          }
        }).catch((err: Error) => {
          reject(new Error(`取得模块${path}权限数据出错:${err.message}`))
        })
    })
  }
}

export default new UserRights()
