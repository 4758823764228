
import { Vue, Component, Watch, PropSync } from 'vue-property-decorator'
import { dispatchUserModules, getterUserInfo, dispatchHandleLogOut, getterCurrentMenuModuleCode, getterUserModules, dispathUpdateCurMenuItemCode } from '@/store'
import { getMenuItemByFullCode, getMenuItemListByCode, getMoudleListByPath, ModuleItem } from '@/libs/sys-modules'
import showModiPwdModal from '@/views/modipwd/modipwd-modal'
import showModiAtTeamNameModal from '@/views/team/team-members/modi-atteam-name-modal'
import { isEmpty } from 'lodash'
import storage from '@/libs/local-storage'

@Component({
  name: 'mainMenu'
})
export default class MainMenu extends Vue {
  /** 当前选中菜单逻辑 */
  @PropSync('actionModule', { type: Object, default: null })
  curActionModule!: ModuleItem | null

  /// 操作按钮列表
  actionItems: Array<ModuleItem> = [];

  // 注销
  loginout () {
    dispatchHandleLogOut()
      .then(() => {
        this.$Message.info('注销成功')
      }).catch((err) => {
        this.$Message.error('注销失败:' + err.message)
      }).finally(() => {
        this.$router.push('/login')
      })
  }

  onUserMenuClick (name: string) {
    if (name === 'loginout') {
      this.loginout()
    }
    if (name === 'modipwd') {
      showModiPwdModal()
    }
    if (name === 'modiname') {
      showModiAtTeamNameModal(this.userInfo.userId, this.userInfo.nameAtTeam)
        .then((name) => {
          this.userInfo.nameAtTeam = name
        })
    }
  }

  /// 用户信息
  userInfo=getterUserInfo()

  /// 用户名
  get userName () {
    const userName = this.userInfo.nameAtTeam
    if (userName) {
      return userName.length >= 2 ? userName.substring(0, 1) : userName
    }
    return ''
  }

  // 拉取当前的模块列表
  created () {
    dispatchUserModules(true)
      .then((menus) => {
        this.actionItems = menus.filter(item => item.showOnMenu).map((item) => {
          return {
            ...item,
            icon: require('@/assets/icon/frame/main-menu/' + item.icon)
          }
        })
      })

    const clearup = this.$store.subscribe((mutation) => {
      if (this.curActionModule) {
        clearup?.()
        return
      }
      const { payload, type } = mutation
      if (type === 'module/setMoudles') {
        const fullcode = storage.loadCurMenuItemCode()
        if (!fullcode && !this.curActionModule && payload[0]) {
          // 设置默认菜单
          this.curActionModule = payload[0]
           clearup?.()
           return
        } else if (!fullcode) {
           clearup?.()
           return
        }

        const moduleList = getMenuItemByFullCode(payload, fullcode)
        if (moduleList) {
          const curModule = moduleList[1]
          const curRootModule = moduleList[0]
          if (curModule) {
            this.curActionModule = curRootModule
            dispathUpdateCurMenuItemCode(curModule.fullCode)
            clearup?.()
          }
        }
      }
    })
  }

  /**
   * 根据路由判断更新当前菜单
   */
  @Watch('$route.path', { immediate: true })
  watchRoute () {
    const modules: ModuleItem[] = []
    const curModuleCode = getterCurrentMenuModuleCode()
    if (getMoudleListByPath(getterUserModules(), this.$route.path, modules)) {
      if (curModuleCode !== modules[1].code) {
        if (!curModuleCode?.startsWith(modules[0].code)) {
          const newActionItem = this.actionItems.find((e) => e.code === modules[0].code)
          if (newActionItem) {
            this.curActionModule = newActionItem
          }
        }
        // 修改当前选择的菜单的fullcode ，也就是当前菜单的name
        dispathUpdateCurMenuItemCode(modules[1].fullCode)
      }
    }
  }

  /**
   * 动态记录操作按钮的效果
   */
  getActionCss (item: ModuleItem) {
    return item.code === this.curActionModule?.code
      ? 'action-button action-button-selected'
      : 'action-button'
  }
}
