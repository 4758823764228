
import { Prop, Vue, Component, Model, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import enumerationConfigApi from '@/api/project/enummanager/enum-config/enum-config-api'
import { getterEntityModelVersion, getterProjectId } from '@/store'

// 选择组件
@Component({ name: 'EnumSelector', components: {}, mixins: [] })
export default class extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue: string|number = ''

  @Prop({
    type: String,
    default: '请输入枚举名称'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  // 下拉菜单列表
  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  /* onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  } */

  getRawData (id: number) {
    const index = this.datas.map(e => e.id).indexOf(id)
    return ~index ? this.datas[index] : undefined
  }

  onSelect (newValue: any) {
    const selectItem = this.getRawData(newValue.value)
    if (!selectItem) { return }

    this.$emit('input', selectItem.uuid)
    this.curValue = selectItem.id
    this.$emit('on-select', selectItem)
  }

  initData () {
    this.$emit('input', undefined)
    this.curValue = ''
  }

  @Watch('value', { immediate: true })
  onValueChange (newValue: string) {
    this.loadDataFromServerByUuid(newValue)
  }

  // 加载下拉框选项
  loadDataFromServer (query?: string) {
    _.debounce((query?: string) => {
      this.loading = true
      enumerationConfigApi.query({
        page: 1,
        pageSize: 10,
        sorts: ['-name'],
        projectId: getterProjectId(),
        version: getterEntityModelVersion()?.id,
        name: query
      }).then((res) => {
        this.datas = res.data?.items ?? []
      }).catch(err => {
        this.$Notice.error({
          title: '获取枚举失败',
          desc: err
        })
      }).finally(() => {
        this.loading = false
      })
    }, 300)(query || '')
  }

  // 通过uuid加载下拉框选项
  loadDataFromServerByUuid (uuid: string) {
    this.loading = true
    enumerationConfigApi.query({
      page: 1,
      pageSize: 10,
      sorts: ['-name'],
      projectId: getterProjectId(),
      version: getterEntityModelVersion()?.id,
      uuid
    }).then(res => {
      this.datas = res.data?.items || []
      this.curValue = res.data?.items?.[0].id || ''
    }).catch((err) => {
      this.$Notice.error({
        title: '获取枚举失败',
        desc: err
      })
    }).finally(() => {
      this.loading = false
    })
  }
}
