
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'

// 选择组件
@Component({ name: 'RequestMethodSelector', components: {}, mixins: [] })
export default class RequestMethodSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  @Watch('value')
  onvaluechange (newValue: number) {
    this.curValue = newValue
  }

  curValue = -1

  @Prop({
    type: String,
    default: '请求方法'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData () {
    this.$emit('input', undefined)
    this.curValue = -1
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.datas = [
      {
        id: 0,
        value: 0,
        label: 'GET'
      },
      {
        id: 1,
        value: 1,
        label: 'POST'
      },
      {
        id: 2,
        value: 2,
        label: 'PUT'
      },
      {
        id: 3,
        value: 3,
        label: 'DELETE'
      }]
  }
}
