import ProjectFrame from '@/frame/project/project-layout.vue'
import ApiManager from '@/views/project/api-manager/api-manager.vue'
import EnumManager from '@/views/project/enum-manager/enum-manager.vue'
import { RouteConfig } from 'vue-router'

const routers: Array<RouteConfig> = [
  {
    name: 'project-webapi',
    component: ProjectFrame,
    path: '/project-webapi',
    redirect: '/project-webapi/base-info-page',
    children: [
      {
        name: 'entity-builder-system',
        path: 'entity-builder-system',
        component: () => import('@/views/project/webapi/entity-builder-system/index.vue'),
        meta: {
          title: '实体构建系统'
        }
      },
      {
        name: 'base-info-page',
        path: 'base-info-page',
        component: () => import('@/views/project/base-info/data-page/base-info-page.vue'),
        meta: {
          title: '项目基础信息'
        }
      },
      {
        name: 'project-member',
        path: 'project-member',
        component: () => import('@/views/project/webapi/project-member/index.vue'),
        meta: {
          title: '项目成员'
        }
      },
      {
        name: 'api-manager',
        path: 'api-manager',
        component: ApiManager,
        meta: {
          title: 'API管理'
        },
        children: [
          {
            name: 'controller-view',
            path: 'controller-view',
            component: () => import('@/views/project/api-manager/controller-view/controller-view.vue'),
            props: true,
            meta: {
              title: 'Controller信息'
            }
          },
          {
            name: 'api-editor',
            path: 'api-editor',
            component: () => import('@/views/project/api-manager/api-editor/api-editor.vue'),
            props: true,
            meta: {
              title: 'API编辑'
            }
          }
        ]
      },
      {
        name: 'enum-manager',
        path: 'enum-manager',
        component: EnumManager,
        meta: {
          title: '枚举管理'
        },
        children: [
          {
            name: 'enumeration-view',
            path: 'enumeration-view',
            component: () => import('@/views/project/enum-manager/enumeration-view/enumeration-view.vue'),
            props: true,
            meta: {
              title: 'Enumeration信息'
            }
          }
          /* {
            name: 'enum-editor',
            path: 'enum-editor',
            component: () => import('@/views/project/enum-manager/enum-editor/enum-editor.vue'),
            props: true,
            meta: {
              title: '枚举编辑'
            }
          } */
        ]
      },
      {
        name: 'code-generate',
        path: 'code-generate',
        component: () => import('@/views/project/code-generate/index.vue'),
        meta: {
          title: '代码生成'
        }
      }
    ]
  }
]

export default routers
