import Security, { UserInfo } from '@/api/security'
import config from '@/config/'
import Token from '@/libs/token'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import localStorage from '@/libs/local-storage'
import userRights from '@/libs/user-rights'
import moment from 'moment'

/**
 * 用户信息的store类
 */
interface UserStore {
  token?: string;
  reftoken?: string;
  /// 用户信息
  userInfo?: UserInfo;
  /// token有效期最后时间
  invalidTime?: Date;
}

class UserStoreModule implements Module<UserStore, any> {
  namespaced = true // 限制作用域

  // 存贮的数据
  state: UserStore = {
    token: config.simulatorUser ? config.simulatorUserInfo.token : Token.getToken(),
    reftoken: config.simulatorUser ? config.simulatorUserInfo.reftoken : Token.getRefreshToken(),
    userInfo: localStorage.loadCurUserInfo(),
    invalidTime: Token.loadInvalidTime()
  }

  // 可执行修改
  mutations: MutationTree<UserStore> = {

    setToken (state, token) {
      state.token = token
      Token.setToken(token)
    },
    setRefreshToken (state, token) {
      state.reftoken = token
      Token.setRefreshToken(token)
    },
    setInvalidTime (state, time) {
      state.invalidTime = time
      Token.setInvalidTime(time)
    },
    /**
     * 设置用户信息
     * @param state
     * @param userInfo
    */
    setUserInfo (state, userInfo?: UserInfo) {
      state.userInfo = userInfo
      localStorage.saveCurUserInfo(userInfo)
    }
  }

  // 操作列表
  actions: ActionTree<UserStore, any> = {

    // 更新token信息
    updateToken ({ commit }, { token, freshToken }) {
      if (token && token.trim().length > 0) {
        commit('setToken', token)
      }
      if (freshToken && freshToken.trim().length > 0) {
        commit('setRefreshToken', freshToken)
      }
    },

    // 登录用户
    handleLogin ({ commit }, { userName, password, teamCode, loginKey, validCode }) {
      return new Promise<void>((resolve, reject) => {
        Security.loginByPwd(userName, password, teamCode, loginKey, validCode)
          .then(res => {
            console.log('收到验证回复:%o', res)
            commit('setToken', res.access_token)
            commit('setRefreshToken', res.refresh_token)

            const vtime = moment().add(res.expires_in, 'seconds').toDate()
            commit('setInvalidTime', vtime)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // 通过osskey登入
    handleLoginByOss ({ commit }, ossKey) {
      return new Promise<void>((resolve, reject) => {
        Security.loginByOssKey(ossKey)
          .then(res => {
            console.log('收到验证回复:%o', res)
            commit('setToken', res.access_token)
            commit('setRefreshToken', res.refresh_token)

            const vtime = moment().add(res.expires_in, 'seconds').toDate()
            commit('setInvalidTime', vtime)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // 用户注销
    // 退出登录
    handleLogOut ({ state, commit }) {
      const curstate = state as UserStore

      return new Promise<void>((resolve, reject) => {
        Security.logout(curstate.token as string, curstate.reftoken as string).then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
        commit('setUserInfo', {
          nickName: '',
          nameAtTeam: '',
          userCode: '',
          userId: 0,
          phone: '',
          activedTeamId: 0,
          teamName: '',
          teamCode: '',
          iconUrl: '',
          canAddUser: false,
          canCreateProject: false,
          canDeleteProject: false,
          canLockProjectGroup: false,
          canCreateProjectGroup: false,
          canDeleteProjectGroup: false,
          canOperatePcServer: false,
          canOperateApplication: false,
          canOperateCloudBaseResource: false,
          canOperateImageResource: false,
          canOperateRegisterCenter: false,
          canOperateApiGateway: false,
          canOperateResource: false
        })
        commit('setToken', '')
        commit('setTeamCode', undefined)
        commit('setUserId', undefined)
        commit('setRefreshToken', undefined)
      })
    },

    // 刷新token
    refreshToken ({ state, commit }) {
      if (!state.reftoken || !state.invalidTime) {
        console.log('当前用户refreshtoken为空，跳过本次token刷新')
        return
      }

      const now = new Date()
      if ((state.invalidTime!.getTime() - now.getTime()) > 60000) {
        // console.log('当前token没有到时间，跳过本次token刷新')
        return
      }

      Security.refreshToken(state.reftoken, state.userInfo!.teamCode!)
        .then((response) => {
          commit('setToken', response.access_token)
          commit('setRefreshToken', response.refresh_token)

          const vtime = moment().add(response.expires_in, 'seconds').toDate()
          commit('setInvalidTime', vtime)
        }).catch((err) => {
          console.error('更新token失败:' + err.message)
          commit('setToken', undefined)
          commit('setRefreshToken', undefined)
          commit('setInvalidTime', undefined)
        })
    },

    // 清除token
    clearToken ({ state, commit }) {
      commit('setToken', undefined)
      commit('setRefreshToken', undefined)
    },
    /**
       * 更新当前用户的用户信息
       * @param param0
       */
    updateCurUserInfo ({ state, commit }, force?: boolean) {
      return new Promise<UserInfo>((resolve, reject) => {
        if (!force && state.userInfo) {
          resolve(state.userInfo)
          return
        }
        userRights.getUserInfoFromServer()
          .then((data) => {
            commit('setUserInfo', data)
            commit('setWebUserInfo', true)
            resolve(data)
          }).catch((err: Error) => {
            console.log(`更新当前用户的UserInfo失败:${err.message}`)
            reject(err)
          })
      })
    }

  }

  // 访问器
  getters: GetterTree<UserStore, any> = {
    // 返回当前的token
    tokenGeter (state: UserStore, getters: any) {
      return state.token
    },
    // 返回当前的刷新token
    refreshtokenGeter (state: UserStore, getters: any) {
      return state.reftoken
    },

    /**
     * 取得当前用户信息
     * @param state
     * @param getters
    */
    userInfoGetter (state: UserStore, getters: unknown) {
      return state.userInfo
    }

  }
}

// 导出对象实例
export default new UserStoreModule()
