
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import ControllerList from './controller-list.vue'
import ControllerView from './controller-view/controller-view.vue'
import ControllerConfigSelector from '@/components/selector/controller-config-selector.vue'

@Component({
  name: 'ApiManager',
  components: { ControllerConfigSelector, ControllerList, ControllerView }
})
export default class ApiManager extends Vue {

}
