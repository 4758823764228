/**
 * 实体版本配置模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { EntityModelVersion } from './entity-model-version'

/**
 * 实体版本服务请求api接口
 */
class EntityModelVersionApi extends DefaultApiService<EntityModelVersion> {
  /**
     * 根据项目id取得所有的版本信息
     * @param projectId
     * @returns
     */
  getVersionListByProjectId (projectId: number) {
    return this.requestList(
            `getVersionListByProjectId/${projectId}`, null, 'GET'
    )
  }
}

export default new EntityModelVersionApi('/api/entityModelVersion')
