/**
 * QueryBean配置模块
 */
import DefaultApiService from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'
import { HasEntityQueryBeanConfig, QueryBeanConfig } from './query-bean-config'

/**
 * QueryBean配置服务请求api接口
 */
class QueryBeanConfigApi extends DefaultApiService<QueryBeanConfig> {
  /**
     * 启用或禁用enumItem
     * @param querybean
     * @returns
     */
  enableOrDisable (querybean: QueryBeanConfig) {
    return this.request(`${querybean.disable ? 'enable' : 'disable'}/${querybean.id}`, null, 'PUT')
  }

  /**
    * 新增指定的数据
    * @param data
    */
  public insertItem (data: QueryBeanConfig | HasEntityQueryBeanConfig): Promise<DataApiResult<QueryBeanConfig>> {
    return this.request('', { ...data, id: undefined, entity: undefined }, 'PUT')
  }
}

export default new QueryBeanConfigApi('/api/queryBeanConfig')
