import Entity from '@/libs/entitymodel/entity'
import _ from 'lodash'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

interface EntityStore {
  entitiesMap: Record<string, Entity>;
}

class EntityStoreModule implements Module<EntityStore, any> {
  namespaced = true

  state: EntityStore = {
    entitiesMap: {}
  }

  mutations: MutationTree<EntityStore> = {
    setEntitiesMap (state: EntityStore, newentitiesMap: Record<string, Entity>) {
      state.entitiesMap = newentitiesMap
    },
    addEntity (state: EntityStore, payload: { entityUuid: string; entity: Entity }) {
      state.entitiesMap[payload.entityUuid] = payload.entity
    },
    removeEntity (state: EntityStore, entityUuid: string) {
      _.unset(state.entitiesMap, entityUuid)
    }
  }

  getters: GetterTree<EntityStore, any> = {
    getEntitiesMap (state: EntityStore) {
      return state.entitiesMap
    }
  }
}

export default new EntityStoreModule()
