/**
 * 表单状态机
 */

import _ from 'lodash'
import { Prop, Watch, Vue, Component } from 'vue-property-decorator'
import { AnyEventObject, EventObject, State, StateSchema } from 'xstate'
import { userHasRight } from './user-rights'

/// 表单的基础状态
export const FormState = {
  New: 'new',
  Edit: 'edit',
  View: 'view',
  Reload: 'reload'
}

/// 基础表单状态机schma
export interface FormSchma extends StateSchema {
  states: {
    /// 新增状态
    new: Record<string, any>;
    /// 修改状态
    edit: Record<string, any>;
    /// 查询状态
    view: Record<string, any>;
    /// 保存中
    saving: Record<string, any>;
    /// 加载中
    loading: Record<string, any>;
  };
}

/// 所有表单状态
export const AllFormState = 'all'

/// 表单标准事件
export type FormEvent = { type: 'edit' } | { type: 'inserted' } | { type: 'saved' } | { type: 'reload' } | AnyEventObject

/// 表单编辑事件
export const FormEventEdit = { type: 'edit' }
/// 表单新增数据事件
export const FormEventInsert = { type: 'insert' }
/// 表单保存修改事件
export const FormEventSave = { type: 'save' }
/// 表单数据重载
export const FormEventReload = { type: 'reload' }

/// 表单数据状态
export type FormDataState = string

/// 当前表单的状态上下文
export interface FormContext<C, E extends EventObject> {
  /**
   * 当前表单状态
   */
  formState?: State<C, E, FormSchma, {
    value: any;
    context: C;
  }>;
  /// 当前数据状态
  dataState: FormDataState;
}

/**
 * 检查表单状态匹配
 * @param formContext
 * @param states
 */
function checkFormStatesMatches (formContext: FormContext<any, FormEvent>, state: string) {
  if (state === AllFormState) return true
  if (formContext.formState?.matches(state)) {
    return true
  }

  return false
}

/**
 * 检查数据状态是否匹配
 * @param formContext
 * @param states
 */
function checkDataStatesMacthes (formContext: FormContext<any, FormEvent>, state: string) {
  if (state === AllFormState) return true
  if (formContext.dataState && formContext.dataState === state) {
    return true
  }

  return false
}

/**
 * 检查是否状态匹配
 * @param statesService
 * @param states
 */
export function checkFormMatches (formContext: FormContext<any, FormEvent>, states: string[]) {
  const spit = '&'
  for (let state of states) {
    /// 将服务器状态与本地状态分开
    state = state.trim()
    if (state.length < 1) return true // 如果是空所有都返回真
    const arry = state.split(spit)

    let checkLocal = false
    let checkServer = false
    const hasSpit = state.lastIndexOf(spit) >= 0

    /// 没有找到分隔，表示只检查本地
    if (!hasSpit) {
      checkLocal = true
    } else if (state.endsWith(spit)) {
      /// 分隔结尾也只
      checkLocal = true
    } else if (state.startsWith(spit)) {
      checkServer = true
    } else {
      checkLocal = checkServer = true
    }

    const local = checkLocal ? checkFormStatesMatches(formContext, arry[0]) : true
    const server = checkServer ? checkDataStatesMacthes(formContext, arry.length > 1 ? arry[1] : arry[0]) : true

    if (local && server) {
      return true
    }
  }
  return false
}

/**
   * 取得有指定的属性名称的第一个父组件
   * @param curNode  当前的组件
   * @param propName 要检查的属性名称
   */
function getHasFirstPropParent (curNode: Vue, dataName: string): Vue | undefined {
  if (curNode.$parent) {
    const parent: Vue | undefined = curNode.$parent
    if (parent.$data[dataName] === undefined && (!parent.$props || parent.$props[dataName] === undefined)) {
      return getHasFirstPropParent(parent, dataName)
    } else {
      return parent
    }
  } else {
    return undefined
  }
}

/**
   * 拥有权限与表单状态控制的组件混合器
   */

@Component({
  name: 'withRightAndStateComponentMinx'
})
export class WithRightAndStateComponentMinx extends Vue {
  /**
   * 页面状态上下文
   */
  formPageContext: FormContext<any, FormEvent> = {} as any;

  /**
   * 显示的表单状态列表
   */
  @Prop({
    type: Array,
    required: false,
    default: () => [AllFormState]
  })
  visiableStates!: Array<string>;

  /**
   * 可以编辑的状态列表
   */
  @Prop({
    type: Array,
    required: false,
    default: () => [FormState.New, FormState.Edit]
  })
  canEditStates!: Array<string>;

  /**
   * 可查看的权限列表
   */
  @Prop({
    type: String,
    required: false
  })
  canVisiableRights?: string

  /**
   * 可编辑的权限列表
   */
  @Prop({
    type: String,
    required: false
  })
  canEditRights?: string

  /**
   * 父节点是否只读
   */
  parentNodeReaderOnly = false

  /// 当前是否显示
  visiable = true

  /**
   * 当前是否只读
   */
  readonly = false

  /**
   * 计算是否可以显示
   */
  @Watch('formPageContext')
  computerVisiable () {
    if (!this.formPageContext) {
      this.visiable = userHasRight(this.canVisiableRights)
    } else {
      // 显示的状态计算
      const stateV = checkFormMatches(this.formPageContext, this.visiableStates)
      const stateR = userHasRight(this.canVisiableRights)

      this.visiable = stateV && stateR
    }
  }

  /**
   * 监控当前readonly变化
   * @param curReadonly
   */
  @Watch('readonly')
  watchReadOnly (curReadonly: boolean) {
    // 向下传送只读变化
    this.$emit('formReadonlyChange', curReadonly)
  }

  /**
   * 计算是否只读
   */
  @Watch('formPageContext')
  computerReadonly () {
    if (this.parentNodeReaderOnly) {
      this.readonly = true
      return
    }
    if (!this.formPageContext) {
      this.readonly = !userHasRight(this.canEditRights)
    } else {
      // 显示的状态计算
      const stateV = !checkFormMatches(this.formPageContext, this.canEditStates)
      const stateR = !userHasRight(this.canEditRights)

      this.readonly = stateV || stateR
    }
  }

  /**
   * 取得上下文中的当前formPageContext
   */
  initFormPageContext () {
    if (this.$props.formContext) {
      this.$set(this, 'formPageContext', this.$props.formContext)
      return
    } // 当前已经有属性了，则不返回
    const parent = getHasFirstPropParent(this, 'formPageContext')
    if (parent) {
      this.$set(this, 'formPageContext', parent.$data.formPageContext)

      parent.$on('formReadonlyChange', this.parentReadonlyChange)
      parent.$on('formPageContextChange', this.parentPageFormContextChange)
    }
  }

  /**
   * 处理父节点的readonly事件
   * @param readonly
   */
  parentReadonlyChange (readonly: boolean) {
    this.parentNodeReaderOnly = readonly
    this.computerReadonly()
  }

  /**
   * 处理父节点的状态变化事件
   * @param formPageContext
   */
  parentPageFormContextChange (formPageContext: any) {
    this.formPageContext = formPageContext
    // 向下传送
    this.$emit('formPageContextChange', this.formPageContext)
  }

  /**
   * 创建时加载初始数据
   */
  created () {
    this.initFormPageContext()
    this.computerVisiable()
    this.computerReadonly()
  }
}

/**
 * 拥有model属性的混合器
 */
@Component({
  name: 'withModelComponentMinx'
})
export class WithModelComponentMinx extends Vue {
  /**
   * 当前组件的上下文model
   */
  model?: any;

  initModel () {
    if (this.$props.model) {
      return
    }
    const parent = getHasFirstPropParent(this, 'model')
    if (parent) {
      const model = parent.$data.model ? parent.$data.model : parent.$props.model
      this.model = model
      this.$set(this, 'model', model)
      parent.$on('onModelChage', this.onModelChange)
    }
  }

  /**
   * 传递model变化事件
   * @param model
   */
  onModelChange (model: any) {
    this.model = model

    this.$set(this, 'model', model)
  }

  @Watch('model')
  watchModelChange (model: any) {
    this.$emit('onModelChage', model)
  }

  created () {
    this.initModel()
  }
}
