import Vue from '*.vue'
import config from '@/config'
import store, { dispatchClearToken } from '@/store'
import axios, { AxiosRequestConfig } from 'axios'
import { reject } from 'lodash'
import { checkRequestErrorMessage } from './error-message'

// 当前是否与服务器联调
export const isDebugLocal: boolean = (process.env.NODE_ENV === 'development' && !config.isConnectDebugSever)

/**
 * 服务器请求
 */
const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
const RESPONSE_SUCCESS = 0

/**
 * 按日期搜索请求
 */
export interface DateRangeReq {
  startDate: string|Date;
  endDate: string|Date;
}

/**
 * 带有header的数据结果
 */
export interface DataWithHeader<T>{
  data: T;
  headers: Record<string, any>;
}

/**
 * 分页服务回复
 */
export interface PagerData<T> {
    total: number;
    items: Array<T>;
}

/**
 * 服务器回复数据
 */
export interface ResponseData<T>{
    code: number;
    message?: string;
    data?: T;
}

/**
 * 服务器回复数据非泛型
 */
export interface ResponseDataCommon{
  code: number;
  message?: string;
  data?: any;
}

/**
 * 分页数据结果
 */
export type PagerResponseData<T> = ResponseData<PagerData<T>>

/**
 * 服务器回复错误
 */
export interface ResponseError {
    code: number;
    message: string;
    headers?: any;
}

/**
 * 分页查询数据
 */
export interface PagerQueryData{
  page: number;
  pageSize: number;
  sorts: Array<string>;
  [prop: string]: any;
}

/**
 * 下载文件
 */
export function downfile (req: AxiosRequestConfig, contextType: string) {
  return new Promise<string>((resolve, reject) => {
    const reqData = req

    // 根据环境变量取得服务地址
    let url = req.url as string

    url = url.startsWith('/') ? baseUrl + url : baseUrl + '/' + url
    reqData.url = url
    reqData.responseType = 'blob'

    // 在请求中添加token信息
    const token: any = store.getters['user/tokenGeter']
    if (token) {
      if (!isDebugLocal) {
        if (!req.headers) req.headers = {}
        req.headers.authorization = 'Bearer ' + token
      }
    }

    axios.request(reqData).then(response => {
      console.log('收到服务请求的数据:%o', response)
      const url = URL.createObjectURL(new Blob([response.data], { type: contextType }))
      resolve(url)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 请求图片
 * @param url
 */
export function requestImage (url: string) {
  url = url.startsWith('/') ? baseUrl + url : baseUrl + '/' + url
  const req: AxiosRequestConfig = {
    url: url,
    method: 'GET',
    responseType: 'arraybuffer'
  }
  // 在请求中添加token信息
  const token: any = store.getters['user/tokenGeter']
  if (token) {
    if (!isDebugLocal) {
      if (!req.headers) req.headers = {}
      req.headers.authorization = 'Bearer ' + token
    }
  }
  return new Promise<DataWithHeader<any>>((resolve, reject) => {
    axios.request(req).then(response => {
      const re: DataWithHeader<any> = {
        data: 'data:image/png;base64,' +
        btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')),
        headers: response.headers
      }
      return re
    }).then((res) => {
      resolve(res)
    })
      .catch(err => {
        reject(err)
      })
  })
}

export default function request<T extends ResponseData<any>> (req: AxiosRequestConfig, useToken = true) {
  return new Promise<T>((resolve, reject) => {
    const reqData = req

    // 根据环境变量取得服务地址
    let url = req.url as string

    url = url.startsWith('/') ? baseUrl + url : baseUrl + '/' + url
    reqData.url = url

    // 在请求中添加token信息
    const token: any = store.getters['user/tokenGeter']
    if (token && useToken) {
      if (!isDebugLocal) {
        if (!req.headers) req.headers = {}
        req.headers.authorization = 'Bearer ' + token
        req.headers['Content-Type'] = 'application/json'
        req.headers.platform = 'web'
        req.headers.platformVersion = '1.0'
      }
    }

    // 向reqData中添加token数据
    axios.request(reqData)
      .then(response => {
        const resp: T = response.data

        // 检查服务器返回的数据的code值是否正确
        if (resp.code) {
          const message = checkRequestErrorMessage(resp.code)
          if (message === null) {
            resolve(resp)
          } else {
            const error: ResponseError = {
              code: resp.code,
              message: resp.message ? resp.message : ''
            }
            reject(error)
          }
        } else {
          // 如果不带code值的返回则直接发送数据处理器
          resolve(response.data)
        }
      }).catch((err) => {
        const message = checkRequestErrorMessage(err.response.status)
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          err.message = '你没有权限访问该功能'
        }

        const error: ResponseError = {
          code: err.response.code,
          message: message!
        }
        reject(error)
      })
  })
}

// 请求外部网站
export function requestOuterUrl<T extends ResponseData<any>> (req: AxiosRequestConfig) {
  return new Promise<T>((resolve, reject) => {
    const reqData = req
    axios.request(reqData)
      .then(response => {
        const resp: T = response.data
        resolve(response.data)
      }).catch((err) => {
        const error: ResponseError = {
          code: err.response.code,
          message: '错误'
        }
        reject(error)
      })
  })
}
