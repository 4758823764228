
import { Vue, Component, Watch } from 'vue-property-decorator'
import EnumConfigApi from '@/api/project/enummanager/enum-config/enum-config-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import Modals from '@/views/project/enum-manager/enumeration-view/modal/modals'
import EntityModelVersionSelector from '@/components/selector/entity-model-version-selector.vue'
import {
  updateCurEnumerationIdAndInfo,
  getterCurEnumerationInfo,
  getterCurEnumerationId,
  getterProjectId,
  getterProjectMemberRight
} from '@/store'
import { CurControllInfo } from '@/store/module/api-manager'
import { Menu } from 'view-design'
import { EnumerationConfig } from '@/api/project/enummanager/enum-config/enum-config'

const defaultQuery = {
  page: 1,
  pageSize: 1000,
  sorts: ['-createTime'],
  queryEnumKey: ''
}

@Component({
  name: 'EnumerationList',
  components: { EntityModelVersionSelector }
})
export default class EnumerationList extends Vue {
  [x: string]: any
  isShowSpin = false

  entityModelVersionId = -1

  // 当前选择中的菜单
  activeName?: string = ''

  /**
   * 取得项目id
   */
  get projectId () {
    return getterProjectId()
  }

  /**
   * 用户是否可以编辑文档
   */
  get canEdit () {
    const right = getterProjectMemberRight()
    if (!right) return false
    return right.updateDataModel
  }

  /**
   * 当前Enumeration的信息
   */
  get curEnumerationInfo () {
    return getterCurEnumerationInfo()
  }

  /**
   * 当前Enumeration的id
   */
  get curEnumerationId () {
    return getterCurEnumerationId()
  }

  /**
   * 当前控件id变化
   */
  onCurEnumerationIdChange (controlId?: number) {
    if (controlId) {
      this.activeName = controlId + ''
    } else if (this.enumList && this.enumList.length > 0) {
      this.activeName = this.enumList[0].id + ''
    } else {
      this.activeName = ''
    }
  }

  enumList: Array<EnumerationConfig> = []

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)

  pageData: PageListData<EnumerationConfig> = {
    items: [],
    total: 0,
    pageSize: 10000
  }

  loadDataFromServer () {
    this.isShowSpin = true
    this.queryData.projectId = getterProjectId()
    // 清空关键字
    if (
      this.queryData.queryControllerKey &&
      this.queryData.queryControllerKey === ''
    ) {
      this.queryData.queryControllerKey = undefined
    }
    this.queryData.versionId = this.entityModelVersionId
    EnumConfigApi.query(this.queryData)
      .then(resp => {
        this.pageData = resp.data!
        this.enumList = this.pageData.items
        if (this.enumList && this.enumList.length > 0) {
          this.onSelectMenu(this.enumList[0].id + '', true)
        } else {
          this.clearEnumerationInfo()
        }
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
        this.forceUpdateMenuSelected()
      })
  }

  /// 新增controlbutton
  onClickAddNewEnumerationButton () {
    const that = this
    Modals.showEnumerationConfigCreatorModal(data => {
      data.versionId = that.entityModelVersionId
      data.projectId = getterProjectId()
      return new Promise<void>((resolve, reject) => {
        EnumConfigApi.insertItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            updateCurEnumerationIdAndInfo(res.data!.id!, {
              name: res.data!.name!,
              title: res.data!.title!
            })
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  /// 删除当前enum
  onClickDeleteEnumerationButton () {
    const enumId = getterCurEnumerationId()
    if (!enumId) return
    this.$Modal.confirm({
      title: '删除enumeration吗',
      content: '确定删除enumeration吗？删除之后不可恢复！',
      onOk: () => {
        EnumConfigApi.deleteItemByIds([enumId])
          .then(res => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  // 禁用enum
  onDisableEnumerationButton () {
    const enumId = getterCurEnumerationId()
    if (!enumId) return

    this.$Modal.confirm({
      title: '确认',
      content: '确定要禁用enumeration吗？',
      onOk: () => {
        EnumConfigApi.disableEnumeration(enumId)
          .then(resp => {
            this.$Message.success('禁用enumeration成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error('禁用enumeration失败:' + err.message)
          })
      }
    })
  }

  // 启用enum
  onEnableEnumerationButton () {
    const enumId = getterCurEnumerationId()
    if (!enumId) return

    this.$Modal.confirm({
      title: '确认',
      content: '确定要启用enumeration吗？',
      onOk: () => {
        EnumConfigApi.enableEnumeration(enumId)
          .then(resp => {
            this.$Message.success('启用enumeration成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error('启用enumeration失败:' + err.message)
          })
      }
    })
  }

  /**
   * 菜单选择
   * @param name 菜单名称
   * @param notforceRoute 是否不强制跳转
   */
  onSelectMenu (name: string, notforceRoute: boolean) {
    const enumId = parseInt(name)

    // 通过id找到对应的control
    const index = this.enumList.findIndex(item => item.id === enumId)
    const enumInfo = this.enumList[index]

    // 执行跳转操作
    updateCurEnumerationIdAndInfo(enumInfo.id!, {
      name: enumInfo.name!,
      title: enumInfo.title!
    })
    if (!notforceRoute && this.$route.name !== 'enumeration-view') {
      this.$router.push({ name: 'enumeration-view' })
    }
  }

  /**
   * 清除当前枚举信息
   */
  clearEnumerationInfo () {
    updateCurEnumerationIdAndInfo()
  }

  /**
   * 当前controlinfo变化
   */
  @Watch('curEnumerationInfo')
  onCurEnumerationInfoChange (value?: CurControllInfo) {
    const id = getterCurEnumerationId()
    if (id) {
      const index = this.enumList.findIndex(item => item.id === id)
      if (index >= 0) {
        this.enumList[index].title = value?.title
        this.enumList[index].name = value?.name
      }
    }
  }

  onQueryEnumerationConfigs () {
    this.loadDataFromServer()
  }

  onContextmenu () {
    this.$contextmenu({
      items: [
        {
          label: '返回(B)',
          onClick: () => {
            console.log('返回(B)')
          }
        }
      ]
    })
  }

  // 强制更新选择的菜单
  forceUpdateMenuSelected () {
    this.onCurEnumerationIdChange(getterCurEnumerationId())
    const menu = this.$refs.menu as Menu
    menu.updateActiveName()
  }
}
