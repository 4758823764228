
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import ControllerConfigApi from '@/api/project/apimanager/controller-config/controller-config-api'
import Modals from '@/views/project/api-manager/controller-view/modal/modals'
import { updateCurControllerIdAndInfo, getterCurControllerId, getterProjectId, getterProjectMemberRight } from '@/store'
import webapiProjectApi from '@/api/web-api-project-info'

@Component({
  name: 'ControllerInfoPanel',
  components: {}
})
export default class ControllerInfoPanel extends Vue {
  /// 当前controlid
  get currentControlId () {
    return getterCurControllerId()
  }

  /**
   * 用户是否可以编辑文档
   */
  get canEdit () {
    const right = getterProjectMemberRight()
    if (!right) return false
    return right.updateApiInterface
  }

  isShowSpin = false

  controllerConfig: ControllerConfig = {}

  package = ''

  rules = {
    title: [
      {
        required: true,
        type: 'string',
        message: '标题不能为空',
        trigger: 'blur'
      }
    ],
    className: [
      {
        required: true,
        type: 'string',
        message: '类名不能为空',
        trigger: 'blur'
      },
      {
        pattern: '[a-zA-Z]+[0-9a-zA-Z_]*(\\.[a-zA-Z]+[0-9a-zA-Z_]*)*',
        message: '字母与数字的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    packageName: [
      {
        required: true,
        type: 'string',
        message: '包名不能为空',
        trigger: 'blur'
      },
      {
        message: '只能字母开头，包含数字、小写字母、下划线并用.分隔',
        pattern: '^[a-z][a-z0-9_]*(.[a-z0-9_]+)+[0-9a-z_]$',
        trigger: 'blur'
      }
    ],
    baseUrlPath: [
      {
        required: true,
        type: 'string',
        message: '基础路径不能为空',
        trigger: 'blur'
      }
    ]
  }

  created () {
    this.loadDataFromServer()
    const projectId = getterProjectId()
    if (projectId) {
      webapiProjectApi.getInfoByProjectId(projectId).then(res => {
        this.package = `${res.data?.orgName}.${res.data?.projectName?.replace(/([A-Z])/g, (val) => `_${val}`).toLowerCase()}`
      })
    }
  }

  @Watch('currentControlId')
  onCurrentControlId (controlId: number) {
    this.loadDataFromServer()
  }

  // 从服务器加载数据
  loadDataFromServer () {
    if (this.currentControlId !== 0 && !this.currentControlId) {
      return
    }
    this.isShowSpin = true
    ControllerConfigApi.getItemById(this.currentControlId)
      .then(resp => {
        this.controllerConfig = resp.data!
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  onClickUpdateControllerButton () {
    const that = this
    Modals.showControllerConfigUpdaterModal(this.controllerConfig, data => {
      return new Promise<void>((resolve, reject) => {
        ControllerConfigApi.updateItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            // 通知修改了control的数据

            updateCurControllerIdAndInfo(res.data!.id!, {
              name: res.data!.className!,
              title: res.data!.title!
            })
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }
}
