import { QueryBeanPropertyConfig } from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config'
import { showModal } from '@/libs/modal-helper'
import QueryBeanPropertyCreatorModal from './query-bean-property-creator-modal.vue'

export default class Modals {
  static showQueryBeanPropertyConfigCreatorModal (apiConfigUuid: string, onOk?: (data: QueryBeanPropertyConfig) => Promise<void>) {
    showModal<any>(QueryBeanPropertyCreatorModal, {
      props: {
        dataProps: {
          combinationType: 0
        }
      }
    }, true, onOk, undefined)
  }

  static showQueryBeanPropertyConfigUpdaterModal (config: QueryBeanPropertyConfig, onOk?: (data: QueryBeanPropertyConfig) => Promise<void>) {
    showModal<any>(QueryBeanPropertyCreatorModal, {
      props: {
        title: '修改QueryBean字段',
        dataProps: config
      }
    }, true, onOk, undefined)
  }
}
