/**
 * 团队模块
 */

import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'
import request from '@/libs/request'

/**
 * 团队接口
 */
export interface Team extends BaseHasTimeEntity {

  // 团队名称
  name?: string;

  // 团队编号
  code?: string;

  // 服务状态
  serviceStatus?: number;

  // 注册用户
  registerUser?: number;

  // 注册Ip
  registerIp?: string;

  // 团队图标
  iconUrl?: string;

}

/**
 * 团队服务请求api接口
 */
class TeamApi extends DefaultApiService<Team> {
  constructor () {
    super('/api/team')
  }

  /**
    * 取得id对应的对象的值
    * @param id 要删除记录的主键id
    */
  public getItemById (id: number): Promise<DataApiResult<Team>> {
    return this.request(`${id}`, undefined, 'GET')
  }

  /**
  */
  public checkNewTeamName (name: string): Promise<DataApiResult<boolean>> {
    return request<DataApiResult<boolean>>({
      url: this.baseUrlPath + `teamNameCheck/${encodeURIComponent(name)}`,
      method: 'GET'
    }, false)
  }
}

export default new TeamApi()
