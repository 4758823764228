/**
 * Api配置模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { ControllerConfigDetail } from './controller-config-detail'

/**
 * Api配置服务请求api接口
 */
class ControllerConfigDetailApi extends DefaultApiService<ControllerConfigDetail> {
  enableOrDisable (data: ControllerConfigDetail): Promise<DataApiResult<ControllerConfigDetail>> {
    return this.request('enableOrDisable', data, 'POST')
  }
}

export default new ControllerConfigDetailApi('/api/apiConfig')
