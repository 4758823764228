
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { QueryBeanPropertyConfig } from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config'
import QueryBeanPropertyConfigApi from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import Modals from './modal/modals'
import BeanModals from '../../modal/modals'
import { QueryBeanConfig } from '@/api/project/apimanager/query-bean-config/query-bean-config'

const defaultQuery = {
  page: 1,
  pageSize: 100,
  sorts: ['-createTime']
}

@Component({
  name: 'QueryBeanPropertyList',
  components: {}
})
export default class QueryBeanPropertyList extends Vue {
  @Watch('queryBeanConfigId')
  onIdChange () {
    this.loadDataFromServer()
  }

  @Prop({
    type: Object,
    required: true
  })
  queryBeanConfig!: QueryBeanConfig

  @Prop({
    type: Boolean,
    default: false
  })
  readonly readonly!: boolean

  isShowSpin = false

  queryBeanPropertyList: Array<QueryBeanPropertyConfig> = []

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery)

  pageData: PageListData<QueryBeanPropertyConfig> = {
    items: [],
    total: 0,
    pageSize: 10000
  }

  created () {
    this.loadDataFromServer()
  }

  loadDataFromServer () {
    if (!this.queryBeanConfig) {
      return
    }
    this.queryData.queryBeanConfigUuid = this.queryBeanConfig.uuid
    this.isShowSpin = true
    QueryBeanPropertyConfigApi.query(this.queryData)
      .then(resp => {
        this.pageData = resp.data!
        this.queryBeanPropertyList = this.pageData.items
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  onClickAddNewQueryBeanPropertyButton () {
    const that = this
    if (!that.queryBeanConfig) {
      return
    }
    Modals.showQueryBeanPropertyConfigCreatorModal(
      this.queryBeanConfig.uuid,
      data => {
        return new Promise<void>((resolve, reject) => {
          data.queryBeanConfigUuid = that.queryBeanConfig.uuid
          QueryBeanPropertyConfigApi.insertItem(data)
            .then(res => {
              this.$Message.success('操作成功')
              this.loadDataFromServer()
              resolve()
            })
            .catch(err => {
              this.$Message.error({
                content: err.message
              })
            })
        })
      }
    )
  }

  onClickUpdateQueryBeanPropertyButton (
    queryBeanPropertyConfig: QueryBeanPropertyConfig
  ) {
    const that = this
    Modals.showQueryBeanPropertyConfigUpdaterModal(
      queryBeanPropertyConfig,
      data => {
        return new Promise<void>((resolve, reject) => {
          data.queryBeanConfigUuid = that.queryBeanConfig.uuid
          QueryBeanPropertyConfigApi.updateItem(data)
            .then(res => {
              this.$Message.success('操作成功')
              this.loadDataFromServer()
              resolve()
            })
            .catch(err => {
              this.$Message.error({
                content: err.message
              })
            })
        })
      }
    )
  }

  onClickDeleteQueryBeanPropertyButton (id: number) {
    this.$Modal.confirm({
      title: '是否删除？',
      content: '是否删除该参数？',
      onOk: () => {
        QueryBeanPropertyConfigApi.deleteItemByIds([id])
          .then(resp => {
            this.$Message.info('操作成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  // 修改查询bean基本信息
  editQueryBean () {
    BeanModals.showQueryBeanConfigUpdateModal(this.queryBeanConfig, data => {
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    })
  }
}

