import { ApiResult, DataApiResult } from '@/libs/http-request'
/* eslint-disable @typescript-eslint/camelcase */
import config from '@/config'

import request, { requestImage, ResponseData } from '@/libs/request'
import Axios from 'axios'
import { reject } from 'lodash'
import { Team } from './team'
import { TeamUse } from './team-user'
import { User, UserTeamInfo } from './user'
import { ProjectMemberRight } from './project-member'

// 权限相关api

/**
 * 用户登入结果回复
 */
export interface UserLoginResponse extends ResponseData<any> {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  refresh_token: string;
  // eslint-disable-next-line camelcase
  token_type: string;
  expires_in: number;
  avatar?: string;
}

export interface UserInfo extends Partial<ProjectMemberRight> {
  nickName: string;
  nameAtTeam: string;
  userCode: string;
  userId: number;
  phone: string;
  /// 当前激活的团队id
  activedTeamId: number;
  teamName: string;
  teamCode: string;
  iconUrl: string;
  /// 是否有权添加用户
  canAddUser: boolean;

  /// 是否有权创建工程
  canCreateProject: boolean;

  /// 是否有权删除工程
  canDeleteProject: boolean;

  // 是否可以锁定项目组(false:否,true:是)"
  canLockProjectGroup: boolean;

  /// 是否有创建项目组(false:否,true:是)"
  canCreateProjectGroup: boolean;

  /// 是否有删除项目组(false:否,true:是)"
  canDeleteProjectGroup: boolean;

  /// 是否有权操作服务器
  canOperatePcServer?: boolean;

  /// 是否有权操作应用中心
  canOperateApplication?: boolean;

  /// 是否有权操作企业微服务基础资源
  canOperateCloudBaseResource?: boolean;

  /// 是否有权操作镜像资源
  canOperateImageResource?: boolean;

  /// 是否有权操作配置中心
  canOperateRegisterCenter?: boolean;

  /// 是否有权操作api网关
  canOperateApiGateway?: boolean;

  /// 是否有权操作资源
  canOperateResource?: boolean;

  /// 运维权限过期时间
  operaExpirationTime?: string;

  /// webapi权限过期时间
  webApiExpirationTime?: string;

  /// webFront权限过期时间
  webFrontExpirationTime?: string;

  access: [];
}

/**
 * 用户团队列表
 */
export interface UserTeamInfoList {
  // 用户id
  userId: number;
  // 用户团队列表
  list: UserTeamInfo[];
}

// 返加用户信息
export type UserInfoResponse = ResponseData<UserInfo>

export default class SecurityService {
  /**
     * 通过用户密码登入系统
     */
  static loginByPwd (name: string, pwd: string, teamCode: string, loginKey: string, code: number): Promise<UserLoginResponse> {
    const data = {
      grant_type: 'password',
      username: name,
      password: pwd,
      scope: config.clientscope,
      client_id: config.clientname,
      client_secret: config.clientpwd,
      clienttype: 1, // 为客户端应用
      check_code: code,
      login_key: loginKey,
      team_code: teamCode,
      authtype: 1
    }
    return new Promise<UserLoginResponse>((resolve, reject) => {
      Axios.request({
        url: '/oauth/token',
        method: 'post',
        params: data
      }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(new Error('登入失败:' + err.mesasge))
      })
    })
  }

  /**
   * 通过ossKey进行登入
   * @param ossKey
   */
  static loginByOssKey (ossKey: string): Promise<UserLoginResponse> {
    const data = {
      grant_type: 'password',
      username: ossKey,
      scope: config.clientscope,
      client_id: config.clientname,
      client_secret: config.clientpwd,
      clienttype: 1, // 为客户端应用
      authtype: 4
    }
    return new Promise<UserLoginResponse>((resolve, reject) => {
      Axios.request({
        url: '/oauth/token',
        method: 'post',
        params: data
      }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(new Error('登入失败:' + err.mesasge))
      })
    })
  }

  // 用户注销
  static logout (token: string, refreshToken: string): Promise<ResponseData<any>> {
    return request({
      url: '/oauth/sso/logout',
      data: {
        token,
        refreshToken
      },
      method: 'post'
    })
  }

  // 刷新token
  static refreshToken (refreshToken: string, teamCode: string): Promise<UserLoginResponse> {
    if (!refreshToken) {
      reject(null)
    }
    const data = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: config.clientname,
      client_secret: config.clientpwd,
      scope: config.clientscope,
      clienttype: 1, // 客户端为后台服务类型
      team_code: teamCode
    }
    return new Promise<UserLoginResponse>((resolve, reject) => {
      Axios.request({
        url: '/oauth/token',
        method: 'post',
        params: data
      }).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  // 取得当前用户的信息
  static getUserInfo (): Promise<UserInfoResponse> {
    return request({
      url: '/api/user/userinfo',
      method: 'get'
    })
  }

  // 取得当前用户的信息
  static clearRecordCache (teamId: number): Promise<UserInfoResponse> {
    return request({
      url: `/api/user/clearCache/${teamId}`,
      method: 'get'
    })
  }

  // 注册用户
  static userRegister (user: User, checkCode: string): Promise<DataApiResult<User>> {
    return request({
      url: `/api/security/registerUser/${checkCode}`,
      method: 'PUT',
      data: {
        ...user,
        textPwd: user.pwd // 传输明文密码
      }
    }, false)
  }

  /**
   * 检查用户帐号是否已经注册
   * @param loginName
   * @returns
   */
  static ensureUserRegister (loginName: string): Promise<DataApiResult<boolean>> {
    loginName = encodeURI(loginName)
    return request<DataApiResult<boolean>>({
      url: `/api/security/ensureUserRegister/${loginName}`
    }, false)
  }

  /**
   * 检查手机号是否已经注册
   * @param phone
   * @returns
   */
  static ensurePhoneRegister (phone: string): Promise<DataApiResult<boolean>> {
    return request<DataApiResult<boolean>>({
      url: `/api/security/ensurePhoneRegister/${phone}`
    }, false)
  }

  /**
   * 给手机发送短信验证码
   * @param phone  手机号
   * @returns
   */
  static sendPhoneCheckCode (phone: string): Promise<ApiResult> {
    return request<ApiResult>({
      url: '/api/security/sendPhoneCheckCode/' + phone,
      method: 'POST'
    }, false)
  }

  /**
   * 修改用户的密码
   * @param pwd 修改后的密码
   * @param checkCode  短信验证码
   */
  static modiUserPwd (pwd: string, checkCode: string): Promise<ApiResult> {
    return request<ApiResult>({
      url: '/api/user/modiUserPwd',
      method: 'POST',
      data: {
        pwd: pwd,
        checkCode: checkCode
      }
    })
  }

  /**
   * 忘记密码
   * @param phone
   * @param pwd
   * @param checkCode
   * @returns
   */
  static forgetPassword (phone: string, pwd: string, checkCode: string) {
    return request<ApiResult>({
      url: '/api/security/forgetPassword',
      method: 'POST',
      data: {
        phone: phone,
        pwd: pwd,
        checkCode: checkCode
      }
    })
  }

  // 根据登录账号找到用户的团队信息
  static getUserTeamListByLoginName (userLoginName: string, pwd: string, key: string, code: number): Promise<DataApiResult<UserTeamInfoList>> {
    return request<DataApiResult<UserTeamInfoList>>(
      {
        url: '/api/security/getUserTeamListByLoginName',
        data: {
          userLoginName: userLoginName,
          pwd: pwd,
          key: key,
          code: code
        },
        method: 'POST'
      }, false)
  }

  /**
  *
  * @param code 根据团队编号获取到团队信息
  */
  static getItemByTeamCode (code: string) {
    return request<DataApiResult<Team>>({
      url: `/api/security/getItemByTeamCode/${code}`,
      method: 'POST'
    }, false)
  }

  /**
   * 新增团队用户接口
   * @param user  申请对象
   */
  static createTeamUser (user: TeamUse) {
    return request<DataApiResult<TeamUse>>({
      url: '/api/security/createTeamUser',
      method: 'POST',
      data: user
    }, false)
  }

  /**
   * 新增团队接口
   * @param name
   * @param registerUser
   */
  static createNewTeam (teamName: string, registerUser: number) {
    return request<DataApiResult<Team>>({
      url: '/api/security/createNewTeam',
      method: 'POST',
      data: {
        name: teamName,
        registerUser: registerUser
      }
    }, false)
  }

  /**
   * 请求验证图片
   * @returns
   */
  static requestCheckPic () {
    return requestImage('/oauth/checkcode/checkImage')
  }

  /**
   * 请求登入验证图片
   * @param key loginkey
   * @param angle 图片角度
   * @returns
   */
  static requestCheckCode (key: string, angle: number) {
    return request<DataApiResult<number>>({
      url: `/oauth/checkcode/checkImageAngle/${key}/${angle}`,
      method: 'GET'
    }, false)
  }

  /**
   * 发送验证码前先使用验证图片进行人机验证
   * @param phone
   * @param key
   * @param angle
   * @returns
   */
  static requestImageToSendCheckCode (phone: string, key: string, angle: number) {
    return request<ApiResult>({
      url: `/oauth/checkcode/checkImageAngleToSendCheckcode/${phone}/${key}/${angle}`,
      method: 'GET'
    }, false)
  }

  /**
   * 取得带有ssokey的url地址
   * @param url
   * @returns
   */
  static requestOssUrl (url: string) {
    return new Promise<string>((resolve, reject) => {
      request<DataApiResult<string>>({
        url: '/oauth/sso/encryptUrl',
        method: 'POST',
        data: {
          url: url
        }
      }).then((resp: any) => {
        console.log('oss url:' + resp.data)
        resolve(resp.data)
      }).catch((err: any) => {
        reject(err)
      })
    })
  }
}
