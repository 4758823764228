import { RouteConfig } from 'vue-router'

export const NotFoundPage = 'notfound'
export const NotRightPage = 'noright'
export const ErrorPage = 'someerror'

/**
 * 提示页面路由
 */
const routers: Array<RouteConfig> = [
  {
    name: NotFoundPage,
    component: () => import('@/views/reminder/notfund-page.vue'),
    path: `/${NotFoundPage}`,
    meta: {
      skipAuthentication: true
    }
  }, {
    name: NotRightPage,
    component: () => import('@/views/reminder/noright-page.vue'),
    path: `/${NotRightPage}`,
    meta: {
      skipAuthentication: true
    }
  }, {
    name: ErrorPage,
    component: () => import('@/views/reminder/error-page.vue'),
    path: `/${ErrorPage}`,
    meta: {
      skipAuthentication: true
    }
  }
]

export default routers
