<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  created () {
    // 调整iview的消息提醒时间长度
    this.$Message.config({ duration: 3 })
  }
}
</script>

<style>
#app{
  width: 100%;
  height: 100%;
}
</style>
