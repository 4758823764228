/**
 * 团队用户模块
 */

import DefaultApiService, { BaseHasTimeEntity, HasStateMachineVersionEntity } from '@/libs/api-request'
import request, { ApiResult, PagerQueryBean } from '@/libs/http-request'

/// 团队加入事件
export enum TeamUserEvent {
  /// 申请
  Apply,
  /// 同意
  Agreement,
  /// 拒绝
  Reject
}

/**
 * 团队用户接口
 */
export interface TeamUse extends BaseHasTimeEntity, HasStateMachineVersionEntity{
  // 记录id
  id?: number;
  // 团队id
  teamId?: number;

  // 用户id
  userId?: number;
  // 用户名称
  userLoginName?: string;

  // 审批人id
  approverId?: number;

  // "在团队中的名称
  nameAtTeam?: string;

  // 加入团队时间
  addTime?: string;

  // 是否禁用
  disable?: boolean;

  // 是否有权限添加用户
  canAddUser?: boolean;

  // 是否有限创建工程
  canCreateProject?: boolean;

  // 是否有限删除工程
  canDeleteProject?: boolean;

  /**
     * 是否锁定项目组(false:否,true:是)
     */
  lockProjectGroup?: boolean;

   /**
    * 是否有创建项目组(false:否,true:是)
    */
  createProjectGroup?: boolean;

   /**
    * 是否有删除项目组(false:否,true:是)
    */
  deleteProjectGroup?: boolean;

  // 是否有权操作服务器
  canOperatePcServer?: boolean;

  // 是否有权操作应用中心
  canOperateApplication?: boolean;

  // 是否有权操作企业微服务基础资源
  canOperateCloudBaseResource?: boolean;

  // 是否有权操作镜像资源
  canOperateImageResource?: boolean;

  // 是否有权操作配置中心
  canOperateRegisterCenter?: boolean;

  // 是否有权操作api网关
  canOperateApiGateway?: boolean;

  // 是否有权操作资源
  canOperateResource?: boolean;

  // 团队加入状态
  state?: number;

  // 团队名称
  teamName?: string;

  // 团队编号
  teamCode?: string;

  // 用户id
  userCode?: string;

  // 昵称
  nickName?: string;

  // 用户手机
  userPhone?: string;

  // 审批人名称
  approverName?: string;
}

export const TeamAddStatusRequesting = 0 // "申请中"
export const TeamAddStatusAgreed = 1 // 已同意
export const TeamAddStatusRejected = 2 // 已拒绝

/**
 * 团队服务请求api接口
 */
class TeamUserApi extends DefaultApiService<TeamUse> {
  constructor () {
    super('/api/team-user')
  }

  public canAddUser = 'canAddUser'
  public canCreateProject = 'canCreateProject'
  public canDeleteProject = 'canDeleteProject'
  public lockProjectGroup = 'lockProjectGroup'
  public createProjectGroup = 'createProjectGroup'
  public deleteProjectGroup = 'deleteProjectGroup'
  public canOperatePcServer = 'canOperatePcServer'
  public canOperateApplication = 'canOperateApplication'
  public canOperateCloudBaseResource = 'canOperateCloudBaseResource'
  public canOperateImageResource = 'canOperateImageResource'
  public canOperateRegisterCenter = 'canOperateRegisterCenter'
  public canOperateApiGateway = 'canOperateApiGateway'
  public canOperateResource = 'canOperateResource'

  /**
   * 查询团队请求接口
   * @param query 查询bean对象
   */
  queryTeamRequestLogs (query: PagerQueryBean) {
    return this.queryByQueryBean('query-team-request', query)
  }

  /**
   * 查询团队（申请中）请求接口
   * @param timeIndex 时间类型（日，周，月）
   */
  queryTeamRequestList (timeIndex: number) {
    return this.requestList(
      `query-team-request-list/${timeIndex}`,
      null,
      'POST'
    )
  }

  /**
   * 查询团队分页数据接口
   * @param query 查询bean对象
   */
  queryTeamListByQuery (query: PagerQueryBean) {
    return this.queryByQueryBean('query-team-list-by-query', query)
  }

  /**
   * 执行事件操作
   * @param id 主键id
   * @param event 事件
   */
  updateWithEvent (id: number, event: TeamUserEvent) {
    return this.request(
      `updateWithEvent/${id}/${TeamUserEvent[event]}`,
      null,
      'POST'
    )
  }

  /**
   * 根据团队id查询团队成员
   * @param teamId 团队id
   */
  curTeamUserList () {
    return this.requestList('curTeamUserList', null, 'GET')
  }

  /**
   * 团队成员
   */
  teamUserList (query: PagerQueryBean) {
    return this.queryByQueryBean('teamUserList', query)
  }

  /**
   * 团队小组成员禁用或启用
   * @param id 主键id
   * @param disable  状态(禁用true,启用false)
   */
  disableChange (id: number, disable: boolean) {
    const url = 'team-member-disable/' + id + '/' + disable
    return this.request(url, null, 'GET')
  }

  /**
   * 修改团队小组成员权限
   * @param id
   * @param disable
   * @param flag
   */
  updateTeamMemberPermission (id: number, disable: boolean, flag: string) {
    const url = 'updateTeamMemberPermission/' + id + '/' + disable + '/' + flag
    return this.request(url, null, 'GET')
  }

  /**
   * 更新团队小组成员状态
   * @param id 成员id
   * @param state 状态值
   * @returns
   */
  updateApplyResult (id: number, state: number): Promise<ApiResult> {
    return this.request('updateApplyResult',
      {
        id: id,
        state: state
      }
      , 'PUT')
  }

  /**
   * 更新用户的团队名称
   * @param userId
   * @param name
   * @returns
   */
  updateUserAtTeamName (userId: number, name: string): Promise<ApiResult> {
    return request({
      url: `${this.baseUrlPath}/${userId}/${name}`,
      method: 'GET'
    })
  }
}

export default new TeamUserApi()
