
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component } from 'vue-property-decorator'
import ControllerBaseModal from './controller-base-modal.vue'
import { Form } from 'view-design'
import {
  ControllerConfig,
  HasEntityControllerConfig
} from '@/api/project/apimanager/controller-config/controller-config'
import EntitySelector, {
  TreeNode
} from '@/components/selector/entity-selector.vue'
import webapiProjectApi from '@/api/web-api-project-info'
import _ from 'lodash'
import { getterProjectId } from '@/store'

@Component({
  name: 'ControllerCreatorModal',
  components: { ControllerBaseModal, EntitySelector }
})
export default class ControllerCreatorModal extends Vue implements BaseModal {
  /**
   * 是否新增模式
   */
  @Prop({
    type: Boolean,
    default: false
  })
  newMode!: boolean

  @Prop({
    type: String,
    default: '新建Controller'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => ({})
  })
  dataProps!: ControllerConfig

  data: HasEntityControllerConfig = _.cloneDeep(this.dataProps)

  // 包名
  package = ''

  /// 表单校验规则
  rules = {
    title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
    className: [
      { required: true, message: '名称不能为空', trigger: 'blur' },
      {
        pattern: '[a-zA-Z]+[0-9a-zA-Z_]*(\\.[a-zA-Z]+[0-9a-zA-Z_]*)*',
        message: '字母与数字的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    packageName: [
      { required: true, message: '包名不能为空', trigger: 'blur' },
      {
        message: '只能字母开头，包含数字、小写字母、下划线并用.分隔',
        pattern: '^[a-z][a-z0-9_]*(.[a-z0-9_]+)+[0-9a-z_]$',
        trigger: 'blur'
      }
    ],
    baseUrlPath: [
      { required: true, message: '基础url路径不能为空', trigger: 'blur' }
    ],
    description: [{ required: true, message: '描述不能为空', trigger: 'blur' }],
    fromEntityUuid: [
      { required: true, message: '关联实体不能为空', trigger: 'blur' }
    ]
  }

  show (): void {
    ;(this.$refs.dlg as ControllerBaseModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as ControllerBaseModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  onSelect (entity: TreeNode): void {
    this.data.fromEntityFullClassName = entity.label
    this.data.entity = entity.raw
  }

  created () {
    const projectId = getterProjectId()
    if (projectId) {
      webapiProjectApi.getInfoByProjectId(projectId).then(res => {
        this.package = `${res.data?.orgName}.${res.data?.projectName
          ?.replace(/([A-Z])/g, '_$1')
          .replace('-', '_')
          .toLowerCase()}`
      })
    }
  }
}
