import DefaultApiService, { BaseHasTimeEntity, HasStateMachineVersionEntity } from '@/libs/api-request'

/// 数据库类型
export enum DbType {
  /// mysql
  Mysql = 'mysql',
  /// oracle
  Oracle = 'oracle',
  /// sqlserve
  SqlServe = 'sqlserve'
}

/**
 * 项目明细相关基础信息
 */
export interface WebApiProjectInfo extends BaseHasTimeEntity, HasStateMachineVersionEntity {
  /// 项目id
  projectId?: number;
  /// 项目包名
  packageName?: string;
  /// 项目图标
  projectLogoUrl?: string;
  /// 项目git仓库地址
  gitUrl?: string;
  /// 数据库配置访问地址
  dbAllUrl?: string;
  /// 语言，cn,en 用逗号分隔
  language?: string;
  /// 项目说明
  memo?: string;
  /// 项目名称
  projectName?: string;
  /// 前端Git仓库地址(临时)
  frontGitUrl?: string;
  /// AppGit仓库地址(临时)(临时的临时)
  appGitUrl?: string;
  /// 组织名
  orgName?: string;
}

/**
 *
 */
class WebApiProjectInfoApi extends DefaultApiService<WebApiProjectInfo> {
  constructor () {
    super('/api/web-api-project-info')
  }

  /**
   * 查询项目明细信息
   * @param projectId 工程Id
   */
  getInfoByProjectId (projectId: number) {
    return this.request(
      `getInfoByProjectId/${projectId}`,
      null,
      'POST'
    )
  }
}

export default new WebApiProjectInfoApi()
