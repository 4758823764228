import MainFrame from '@/frame/main/main-layout.vue'
import { RouteConfig } from 'vue-router'

const routers: Array<RouteConfig> = [
  {
    name: 'maintain',
    component: MainFrame,
    path: '/maintain',
    meta: {
      showOnBreadCrumb: true
    },
    children: [
      {
        name: 'server-center',
        path: 'server-center',
        component: () => import('@/views/maintain/server-center/data-list/index.vue'),
        meta: {
          title: '服务器中心',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'server-page',
        path: 'server-page',
        component: () => import('@/views/maintain/server-center/data-page/index.vue'),
        meta: {
          title: '配置服务器',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'register-center',
        path: 'register-center',
        component: () => import('@/views/maintain/register-center/data-list/index.vue'),
        meta: {
          title: '注册中心',
          showOnBreadCrumb: true
        }
      },
      // {
      //   name: 'config-center',
      //   path: 'config-center',
      //   component: () => import('@/views/maintain/config-center/data-list/config-application-list.vue'),
      //   meta: {
      //     title: '配置中心',
      //     showOnBreadCrumb: true
      //   }
      // },
      {
        name: 'config-center',
        path: 'config-center',
        component: () => import('@/views/config-center/config-center-main.vue'),
        meta: {
          title: '注册中心',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'profile-config',
        path: 'profile-config',
        component: () => import('@/views/maintain/config-center/data-list/config-profile-list.vue'),
        meta: {
          title: '版本配置',
          showOnBreadCrumb: false
        }
      },
      {
        name: 'config-detail',
        path: 'config-detail',
        component: () => import('@/views/maintain/config-center/data-list/config-detail-list.vue'),
        meta: {
          title: '配置详情',
          showOnBreadCrumb: false
        }
      },
      {
        name: 'logger-center',
        path: 'logger-center',
        component: () => import('@/views/maintain/logger-center/index.vue'),
        meta: {
          title: '日志中心',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'application-center',
        path: 'application-center',
        component: () => import('@/views/maintain/application-center/index.vue'),
        meta: {
          title: '应用中心',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'application-page',
        path: 'application-page/:id',
        component: () => import('@/views/maintain/application-center/components/application-tabs-page.vue'),
        meta: {
          title: '修改应用页面',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'application-deploy',
        path: 'application-deploy/:id',
        component: () => import('@/views/maintain/application-center/components/app-batch-deploy-page.vue'),
        meta: {
          title: '应用部署',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'instance-page',
        path: 'instance-page/:id',
        component: () => import('@/views/maintain/application-center/components/standard-container.vue'),
        meta: {
          title: '实例信息',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'webfront-page',
        path: 'webfront-page/:id',
        component: () => import('@/views/maintain/application-center/components/webfront-container.vue'),
        meta: {
          title: '实例信息',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'instance-deploy-page',
        path: 'instance-deploy-page/:id',
        component: () => import('@/views/maintain/application-center/components/app-instance-deploy-page.vue'),
        meta: {
          title: '实例部署页面',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'monitoring-center',
        path: 'monitoring-center',
        component: () => import('@/views/maintain/monitoring-center/index.vue'),
        meta: {
          title: '监控中心'
        }
      },
      {
        name: 'cloud-resource',
        path: 'cloud-resource',
        component: () => import('@/views/maintain/cloud-resource/index.vue'),
        meta: {
          title: '企业微服务基础资源'
        }
      },
      {
        name: 'cloud-resource-page',
        path: 'cloud-resource-page/:id',
        component: () => import('@/views/maintain/cloud-resource/cloud-guide/index.vue'),
        meta: {
          title: '企业微服务基础资源设置'
        }
      },
      {
        name: 'api-gateway',
        path: 'api-gateway',
        component: () => import('@/views/maintain/api-gateway/data-list/index.vue'),
        meta: {
          title: 'api网关'
        }
      },
      {
        name: 'api-gateway-page',
        path: 'api-gateway-page',
        component: () => import('@/views/maintain/api-gateway/data-page/index.vue'),
        meta: {
          title: '网关路由页面'
        }
      },
      {
        name: 'image-resource',
        path: 'image-resource',
        component: () => import('@/views/maintain/image-resource/index.vue'),
        meta: {
          title: '镜像资源'
        }
      },
      {
        name: 'image-config',
        path: 'image-config',
        component: () => import('@/views/maintain/image-resource/image-config.vue'),
        meta: {
          title: '镜像资源配置'
        }
      },
      {
        name: 'resource-management',
        path: 'resource-management',
        component: () => import('@/views/maintain/resource-management/data-list/index.vue'),
        meta: {
          title: '资源管理',
          showOnBreadCrumb: true
        }
      },
      {
        name: 'resource-management-page',
        path: 'resource-management-page',
        component: () => import('@/views/maintain/resource-management/data-page/index.vue'),
        meta: {
          title: '资源管理配置'
        }
      }
    ]
  }
]

export default routers
