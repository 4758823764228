import { ControllerConfig } from '@/api/project/apimanager/controller-config/controller-config'
import { HasPackageInfoEntity } from '../entitymodel/entity'
import EntityField from '../entitymodel/entity-field'
import Filepack from '../filepack'
import genDataList from './gen-data-list'
import genDataPage from './gen-data-page'
import genTableConfig from './gen-table-config'

export interface FormComponentFieldGroupConfig {
  /** 分组标题 */
  title: string;
  /** 组内字段列表 */
  fields: EntityField[];
}

/**
 * 表单组件生成配置
 */
export interface FormComponentConfig {
  /** 搜索框字段 */
  searchFields: EntityField[];
  /** 显示在表格的字段 */
  showFields: EntityField[];
  /** 是否分组 */
  group: boolean;
  /** 表单字段 (不分组) */
  formFields: EntityField[];
  /** 表单字段组 (分组) */
  fieldGroup: FormComponentFieldGroupConfig[];
}

/**
 * 根据配置生成组件
 * @param entity
 * @param controller
 * @param options
 */
export default async function (entity: HasPackageInfoEntity, controller: ControllerConfig, options: FormComponentConfig): Promise<void> {
  const filepack = new Filepack()
  filepack.addFile('data-list/index.vue', genDataList(entity, controller, options))
  filepack.addFile('data-list/table.config.ts', genTableConfig(options.showFields))
  filepack.addFile('data-page/index.vue', (await genDataPage(entity, controller, options)))

  await filepack.save(`${entity.title}Vue组件`)
}
