import { UserInfo } from '@/api/security'
import axios from 'axios'
import { expect } from 'chai'
import { isEmpty } from 'lodash'
import { random } from 'xe-utils'

export const MenuCodeSpitChar = '-'
export const ShowOnBreadCrumb = 'showOnBreadCrumb'
/**
 * 系统的模块模块
 */
export interface ModuleItem{
    /// 模块的编号
    code: string;
    /// 模块的全局编号 是其所有祖级编号通过,号拼接成串
    fullCode: string;
    /// 模块标题
    title: string;
    /// 模块名称
    name: string;
    /// 模块的路径
    path: string;
    /// 模块图标 icon的名称
    icon: string;
    /// 是否显示在菜单中
    showOnMenu: boolean;
    /// 子模块列表
    children: Array<ModuleItem>;
    // 权限
    authorized?: string[];
}
/**
 * 通过code取得模块项
 * @param root 模块项列表
 * @param code 模块编号
 */
function getMenuItemByCode (root: Array<ModuleItem>, code: string): ModuleItem|undefined {
  if (!root) return undefined
  if (root.length === 0) return undefined

  for (const item of root) {
    if (item.code === code) return item
  }
  return undefined
}

/**
 * 通过path取得模块块
 * @param root 模块项列表
 * @param path 模块对应的path
 */
export function getMoudleItemByPath (root: Array<ModuleItem>, path: string): ModuleItem|undefined {
  if (!root) return undefined
  if (root.length === 0) return undefined

  expect(path, '参数path不允许为空').not.empty.and.not.undefined

  for (const item of root) {
    if (item.path === path) return item
    // 如果当前模块不是由找下一级模块
    if (item.children && item.children.length > 0) {
      const subItem = getMoudleItemByPath(item.children, path)
      if (subItem) return subItem
    }
  }
  return undefined
}

/**
 * 取得路径对应的模块列表
 * @param root
 * @param path
 * @deprecated
 */
export function getMoudleListByPath (root: Array<ModuleItem>, path: string, res: ModuleItem[]): boolean {
  if (!root) return false
  if (root.length === 0) return false

  expect(path, '参数path不允许为空').not.empty.and.not.undefined

  for (const item of root) {
    if (item.path === path) {
      res.push(item)
      return true
    }
    // 如果当前模块不是由找下一级模块
    if (item.children && item.children.length > 0) {
      const re = getMoudleListByPath(item.children, path, res)
      if (re) {
        // 如果在下一级找到，把当前的也放在列表中
        res.unshift(item)
        return true
      }
    }
  }
  return false
}

/**
 * 取得code编号对应的所有模块对象列表
 * @param fullcode
 */
export function getMenuItemListByCode (root: Array<ModuleItem>, codes: Array<string>, res: Array<ModuleItem>) {
  expect(codes, '参数codes不允许为空').have.property('length').above(0)
  expect(root, '参数root不允许为空').have.property('length').above(0)
  expect(res, '参数res不允许为空').not.undefined
  const code = codes.shift()

  const menuItem = getMenuItemByCode(root, code!)
  expect(menuItem, `无法找到code为${code}的模块项`).not.undefined

  res.push(menuItem!)
  // 结果还有子模块，则添加继续在子模块中找下一级模块项
  if (codes.length > 0) {
    getMenuItemListByCode(menuItem!.children, codes, res)
  }
}

/**
 * 更新模块的全部编码
 * @param root 模块列表
 */
function updateMenusFullCode (parentCode: string|undefined, root: Array<ModuleItem>) {
  for (const item of root) {
    item.fullCode = parentCode ? parentCode + MenuCodeSpitChar + item.code : item.code
    if (item.children) updateMenusFullCode(item.fullCode, item.children)
  }
}

/**
 * 更新当前用户的模块列表
 */
export function updateCurUserMenusFromServer (): Promise<Array<ModuleItem>> {
  return new Promise<Array<ModuleItem>>((resolve, reject) => {
    axios.get(`/module-data/moudle.json?${random(0, 10000)}`)
      .then((response) => {
        const menus = response.data
        updateMenusFullCode('', menus)
        resolve(menus)
      }).catch(err => {
        reject(err)
      })
  })
}

/**
 * 更新用户的工程菜单列表
 * @param projectId
 */
export function updateUserProjectMenusFromServer (projectId: number): Promise<Array<ModuleItem>> {
  return new Promise<ModuleItem[]>((resolve, reject) => {
    axios.get('/module-data/webapi-moudle.json')
      .then((response) => {
        const menus = response.data
        updateMenusFullCode('', menus)
        resolve(menus)
      }).catch(err => {
        reject(err)
      })
  })
}

/**
 * 根据全编码取得模块项
 * @param modules
 * @param fullCode
 * @returns [firstItem,lastItem] or empty
 */
export function getMenuItemByFullCode (modules: ModuleItem[], fullCode: string): [ModuleItem, ModuleItem] | null {
  if (isEmpty(modules) || !fullCode) return null

  const [rootCode, endCode] = fullCode.split(MenuCodeSpitChar)

  const rootItem = modules.find(item => item.code === rootCode)
  if (!rootItem) return null

  const endItem = (function r (_modules: ModuleItem[], code: string): ModuleItem | null {
    for (const item of _modules) {
      if (item.code === code) {
        return item
      } else if (!isEmpty(item.children)) {
        const re = r(item.children, code)
        if (re) return re
      }
    }
    return null
  })(rootItem?.children || [], endCode)

  return endItem ? [rootItem, endItem] : null
}
