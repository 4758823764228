
import { Vue, Component, Watch } from 'vue-property-decorator'
import { getterUserModules, dispatchUpdateBreadCrumbList, getterBreadCrumbList, getterBreadCrumbLastPath } from '@/store'
import { ModuleItem, getMoudleListByPath } from '@/libs/sys-modules'
import { Route } from 'vue-router'

interface BreadcrumbInfo{
  title: string;
  path: string;
  icon: string;
}

@Component({
  name: 'breadcrumbPanel'
})
export default class BreadcrumbPanel extends Vue {
  items: BreadcrumbInfo[]=[]

  created () {
    // 重新刷新时，先检查原来保存的位置信息，如果已经存在，则使用原来的
    const moduleList = getterBreadCrumbList()
    const lastPath = getterBreadCrumbLastPath()
    if (moduleList && lastPath && lastPath === this.$route.path) {
      const items: Array<BreadcrumbInfo> = this.getBreadcrumbInfoFromMoudleList(moduleList)
      this.addCurRouteInfo(items, this.$route)
      this.items = items
    } else {
      this.watchRoute(this.$route)
    }
  }

  /**
   * 计算当前路由的提示
   */
  @Watch('$route', { immediate: true })
  private watchRoute (to: Route) {
    const modules: ModuleItem[] = []
    let items: Array<BreadcrumbInfo> = []
    const from = to.params.from
    if (getMoudleListByPath(getterUserModules(), to.path, modules)) {
      // 如果当前路由有对应的模块信息，则直接返回
      items = this.getBreadcrumbInfoFromMoudleList(modules)
    } else if (from) {
      getMoudleListByPath(getterUserModules(), from, modules)
      items = this.getBreadcrumbInfoFromMoudleList(modules)
    }
    this.addCurRouteInfo(items, to, to.params.tagName)

    dispatchUpdateBreadCrumbList(modules, to.path)

    this.items = items
  }

  /// 添加当前路由的位置信息
  private addCurRouteInfo (items: Array<BreadcrumbInfo>, to: Route, tag?: string) {
    if (to?.meta?.title) {
      items.push({
        // title: to.params.mode ? to.meta.title + `(${to.params.mode})` : to.meta.title,
        title: tag || to.meta.title,
        path: to.path,
        icon: ''
      })
    }
  }

  /**
   * 从模块列表取得title
   */
  private getBreadcrumbInfoFromMoudleList (modules: ModuleItem[]) {
    return modules.map((item) => {
      return {
        title: item.title,
        path: item.path,
        icon: item.icon
      }
    })
  }
}
