/**
 * 选择团队对话框
 */
import { showModal } from '@/libs/modal-helper'
import modal from './modipwd-modal.vue'

/**
  * 显示选择团队的弹出对话框
  * @param userId
  * @returns
  */
export default function showModiPwdModal (): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<any>(modal, {
      props: {
      }
    }, true, () => {
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
