import { ConfigApplication } from './../api/maintain/config-application'
import { BaseProjectInfo } from '@/api/project'
import { UserInfo, UserLoginResponse } from '@/api/security'
import ProjectVersion from '@/libs/entitymodel/project-verion'
import { ModuleItem } from '@/libs/sys-modules'
import Vue from 'vue'
import { Route } from 'vue-router'
import Vuex from 'vuex'
import appModule, { TagInfo, TagInfoCollection } from './module/app'
import moudleModule, { RouteMoudleInfo } from './module/moudle'
import projectModule from './module/project'
import userModule from './module/user'
import maintainModule from './module/maintain'
import apimanagerModule, { CurControllInfo } from './module/api-manager'
import enummanagerModule, { CurEnumerationInfo } from './module/enum-manager'
import entitymanagerModule from './module/entity-manager'
import { ProjectMemberRight } from '@/api/project-member'
import { ApplicationProfile } from '@/api/maintain/application-profile'
import { AppInstance } from '@/api/maintain/app-instance'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app: appModule,
    module: moudleModule,
    user: userModule,
    project: projectModule,
    apimanager: apimanagerModule,
    enummanager: enummanagerModule,
    entitymanager: entitymanagerModule,
    maintain: maintainModule
  }
})

export default store

/**
 * 取得当前用户的token
 * @returns
 */
export function getterUserToken (): string|undefined {
  return store.getters['user/tokenGeter']
}

/**
 * 导出getter列表
 */
/**
 * 返回页面面包屑列表
 */
export function getterBreadCrumbList (): Array<ModuleItem> {
  return store.getters['app/breadCrumbListGetter']
}

/**
 * 返回面包屑列表最后对应的路径
 */
export function getterBreadCrumbLastPath (): string|undefined {
  return store.getters['app/breadCrumbLastPathGetter']
}

/**
 * 返回当前找开的页签列表
 */
export function getterTagCollectsGetter (): TagInfoCollection {
  return store.getters['app/tagCollectsGetter']
}

/**
 * 返回当前tagName
 * @returns
 */
export function getterCurTagName (): string {
  return store.getters['app/curTagNameGetter']
}

/**
 * 返回用户的模块列表
 */
export function getterUserModules (): Array<ModuleItem> {
  return store.getters['module/userMoudlesGetter']
}

/**
 * 返回当前菜单模块代码
 */
export function getterCurrentMenuModuleCode (): string {
  return store.getters['module/curMenuCodeItemGetter']
}

/**
 * 返回当前路由的模块信息
 */
export function getterCurrentRouteMoudleInfoGetter (): RouteMoudleInfo|undefined {
  return store.getters['module/curRouteMoudleInfoGetter']
}

/**
 * 取得当前用户信息
 */
export function getterUserInfo (): UserInfo {
  return store.getters['user/userInfoGetter']
}

/**
 * 返回当前工程id
 */
export function getterProjectId (): number|undefined {
  return store.getters['project/projectIdGetter']
}

/**
 * 返回当前工程信息
 */
export function getterProject (): BaseProjectInfo|undefined {
  return store.getters['project/projectGetter']
}

/**
 * 导出操作列表，用于简化对store访问
 */
/**
 * 更新页面面包屑列表
 * @param breadCrumbList
 */
export function dispatchUpdateBreadCrumbList (breadCrumbList: Array<ModuleItem>, path: string) {
  store.dispatch('app/updateBreadCrumbList', { breadCrumbList, path })
}

/**
 * 添加一个签页
 * @param route  页签对应的路由
 * @param vm 页面的vue对象
 * @param tagName 对应的tagName
 */
export function dispatchAddTag (route: Route, vm: Vue, tagName: string) {
  store.dispatch('app/addTag', {
    route: route,
    vm: vm,
    tagName: tagName
  })
}
/**
 * 更新当前的tagName
 * @param tagName
 */
export function dispatchUpdateCurTagName (tagName: string) {
  store.dispatch('app/updateCurTagName', tagName)
}

/**
 * 删除一个签面
 * @param route    页签对应的路由
 */
export function dispatchRemoveTag (tagName: string|undefined, route: Route) {
  if (!tagName) {
    tagName = getterCurTagName()
  }
  store.dispatch('app/removeTag', {
    tagName: tagName,
    route: route
  })
}
/**
 * 更新当前更签的模式
 * @param mode
 */
export function dispatchUpdateCheckTage (tagName: string|undefined, mode: string) {
  if (!tagName) {
    tagName = getterCurTagName()
  }
  store.dispatch('app/updateCheckTageTitle', {
    tagName: tagName,
    mode: mode
  })
}

/**
 * 清除所有标签，只保留一个
 * @param tagName
 */
export function dispatchClearTags (tagName: string|undefined, route: Route) {
  if (!tagName) {
    tagName = getterCurTagName()
  }
  store.dispatch('app/clearTag', {
    tagName: tagName,
    route: route
  })
}

/**
 * 更新当前用户的模块列表
 * @param force 强制
 */
export function dispatchUserModules (force: boolean|undefined): Promise<Array<ModuleItem>> {
  return store.dispatch('module/updateUserMoudles', force)
}

/**
 * 更新当前用户的工程模块列表
 * @param projectId
 * @param force 强制
 */
export function dispatchUserProjectModules (projectId: number, force: boolean|undefined): Promise<Array<ModuleItem>> {
  return store.dispatch('module/updateProjectMoudles', { projectId, force })
}

/**
 * 更新当前用户的选择中的菜单
 * @param module
 */
export function dispathUpdateCurMenuItemCode (moduleFullCode: string) {
  store.dispatch('module/updateCurMenuItemCode', moduleFullCode)
}

/**
 * 更新当前用户信息,包括权限与用户信息
 */
export function dispatchUpdateUserInfo (force?: boolean): Promise<UserInfo> {
  return store.dispatch('user/updateCurUserInfo', force)
}

/**
 * 更新当前的工程信息
 * @param projectId
 */
export function dispatchUpdateProject (projectId: number): Promise<BaseProjectInfo> {
  return store.dispatch('project/updateProjectInfo', projectId)
}

/**
 * 更新当前激活的实体uuid
 * @param uuid
 */
export function dispatchUpdateActiveEntityUuid (uuid?: string) {
  return store.dispatch('project/updateActiveEntityUuid', uuid)
}

/**
 * 返回激活的实体uuid
 */
export function getterActiveEntityUuid (): string|undefined {
  return store.getters['project/activeEntityUuidGetter']
}

/**
 * 返回当前用户锁定的组织列表
 */
export function getterLockOrgIds (): string[] {
  return store.getters['project/lockOrgIdsGetter']
}

/**
 * 更新当前用户锁定的组织列表
 * @param projectId
 */
export function dispatchUpdateLockOrgIds (projectId: number, version: string): Promise<string[]> {
  return store.dispatch('project/updateLockOrgIds', { projectId: projectId, version: version })
}

/**
 * 尝试锁定数据模型的组织，以进行编辑
 * @param projectId
 * @param orgId
 * @returns
 */
export function dispatchTryLockOrgId (projectId: number, version: string, orgId: string) {
  return store.dispatch('project/tryLockOrgId', { projectId: projectId, version, orgId: orgId })
}

/**
 * 解开组织id的锁定
 * @param projectId
 * @param orgId
 * @returns
 */
export function dispatchUnLockOrgId (projectId: number, version: string, orgId: string) {
  return store.dispatch('project/unLockOrgId', { projectId: projectId, version, orgId: orgId })
}

/**
 * 设置当前实体的版本
 * @param curVersion
 * @returns
 */
export function dispatchSetEntityModelVersion (curVersion: string) {
  return store.dispatch('project/setEntityModelVersion', curVersion)
}

/**
 * 更新当前实体模型的版本
 * @param version
 * @returns
 */
export function dispatchCurEntityModelVersion (): Promise<ProjectVersion> {
  return store.dispatch('project/updateCurEntityModelVersion')
}

/**
 * 返回当前实体的版本
 */
export function getterEntityModelVersion (): ProjectVersion|undefined {
  return store.getters['project/entityModelVersionGetter']
}

/**
 * store 的dispatcher方法，以方便对store的action方法进行访问
 */
// 导出登入
export function dispatchHandleLogin (data: any): Promise<UserLoginResponse> {
  return store.dispatch('user/handleLogin', data)
}

// 导出通过ossKey登入
export function dispatchHandleLoginByOss (ossKey: string): Promise<void> {
  return store.dispatch('user/handleLoginByOss', ossKey)
}

// 导出登出
export function dispatchHandleLogOut (): Promise<any> {
  return store.dispatch('user/handleLogOut')
}

// 刷新token
export function dispatchRefreshToken (): Promise<UserLoginResponse> {
  return store.dispatch('user/refreshToken')
}

// 更新token
export function dispatchUpdateToken (data: any): void{
  store.dispatch('user/updateToken', data)
}

// 清除token
export function dispatchClearToken (): void{
  store.dispatch('user/clearToken')
}

// 取得当前curControlId
export function getterCurControllerId (): number|undefined {
  return store.getters['apimanager/curControllerIdGetter']
}

// 取得当前curControllInfo
export function getterCurControllerInfo (): CurControllInfo {
  return store.getters['apimanager/curControllerInfoGetter']
}

// 更新当前controller的id
export function updateCurControllerIdAndInfo (controlId: number, controlInfo: CurControllInfo) {
  store.dispatch('apimanager/updateCurControllerIdAndInfo', {
    controlId: controlId,
    info: controlInfo
  })
}

// 更新当前controller的controllerInfo信息
export function updateCurControllerInfo (controlInfo: CurControllInfo) {
  store.dispatch('apimanager/setCurControllerInfo', controlInfo)
}

// 取得当前curEnumerationId
export function getterCurEnumerationId (): number|undefined {
  return store.getters['enummanager/curEnumerationIdGetter']
}

// 取得当前curEnumerationInfo
export function getterCurEnumerationInfo (): CurEnumerationInfo {
  return store.getters['enummanager/curEnumerationInfoGetter']
}

// 更新当前Enumeration的id
export function updateCurEnumerationIdAndInfo (enumId?: number, enumInfo?: CurEnumerationInfo) {
  store.dispatch('enummanager/updateCurEnumerationIdAndInfo', {
    enumerationId: enumId,
    info: enumInfo
  })
}

// 更新当前Enumeration的EnumerationInfo信息
export function updateCurEnumerationInfo (enumInfo: CurEnumerationInfo) {
  store.dispatch('enummanager/setCurEnumerationInfo', enumInfo)
}

// 取得当前用户的团队用户信息
export function getterProjectMemberRight (): ProjectMemberRight {
  return store.getters['project/projectMemberRightGetter']
}

// 更新当前实例ip
export function updateCurInstanceIp (instaceIp: string) {
  store.dispatch('maintain/setCurInstanceIp', instaceIp)
}

// 取得当前实例ip
export function getterCurInstanceIp (): string {
  return store.getters['maintain/curInstanceIpGetter']
}

// 更新当前配置项目
export function updateCurConfigApplciation (configApp: ConfigApplication) {
  store.dispatch('maintain/setCurConfigApplciation', configApp)
}

// 取得当前配置项目
export function getterCurConfigApplciation (): ConfigApplication {
  return store.getters['maintain/curConfigApplciationGetter']
}

// 更新当前配置项目
export function updateCurAppProfile (curProfile: ApplicationProfile) {
  store.dispatch('maintain/setCurAppProfile', curProfile)
}

// 取得当前配置项目
export function getterCurAppProfile (): ApplicationProfile {
  return store.getters['maintain/curAppProfileGetter']
}

// 更新网关缓存实例
export function updateGatewayInstance (gatewayInstance: AppInstance) {
  return store.dispatch('maintain/setGatewayInstance', gatewayInstance)
}

// 取得网关缓存实例
export function getterGatewayInstance (): AppInstance {
  return store.getters['maintain/gatewayInstance']
}

// 更新consul缓存实例
export function updateConsulInstance (consulInstance: AppInstance) {
  return store.dispatch('maintain/setConsulInstance', consulInstance)
}

// 取得consul缓存实例
export function getterConsulInstance (): AppInstance {
  return store.getters['maintain/consulInstance']
}

// 更新配置中心缓存实例
export function updateConfigCenterInstance (configCenterInstance: AppInstance) {
  return store.dispatch('maintain/setConfigCenterInstance', configCenterInstance)
}

// 取得配置中心缓存实例
export function getterConfigCenterInstance (): AppInstance {
  return store.getters['maintain/configCenterInstance']
}
