
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component } from 'vue-property-decorator'
import QueryBeanPropertyBaseModal from './query-bean-property-base-modal.vue'
import { Form } from 'view-design'
import { QueryBeanPropertyConfig } from '@/api/project/apimanager/query-bean-property-config/query-bean-property-config'
import ConditionTypeSelector from '@/views/project/api-manager/api-editor/components/condition-type-selector.vue'
import _ from 'lodash'
import DataTypesAutoComplete from '@/views/project/api-manager/components/data-types-autocomplete.vue'
import { getterCurControllerId } from '@/store'
import EnumSelector from '@/components/selector/enum-selector.vue'

@Component({
  name: 'QueryBeanPropertyCreatorModal',
  components: {
    QueryBeanPropertyBaseModal,
    ConditionTypeSelector,
    DataTypesAutoComplete,
    EnumSelector
  }
})
export default class QueryBeanPropertyCreatorModal
  extends Vue
  implements BaseModal {
  @Prop({
    type: String,
    default: '添加QueryBean字段'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  dataProps!: QueryBeanPropertyConfig

  data: QueryBeanPropertyConfig = _.cloneDeep(this.dataProps)

  /// 表单校验规则
  rules = {
    title: [{ required: true, message: '字段标题不能为空', trigger: 'blur' }],
    propertyName: [
      { required: true, message: '字段名称不能为空', trigger: 'blur' }
    ],
    dataType: [
      { required: true, message: '数据类型不能为空', trigger: 'blur' }
    ],
    conditionType: [
      {
        required: true,
        type: 'number',
        message: '条件类型不能为空',
        trigger: 'blur'
      }
    ],
    combinationType: [
      {
        required: true,
        type: 'number',
        message: '组合类型没有选择',
        trigger: 'blur'
      }
    ],
    fromEnumUuid: [
      { required: true, message: '关联枚举不能为空', trigger: 'blur' }
    ]
  }

  show (): void {
    ;(this.$refs.dlg as QueryBeanPropertyBaseModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as QueryBeanPropertyBaseModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.data.controllerConfigId = getterCurControllerId()
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
