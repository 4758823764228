
import { Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { getterUserInfo } from '../../../store'
import moment from 'moment'
import TeamApi from '@/api/team'
@Component({
  name: 'TeamInfoModal',
  components: {}
})
export default class TeamInfoModal extends Vue implements BaseModal {
  // 显示状态
  visiable = false;

  formData = {
    teamName: '',
    teamCode: '',
    openDevops: false,
    openWebApi: false,
    devopsExpired: false,
    webApiExpired: false,
    devopsExpireTime: '',
    webApiExpireTime: ''
  };

  show (): void {
    // 显示状态
    this.visiable = true
  }

  created () {
    const userInfo = getterUserInfo()
    TeamApi.getItemById(userInfo.activedTeamId).then((res) => {
      this.formData.teamName = res.data?.name || ''
      this.formData.teamCode = res.data?.code || ''
    })

    this.formData.devopsExpireTime = userInfo.operaExpirationTime || ''
    this.formData.webApiExpireTime = userInfo.webApiExpirationTime || ''
    if (userInfo.operaExpirationTime) {
      if (moment(userInfo.operaExpirationTime).isAfter(moment.now())) {
        this.formData.openDevops = true
      } else {
        this.formData.devopsExpired = true
      }
    }
    if (userInfo.webApiExpirationTime) {
      if (moment(userInfo.webApiExpirationTime).isAfter(moment.now())) {
        this.formData.openWebApi = true
      } else {
        this.formData.webApiExpired = true
      }
    }
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    console.log('===========')
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
