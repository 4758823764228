
import { Prop, Vue, Component } from 'vue-property-decorator'
import { ModuleItem } from '@/libs/sys-modules'
import { getterUserInfo } from '@/store'

/**
 * 子菜单项
 */
@Component({
  name: 'SubMenuItem'
})
export default class SubMenuItem extends Vue {
  /**
   * 菜单项
   */
  @Prop({
    type: Object,
    required: true
  })
  readonly menuItem!: ModuleItem;

  get userInfo () {
    return getterUserInfo()
  }

  get shownChildrenMenu () {
    return this.menuItem?.children?.filter(item => {
      if (item?.authorized && item.authorized.length > 0) {
        return item.authorized.every(auth => !!(this.userInfo as any)?.[auth])
      } else {
        return true
      }
    }) || []
  }

  /**
   * 检查菜单项是否有子菜单
   */
  get hasChidrenMenu () {
    return this.shownChildrenMenu?.length > 0
  }
}
