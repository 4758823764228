
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component, Ref } from 'vue-property-decorator'
import EnumerationBaseModal from './enumeration-base-modal.vue'
import { Form } from 'view-design'
import EntitySelector from '@/components/selector/entity-selector.vue'
import { getShortUuid } from '@/libs/uuid'
import _ from 'lodash'
import { EnumerationConfig } from '@/api/project/enummanager/enum-config/enum-config'
import showSelectOrgTreeModal from '@/components/modal/org-selector-modal'

@Component({
  name: 'EnumerationCreatorModal', components: { EnumerationBaseModal, EntitySelector }
})
export default class EnumerationCreatorModal extends Vue implements BaseModal {
  /**
   * 是否新增模式
   */
  @Prop({
    type: Boolean,
    required: true,
    default: true
  })
  newMode!: boolean

  @Prop({
    type: String,
    default: '新建Enumeration'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  dataProps!: EnumerationConfig

  @Ref()
  readonly dlg!: EnumerationBaseModal

  data: EnumerationConfig = _.cloneDeep(this.dataProps)

  /// 表单校验规则
  rules={
    title: [
      { required: true, message: '标题不能为空', trigger: 'blur' }
    ],
    name: [
      { required: true, message: '枚举名称不能为空', trigger: 'blur' },
      { pattern: '[a-zA-Z]+[0-9a-zA-Z_]*(\\.[a-zA-Z]+[0-9a-zA-Z_]*)*', message: '字母与数字的组合，且字母开始', trigger: 'blur' }
    ],
    packageName: [
      { required: true, message: '包名不能为空', trigger: 'blur' },
      { message: '只能字母开头，包含数字、小写字母、下划线并用.分隔', pattern: '^[a-z][a-z0-9_]*(.[a-z0-9_]+)+[0-9a-z_]$', trigger: 'blur' }
    ],

    memo: [
      { required: true, message: '描述不能为空', trigger: 'blur' }
    ]
  }

  show (): void {
    this.dlg.show()
  }

  close (): void {
    this.dlg.close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate((result) => {
      if (result) {
        this.data.uuid = this.data.uuid ?? getShortUuid()
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  /**
   * 选择组织
   */
  selectOrg () {
    showSelectOrgTreeModal()
      .then((orgName) => {
        this.$set(this.data, 'packageName', orgName)
      })
  }
}

