
// 对话框调用助手，支持动态调用对话框，降低对话框调用的繁琐
import Vue from 'vue'
import { expect } from 'chai'

/**
 * 基础对话框
 */
export interface BaseModal{
  /// 显示对话框
  show(): void;
  /// 关闭对话框
  close(): void;

  /// 保存处理
  doSave (): void;

  /// 取消处理
  doCancel (): void;
}

/// 动态创建对话框
/**
 *
 * @param modal 对话框组件
 * @param parent 对话框组年的父亲节点，如果全局可以使用body
 * @param pops 组件的属性配置
 * @param okClose 当成功时是否关对话框
 * @param onOk 成功处理方法
 * @param onCancel 取消处理方法
 */
export function showModal<T> (modal: any, pops: any,
  okClose?: boolean, onOk?: (data: T) => Promise<void>, onCancel?: () => void) {
  expect(modal, '参数modal不允许为空').not.undefined

  const parent = document.body

  const vm = new Vue({
    components: {
      modal
    },
    render (h, cxt) {
      return h('modal', {
        props: {
          ...(pops.props)
        },
        attrs: {
          style: 'z-index:19999'
        },
        ref: 'dlg',
        on: {
          ...(pops.on),
          onOk: (data: T) => {
            if (onOk) {
              onOk(data).then(() => {
                if (okClose) {
                  const dlg = (vm.$children[0] as any) as BaseModal
                  dlg.close()
                  parent.removeChild(vm.$el)
                }
              })
                .catch((err: Error) => {
                  this.$Notice.error({
                    title: '错误',
                    desc: '生成错误:' + err.message
                  })
                  console.error(err)
                })
            }
          },
          onCancel: () => {
            parent.removeChild(vm.$el)
            const dlg = (vm.$children[0] as any) as BaseModal
            dlg.close()
            if (onCancel) { onCancel() }
          }
        }
      })
    }
  }
  ).$mount()

  parent.appendChild(vm.$el)
  const dlg = (vm.$refs.dlg as any) as BaseModal
  dlg.show()

  return dlg
}
