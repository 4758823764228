import EntityField from '../entitymodel/entity-field'

/**
 * 通过字段列表渲染表格列
 * @param {EntityField[]} fields 实体字段列表
 * @returns
 */
function columns (fields: EntityField[]): string {
  let res = ''
  fields.forEach(item => {
    res += `\n\t{ field: '${item.name}', title:'${item.title}' },`
  })
  return res
}

/**
 * 渲染表格配置
 * @param {EntityField[]} fields 实体字段列表
 * @returns
 */
function render (fields: EntityField[]) {
  return `
import { ToolBarCodeExportAll, TableConfig } from '@/frame/share/table-panel'

const tableConfig: TableConfig = {
  options: {},
  columns: [${columns(fields)}
    { width: 100, slot: 'col_action' }
  ],
  /// 导出工具栏定义
  toolbarConfig: {
    buttons: [
      { code: 'insert', name: '新增', icon: 'md-add' },
      { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
      { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
    ],
    refresh: true,
    export: true,
    print: true,
    zoom: true,
    custom: true,
    exportall: true
  },
  // 表格的打印配置
  printConfig: {
    sheetName: '打印表格'
  },
  // 表格的导出配置
  tableExport: {
    // 默认选中类型
    type: 'xlsx',
    // 自定义类型
    types: ['xlsx', 'csv', 'html', 'xml', 'txt']
  }
}

export default tableConfig 
`
}

export default function (fields: EntityField[]): string {
  return render(fields)
}
