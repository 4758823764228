
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import OrgTreeSelector from '../selector/org-tree-selector.vue'
import { Form } from 'view-design'

@Component({
  name: 'orgSelectorModal',
  components: { OrgTreeSelector }
})
export default class OrgSelectorModal extends Vue implements BaseModal {
    // 显示状态
    visiable=false
    // 原选择的包的名称
    @Prop({
      required: false,
      default: '',
      type: String
    })
    orgFullName!: string

    // 标题
    @Prop({
      type: String,
      default: '请设置新的组织',
      required: false
    })
    title!: string

    rules={
      fullName: [
        { required: true, type: 'string', message: '请选择组织' }
      ]
    }

    /**
     * 表单数据
     */
    formData={
      fullName: ''
    }

    /**
     * 设置组织全名
     */
    setOrgFullName (fullName: string) {
      this.formData.fullName = fullName
    }

    show (): void {
      // 显示状态
      this.visiable = true
    }

    close (): void {
      this.visiable = false
    }

    doSave (): void {
      const form = this.$refs.form as Form
      form.validate((valid) => {
        if (!valid) {
          this.$Message.error('请选择一个组织')
        } else {
          this.$emit('onOk', this.formData.fullName)
        }
      })
    }

    doCancel (): void {
      this.$emit('onCancel')
    }
}
