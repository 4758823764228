
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component } from 'vue-property-decorator'
import ControllerBaseModal from './controller-base-modal.vue'
import { Form } from 'view-design'
import { ControllerConfigDetail } from '@/api/project/apimanager/api-config/controller-config-detail'
import RequestMethodSelector from '@/components/selector/request-method-selector.vue'
import EntitySelector from '@/components/selector/entity-selector.vue'

import _ from 'lodash'

@Component({
  name: 'ApiConfigCreatorModal',
  components: { ControllerBaseModal, RequestMethodSelector, EntitySelector }
})
export default class ApiConfigCreatorModal extends Vue implements BaseModal {
  @Prop({
    type: String,
    default: '新建Api'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  dataProps!: ControllerConfigDetail

  data: ControllerConfigDetail = _.cloneDeep(this.dataProps)

  /// 表单校验规则
  rules = {
    requestUrl: [
      {
        required: true,
        type: 'string',
        message: 'url路径不能为空',
        trigger: 'blur'
      }
    ],
    requestMethod: [
      {
        required: true,
        type: 'number',
        message: '请求方法不能为空',
        trigger: 'blur'
      }
    ],
    title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
    methodName: [
      { required: true, message: '方法名称不能为空', trigger: 'blur' }
    ],
    apiMethodReturnType: [
      {
        required: true,
        type: 'number',
        message: '返回值类型不能为空',
        trigger: 'blur'
      }
    ],
    description: [{ required: true, message: '描述不能为空', trigger: 'blur' }],
    fromEntityUuid: [
      { required: true, message: '关联实体不能为空', trigger: 'blur' }
    ]
  }

  show (): void {
    ;(this.$refs.dlg as ControllerBaseModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as ControllerBaseModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  onSelectEntity (entityModel: any) {
    this.data.apiMethodReturnFullClassName = entityModel.label
  }
}
