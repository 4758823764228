import Vue from 'vue'
import { userHasRight } from './user-rights'

/// 注册v-r指令
function registerRightDirective (): void{
  Vue.directive(
    'r',
    {
      /// 在元素插入时检查用户权限来决定是否显示
      inserted: (el, binging) => {
        if (!binging.value) {
          // 如果没有指定要什么权限则一直显示
          return
        }
        const rightStr = binging.value as string

        const isShow = userHasRight(rightStr)
        if (!isShow) {
          el.style.display = 'none'
          el.remove()
        }
      }
    }
  )
}

// 执行注册
registerRightDirective()
