import { RouteConfig } from 'vue-router'

const routers: Array<RouteConfig> = [
  {
    name: 'test',
    component: () => import('@/views/test/TestState.vue'),
    path: '/teststate',
    meta: {
      skipAuthentication: true
    }
  }, {
    name: 'testdloy',
    path: '/testdloy',
    component: () => import('@/views/test/TestDeploy.vue'),
    meta: {
      skipAuthentication: true
    }
  }, {
    name: 'testnewdeploy',
    path: '/testnewdeploy',
    component: () => import('@/views/maintain/image-resource/alert/consul-default-alert.vue'),
    meta: {
      skipAuthentication: true
    }
  }
]

export default routers
