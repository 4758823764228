
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import EnumerationList from './enumeration-list.vue'
import ControllerConfigSelector from '@/components/selector/controller-config-selector.vue'

@Component({
  name: 'EnumManager',
  components: { ControllerConfigSelector, EnumerationList }
})
export default class ApiManager extends Vue {

}
