
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
import _ from 'lodash'
import EnumerationBaseModal from './enumeration-base-modal.vue'
import { EnumerationItem } from '@/api/project/enummanager/enum-item/enum-item'

@Component({
  name: 'EnumerationCreatorModal',
  components: { EnumerationBaseModal }
})
export default class EnumerationCreatorModal extends Vue implements BaseModal {
  /**
   * 是否新增模式
   */
  @Prop({
    type: Boolean,
    required: true,
    default: true
  })
  newMode!: boolean;

  @Prop({
    type: String,
    default: '新建EnumerationItem'
  })
  title!: string;

  @Prop({
    type: Object,
    default: () => ({})
  })
  dataProps!: EnumerationItem;

  @Ref()
  readonly dlg!: EnumerationBaseModal

  data = { ..._.cloneDeep(this.dataProps), value: this.dataProps.value ? this.dataProps.value : null };

  /// 表单校验规则
  rules = {
    name: [
      { required: true, message: '枚举项名称', trigger: 'blur' },
      {
        pattern: '^[a-zA-Z]{1}[0-9a-zA-Z_]*$',
        message: '字母、数字与_的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    value: [
      { type: 'number', required: true, message: '枚举项值不能为空', trigger: 'blur' },
      {
        type: 'number',
        pattern: '^[0-9]+$s',
        message: '只能为数字',
        trigger: 'blur'
      }
    ],
    memo: [{ required: true, message: '描述不能为空', trigger: 'blur' }]
  };

  show (): void {
    this.dlg.show()
  }

  close (): void {
    this.dlg.close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
